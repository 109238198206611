<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { Button, Icon } from '@/components'

interface Props {
  modelValue?: boolean
  title?: string
  hideHeader?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  title: '',
  hideHeader: false,
})

const emit = defineEmits<{
  (e: 'input', value: boolean): void
  (e: 'update:modelValue', value: boolean): void
}>()

const isOpen = useVModel(props, 'modelValue', emit)
const isMobile = ref(false)

onMounted(() => {
  setIsMobile()

  window.addEventListener('resize', setIsMobile)
})

onUnmounted(() => {
  window.removeEventListener('resize', setIsMobile)
})

function setIsMobile() {
  isMobile.value = window.innerWidth < 600
}
</script>

<template>
  <transition
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isOpen"
      class="sm:hidden block transition duration-300 sm:transition-none fixed inset-0 z-20 bg-black bg-opacity-50 w-full h-screen"
      @click="isOpen = false"
    />
  </transition>
  <transition
    enter-active-class="ease-out duration-500"
    enter-from-class="opacity-0 translate-y-full sm:scale-95"
    enter-to-class="opacity-100 -translate-y-0 sm:scale-100"
    leave-active-class="ease-in duration-300"
    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
    leave-to-class="opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
  >
    <div
      v-if="isOpen"
      class="bg-white px-0 sm:px-6 py-2 fixed sm:relative z-[999999] sm:top-0 flex flex-col sm:flex-row justify-between gap-4 items-center w-full bottom-0 p-6 rounded-t-xl sm:rounded-none transition sm:transition-none duration-300"
    >
      <div
        v-if="!hideHeader"
        class="sm:hidden flex border-b w-full gap-2 px-6 justify-between items-center py-2"
      >
        <div class="font-semibold">
          {{ title }}
        </div>
        <Button class="hidden" text icon @click="isOpen = false">
          <Icon :size="20" name="x" />
        </Button>
      </div>

      <div class="container mx-auto">
        <div
          class="text-left sm:mb-0 sm:text-center flex-grow px-4 sm:px-6 py-4 sm:py-0"
        >
          <slot />
        </div>
      </div>

      <Button
        v-if="!isMobile"
        class="absolute right-6"
        text
        icon
        @click="isOpen = false"
      >
        <Icon :size="20" name="x" />
      </Button>
    </div>
  </transition>
</template>
