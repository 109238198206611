import { defineStore } from 'pinia'
import type { User } from 'oidc-client-ts'
import type { RouteLocationNormalized } from 'vue-router'
import Cookies from 'js-cookie'

import {
  addUserManagerEventListener,
  createOidcUserManager,
  getOidcCallbackPath,
  getOidcConfig,
  isTokenExpired,
  tokenExp,
} from '@/utils/oidc'
import type {
  OidcClientSettings,
  OidcErrorPayload,
  OidcSignInSilentPayload,
  OidcStoreListeners,
  OidcStoreSettings,
  OidcStoreState,
} from '@/types/oidc'

function createStoreModule(
  oidcSettings: OidcClientSettings,
  storeSettings: OidcStoreSettings = {},
  eventListeners: OidcStoreListeners = {},
) {
  const oidcConfig = getOidcConfig(oidcSettings)

  const oidcUserManager = createOidcUserManager(oidcSettings)

  storeSettings = Object.assign(
    { isAuthenticatedBy: 'access_token' },
    storeSettings,
  )

  const oidcCallbackPath = getOidcCallbackPath(
    oidcConfig.redirect_uri,
    storeSettings.routeBase || '/',
  )

  const oidcSilentCallbackPath = getOidcCallbackPath(
    oidcConfig.silent_redirect_uri,
    storeSettings.routeBase || '/',
  )

  Object.keys(eventListeners).forEach((eventName) => {
    addUserManagerEventListener(
      oidcUserManager,
      eventName,
      eventListeners[eventName as keyof OidcStoreListeners],
    )
  })

  function isRoutePublic(route: RouteLocationNormalized) {
    if (route.meta && route.meta.middleware !== 'auth')
      return true

    if (
      route.meta
      && Array.isArray(route.meta)
      && route.meta.reduce((isPublic, meta) => meta.auth !== true || isPublic, false)
    )
      return true

    if (
      storeSettings.publicRoutePaths
      && storeSettings.publicRoutePaths
        .map(path => path.replace(/\/$/, ''))
        .includes(route.path.replace(/\/$/, ''))
    )
      return true

    if (
      storeSettings.isPublicRoute
      && typeof storeSettings.isPublicRoute === 'function'
    )
      return storeSettings.isPublicRoute(route)

    return false
  }

  function isAuthenticated(state: OidcStoreState) {
    return !!state?.[storeSettings.isAuthenticatedBy as keyof OidcStoreState]
  }

  function isRouteOidcCallback(route: RouteLocationNormalized) {
    if (route.meta && route.meta.isOidcCallback)
      return true

    if (
      route.meta
      && Array.isArray(route.meta)
      && route.meta.reduce(
        (isOidcCallback, meta) => meta.isOidcCallback || isOidcCallback,
        false,
      )
    )
      return true

    if (route.path && route.path.replace(/\/$/, '') === oidcCallbackPath)
      return true

    return !!(route.path && route.path.replace(/\/$/, '') === oidcSilentCallbackPath)
  }

  function errorPayload(context: string, error: { message: any }): OidcErrorPayload {
    return {
      context,
      error: error && error.message ? error.message : error,
    }
  }

  async function authenticateSilent(store: any, payload: OidcSignInSilentPayload = {}) {
    try {
      const options = payload.options || {}
      const user = await oidcUserManager.signinSilent(options)
      await store.oidcWasAuthenticated(user)
      return user
    }
    catch (err: any) {
      store.setOidcAuthIsChecked()
      if (payload.ignoreErrors) {
        return null
      }
      else {
        store.setOidcError(errorPayload('authenticateSilent', err))
        throw err
      }
    }
  }

  function dispatchCustomErrorEvent(eventName: string, payload: OidcStoreListeners) {
    // oidcError and automaticSilentRenewError are not UserManagement events, they are events implemeted in pinia-oidc,
    if (typeof eventListeners[eventName as keyof OidcStoreListeners] === 'function')
      eventListeners[eventName as keyof OidcStoreListeners](payload)
  }

  return defineStore('oidc', {
    state(): OidcStoreState {
      return {
        access_token: null,
        id_token: null,
        refresh_token: null,
        user: null,
        scopes: null,
        is_checked: false,
        events_are_bound: false,
        error: null,
      }
    },
    getters: {
      oidcIsAuthenticated(state) {
        return isAuthenticated(state)
      },
      oidcUser(state) {
        return state.user
      },
      oidcAccessToken(state) {
        return isTokenExpired(state.access_token) ? null : state.access_token
      },
      oidcAccessTokenExpiry(state) {
        return tokenExp(state.access_token)
      },
      oidcScopes(state) {
        return state.scopes
      },
      oidcIdToken(state) {
        return isTokenExpired(state.id_token) ? null : state.id_token
      },
      oidcIdTokenExpiry(state) {
        return tokenExp(state.id_token)
      },
      oidcRefreshToken(state) {
        return isTokenExpired(state.refresh_token) ? null : state.refresh_token
      },
      oidcRefreshTokenExpiry(state) {
        return tokenExp(state.refresh_token)
      },
      oidcAuthenticationIsChecked(state) {
        return state.is_checked
      },
      oidcError(state) {
        return state.error
      },
      oidcIsRoutePublic() {
        return isRoutePublic
      },
    },
    actions: {
      async loadUser() {
        const isAuthenticatedInStore = isAuthenticated(this)
        const user = await oidcUserManager.getUser()

        if ((!user || user.expired)) {
          if (isAuthenticatedInStore)
            this.unsetOidcAuth()
        }
        else {
          await this.oidcWasAuthenticated(user)
          if (!isAuthenticatedInStore) {
            if (eventListeners && typeof eventListeners.userLoaded === 'function')
              eventListeners.userLoaded(user)
          }
        }
      },
      async checkAccess(route: RouteLocationNormalized) {
        if (isRouteOidcCallback(route))
          return [true, false]

        const isAuthenticatedInStore = isAuthenticated(this)
        const user = await oidcUserManager.getUser()

        // handle invalid or expired user
        if (!user || user.expired) {
          const canSilentAuth = oidcConfig.silent_redirect_uri && oidcConfig.automaticSilentRenew

          if (isRoutePublic(route)) {
            if (isAuthenticatedInStore)
              this.unsetOidcAuth()

            // route is public, just attempt silent auth now
            if (canSilentAuth)
              await authenticateSilent(this, { ignoreErrors: true })
          }
          else {
            const redirectToSignIn = () => {
              if (isAuthenticatedInStore)
                this.unsetOidcAuth()

              this.signInRedirect({ redirectPath: route.fullPath })
            }

            // if we can do silent sign in, try to authenticate silently before denying access
            if (canSilentAuth) {
              try {
                await authenticateSilent(this, { ignoreErrors: true })
                const user = await oidcUserManager.getUser()

                // user is invalid, deny access and redirect to login page
                if (!user || user.expired) {
                  redirectToSignIn()
                  return [false, true]
                }

                return [!!user, false]
              }
              catch {
                // silent auth failed, deny access and redirect to login page
                redirectToSignIn()
                return [false, true]
              }
            }
            else {
              // silent sign in is not set up, deny access and redirect to login page
              redirectToSignIn()
              return [false, true]
            }
          }
        }
        else {
          await this.oidcWasAuthenticated(user)
          if (!isAuthenticatedInStore) {
            if (eventListeners && typeof eventListeners.userLoaded === 'function')
              eventListeners.userLoaded(user)
          }
        }

        return [true, false]
      },
      async signInRedirect({ redirectPath, ref }: { redirectPath?: string; ref?: string } = {}) {
        try {
          if (redirectPath)
            sessionStorage.setItem('oidc:active_route', redirectPath)
          else
            sessionStorage.removeItem('oidc:active_route')

          const extraQueryParams: Record<string, string> = {
            audience: import.meta.env.VITE_OIDC_AUDIENCE,
          }
          if (!ref)
            ref = 'user-profile'

          extraQueryParams.ref = ref
          Cookies.set(
            'bid_login_ref',
            ref,
            { expires: 1, domain: '.belajar.id', secure: true, sameSite: 'Lax' },
          )

          return await oidcUserManager.signinRedirect({ extraQueryParams })
        }
        catch (err: any) {
          this.setOidcError(errorPayload('signInRedirect', err))
        }
      },
      async signInRedirectCallback(url?: string) {
        try {
          const user = await oidcUserManager.signinRedirectCallback(url)
          await this.oidcWasAuthenticated(user)
          return sessionStorage.getItem('oidc:active_route') || '/'
        }
        catch (err: any) {
          this.setOidcError(errorPayload('signInRedirectCallback', err))
          this.setOidcAuthIsChecked()
          throw err
        }
      },
      signInSilent(payload: OidcSignInSilentPayload = {}) {
        return authenticateSilent.call(this, payload)
      },
      signInSilentCallback(url?: string) {
        return oidcUserManager.signinSilentCallback(url)
      },
      async oidcWasAuthenticated(user: User | null) {
        this.setOidcAuth(user)

        if (!this.events_are_bound) {
          oidcUserManager.events.addAccessTokenExpired(() => {
            this.unsetOidcAuth()
          })
          if (oidcSettings.automaticSilentRenew) {
            oidcUserManager.events.addAccessTokenExpiring(async () => {
              try {
                await authenticateSilent(this)
              }
              catch (err: any) {
                dispatchCustomErrorEvent(
                  'automaticSilentRenewError',
                  errorPayload('authenticateOidcSilent', err),
                )
              }
            })
          }
          this.setOidcEventsAreBound()
        }

        this.setOidcAuthIsChecked()
      },
      async storeOidcUser(user: User) {
        try {
          await oidcUserManager.storeUser(user)
          const oidcUser = await oidcUserManager.getUser()
          await this.oidcWasAuthenticated(oidcUser)
        }
        catch (err: any) {
          this.setOidcError(errorPayload('storeOidcUser', err))
          this.setOidcAuthIsChecked()
          throw err
        }
      },
      async getOidcUser() {
        const user = await oidcUserManager.getUser()
        this.setOidcUser(user)
        return user
      },
      async signOutRedirect(payload = {}) {
        await oidcUserManager.signoutRedirect(payload)
        this.unsetOidcAuth()
      },
      signOutRedirectCallback() {
        return oidcUserManager.signoutRedirectCallback()
      },
      async removeOidcUser() {
        await oidcUserManager.removeUser()
        this.unsetOidcAuth()
      },
      setOidcAuth(user: User | null) {
        if (!user)
          return

        this.id_token = user.id_token
        this.access_token = user.access_token
        this.refresh_token = user.refresh_token
        this.user = user.profile
        this.scopes = user.scopes
        this.error = null
      },
      setOidcUser(user: User | null) {
        this.user = user ? user.profile : null
      },
      unsetOidcAuth() {
        this.id_token = null
        this.access_token = null
        this.refresh_token = null
        this.user = null
      },
      setOidcAuthIsChecked() {
        this.is_checked = true
      },
      setOidcEventsAreBound() {
        this.events_are_bound = true
      },
      setOidcError(payload: OidcErrorPayload) {
        this.error = payload.error
      },
    },
  })
}

export const useOidcStore = createStoreModule({
  authority: import.meta.env.VITE_OIDC_AUTHORITY,
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_OIDC_REDIRECT_URI,
  post_logout_redirect_uri: import.meta.env.VITE_OIDC_POST_LOGOUT_REDIRECT_URI,
  response_type: 'code',
  scope: import.meta.env.VITE_OIDC_SCOPE,
  fetchRequestCredentials: String(import.meta.env.VITE_OIDC_FETCH_REQUEST_CREDENTIALS || 'same-origin') as RequestCredentials,
  loadUserInfo: false,
  extraQueryParams: {
    audience: import.meta.env.VITE_OIDC_AUDIENCE,
  },
})
