const __pages_import_0__ = () => import("/src/pages/account/lookup/v2/index.vue");
const __pages_import_1__ = () => import("/src/pages/auth/silent/callback.vue");
const __pages_import_2__ = () => import("/src/pages/auth/logout/callback.vue");
const __pages_import_3__ = () => import("/src/pages/auth/login/index.vue");
const __pages_import_4__ = () => import("/src/pages/auth/login/callback.vue");
const __pages_import_5__ = () => import("/src/pages/account/dinas/index.vue");
const __pages_import_6__ = () => import("/src/pages/webinar/index.vue");
const __pages_import_7__ = () => import("/src/pages/resources/index.vue");
const __pages_import_8__ = () => import("/src/pages/reset-password/otp.vue");
const __pages_import_9__ = () => import("/src/pages/reset-password/new-password.vue");
const __pages_import_10__ = () => import("/src/pages/profile/index.vue");
import __pages_import_11__ from "/src/pages/index/index.vue";
import __pages_import_12__ from "/src/pages/index/home.vue";
const __pages_import_13__ = () => import("/src/pages/faq/index.vue");
const __pages_import_14__ = () => import("/src/pages/eligible/peserta-didik.vue");
const __pages_import_15__ = () => import("/src/pages/[...path].vue");

const routes = [{"name":"account-lookup-v2","path":"/account/lookup/v2","component":__pages_import_0__,"props":true},{"name":"auth-silent-callback","path":"/auth/silent/callback","component":__pages_import_1__,"props":true,"meta":{"layout":"blank"}},{"name":"auth-logout-callback","path":"/auth/logout/callback","component":__pages_import_2__,"props":true,"meta":{"layout":"blank"}},{"name":"auth-login","path":"/auth/login","component":__pages_import_3__,"props":true,"meta":{"layout":"blank"}},{"name":"auth-login-callback","path":"/auth/login/callback","component":__pages_import_4__,"props":true,"meta":{"layout":"blank"}},{"name":"account-dinas","path":"/account/dinas","component":__pages_import_5__,"props":true},{"name":"webinar","path":"/webinar","component":__pages_import_6__,"props":true},{"name":"resources","path":"/resources","component":__pages_import_7__,"props":true},{"name":"reset-password-otp","path":"/reset-password/otp","component":__pages_import_8__,"props":true},{"name":"reset-password-new-password","path":"/reset-password/new-password","component":__pages_import_9__,"props":true},{"name":"profile","path":"/profile","component":__pages_import_10__,"props":true,"meta":{"middleware":"auth","layout":"blank"}},{"name":"home","path":"/","component":__pages_import_11__,"props":true},{"name":"index-home","path":"//home","component":__pages_import_12__,"props":true},{"name":"faq","path":"/faq","component":__pages_import_13__,"props":true},{"name":"eligible-peserta-didik","path":"/eligible/peserta-didik","component":__pages_import_14__,"props":true,"meta":{"middleware":"auth","layout":"blank"}},{"name":"path","path":"/:path(.*)*","component":__pages_import_15__,"props":true,"meta":{"layout":"blank"}}];

export default routes;