import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface TempState {
  file: File | null
  fileBase64: string
}

export const temp = {
  namespaced: true,
  state: {
    file: null,
    fileBase64: '',
  },
  getters: {},
  mutations: {
    setFile(state, { file, fileBase64 }) {
      state.file = file
      state.fileBase64 = fileBase64
    },
  },
  actions: {},
} as Module<TempState, RootState>
