<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

const LazyFaq = defineAsyncComponent(() => import('@/components/Home/Faq.vue'))
const LazyGovernmentStatement = defineAsyncComponent(() => import('@/components/Home/GovernmentStatement.vue'))
const LazyWebinar = defineAsyncComponent(() => import('@/components/Home/Webinar.vue'))
const LazyHowToActivate = defineAsyncComponent(() => import('@/components/Home/HowToActivate.vue'))
const LazyDinas = defineAsyncComponent(() => import('@/components/Home/Dinas.vue'))
const LazyProducts = defineAsyncComponent(() => import('@/components/Home/Products.vue'))

const siteData = reactive({
  title: 'Akun belajar.id | Kemdikbudristek',
  description:
    'Akun belajar.id merupakan akun elektronik yang diterbitkan oleh Kementerian Pendidikan dan Kebudayaan.',
})

useHead({
  title: computed(() => siteData.title),
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description),
    },
  ],
})

const store = useStore()

function onActiveTabChange(selector: string) {
  nextTick(() => {
    const hashMap: Record<string, number> = {
      '#home': 0,
      '#about': 1,
      '#platform': 2,
      '#cara-aktivasi': 3,
      '#webinar': 4,
      '#pernyataan-pemerintah': 5,
      '#faq': 6,
    }

    const index = hashMap[selector]
    store.state.activeMenu = index
    store.state.scrollHash = selector

    if (!selector)
      return

    const el = document.querySelector(selector)
    el?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  })
}

const route = useRoute()

onMounted(() => {
  onActiveTabChange(route.hash)
})
</script>

<template>
  <div class="bg-gray-0">
    <NewAccountLookup />
    <About />

    <div>
      <LazyDinas />
      <div id="platform">
        <LazyProducts />
      </div>
    </div>

    <LazyHowToActivate />
    <LazyWebinar />
    <LazyGovernmentStatement />
    <LazyFaq />
  </div>
</template>

<route lang="yaml">
name: home
</route>
