import { useToast } from './toast'
import { type ResetPasswordMethod, type ResetPasswordOtpData, sendOtp } from '@/services'
import type { ResetPasswordAccount } from '@/store/modules'

export const useResetPassword = () => {
  const store = useStore()

  const loading = ref(false)
  const error = ref('')

  const account = computed<ResetPasswordAccount>(() => store.state.resetPassword.account)
  const email = computed(() => account.value?.email)
  const emailEligible = computed(
    () => account.value?.email_eligible,
  )
  const phone = computed(() => account.value?.phone)
  const phoneEligible = computed(
    () => account.value?.phone_eligible,
  )

  const { show } = useToast()

  const handlSendOtp = async ({
    method,
    onSuccess,
    onError,
  }: {
    method: ResetPasswordMethod
    onSuccess?: (res: ResetPasswordOtpData) => void
    onError?: (error: any) => void
  }) => {
    error.value = ''
    loading.value = true

    try {
      const res = await sendOtp({
        token: store.state.resetPassword.account?.token,
        metode: method,
      })

      store.commit('resetPassword/setSessionId', res.data.session_id)

      show({
        message: 'Kode OTP berhasil dikirim',
        icon: 'ic:sharp-check',
        timeout: 10000,
      })

      onSuccess?.(res.data)
    }
    catch (e: any) {
      error.value = e.response?.data?.error?.message || e.message
      onError?.(e)
    }
    finally {
      loading.value = false
    }
  }

  return {
    error,
    loading,
    handlSendOtp,
    email,
    emailEligible,
    phone,
    phoneEligible,
    account,
  }
}
