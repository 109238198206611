export type TypeAuth = 'pengajuan-akun' | 'portal-admin' | ''

export interface Link {
  text: string
  to: string
}

export interface AuthModal {
  show: boolean
  type: TypeAuth
  headerText: string | Link | '' | null
  footerText: string | Link | null | ''
  errors: any
  loading: boolean
  isFailedState: boolean
  showMenu: boolean
}

export const authModal = {
  namespaced: true,

  state: (): AuthModal => ({
    show: false,
    type: '',
    headerText: null,
    footerText: null,
    errors: null,
    loading: false,
    isFailedState: true, // for testing purpose
    showMenu: false,
  }),

  mutations: {
    setShow(state: AuthModal, payload: boolean) {
      state.show = payload
    },

    setType(state: AuthModal, payload: TypeAuth) {
      state.type = payload
    },

    setHeaderText(state: AuthModal, payload: string | Link) {
      state.headerText = payload
    },

    setFooterText(state: AuthModal, payload: string | Link) {
      state.footerText = payload
    },

    setErrors(state: AuthModal, payload: any) {
      state.errors = payload
    },

    setLoading(state: AuthModal, payload: boolean) {
      state.loading = payload
    },

    setFailedState(state: AuthModal, payload: boolean) {
      state.isFailedState = payload
    },

    setShowMenu(state: AuthModal, payload: boolean) {
      state.showMenu = payload
    },
  },
}
