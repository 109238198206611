import axios from 'axios'

export const baseUrl = (path = '') =>
  `belajar-id/account-lookup/v1/lookup/self-reset-password${path}`

/**
 * Verify belajar id account for self-reset password.
 *
 * @see https://gitlab.com/wartek-id/belajar-id/belajarid-api/-/blob/main/website/openapiv2/wartek/belajar_id/account_lookup/v1/account_lookup.swagger.json#/wartek.belajar_id.account_lookup.v1.AccountLookupService/SelfResetPasswordVerify
 *
 * @param payload
 */
export const verifyAccountResetPassword = async (payload: {
  belajaridEmail: string
  dob: string
}) => {
  return axios.post(baseUrl('/verify'), payload)
}

export type ResetPasswordMethod = 'phone' | 'email'

export interface ResetPasswordOtpData {
  session_id: string
  status: string
}

export const sendOtp = async (payload: {
  token: string
  metode: ResetPasswordMethod
}) => {
  return axios.post<ResetPasswordOtpData>(baseUrl('/send-otp'), payload)
}

export const validateOtp = async (payload: {
  token: string
  sessionId: string
  otpCode: string
}) => {
  return axios.post(baseUrl('/validate-otp'), payload)
}

export const resetPassword = async (payload: {
  token: string
  newPassword: string
  retypePassword: string
}) => {
  return axios.post(baseUrl(), payload)
}
