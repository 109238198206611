import type { RootState } from './../store/index'
import {
  getDinasCity,
  getDinasJabatan,
  getPlatformDinas,
  postDinasReferralCode,
  uploadFile,
} from '@/services'
import type {
  GetCityParams,
  GetPositionParams,
  ReferralCodeParams,
} from '@/services/dinas'
import type { DinasForm } from '@/store/modules'
import provinces from '@/content/provinces.json'
import { createDinasAccount } from '@/services/dinas'
import { sortBy } from '@/utils'

export interface SelectOption { text: string; value: string }

export const cities = ref<SelectOption[]>([])

export const useDinas = () => {
  const store = useStore<RootState>()
  const positions = ref<SelectOption[]>([])
  const platform = ref()
  const dinasTypes = ref<SelectOption[]>([
    {
      text: 'Dinas Provinsi',
      value: 'provinsi',
    },
    {
      text: 'Dinas Kabupaten & Kota',
      value: 'kota_kabupaten',
    },
  ])

  const loading = ref(false)
  const error = computed(() => store.state.dinas.error)
  const errorDetail = computed(() => store.state.dinas.errorDetail)

  const clearError = () => store.commit('dinas/clearError')

  const setError = (error: string, detail?: string) => {
    store.commit('dinas/setError', error)
    store.commit('dinas/setErrorDetail', detail)
  }

  const reset = () => store.commit('dinas/reset')

  const setForm = (values: Record<string, any>) => {
    store.commit('dinas/setForm', values)
  }

  const setFiles = (files: File[] | FileList) => {
    store.commit('dinas/setFiles', files)
  }

  const catchError = (e: any) => {
    const err = e.response?.data?.error
    const errMessage = err?.message || e.message
    const errDetail = err?.description

    setError(errMessage, errDetail)
  }

  const getCities = async (params: GetCityParams) => {
    loading.value = true
    try {
      const res = await getDinasCity(params)
      cities.value = res.data.data.map(item => ({
        value: item.id,
        text: item.name,
      }))
    }
    catch (e: any) {
      catchError(e)
    }
    finally {
      loading.value = false
    }
  }

  const getPositions = async (params?: GetPositionParams) => {
    loading.value = true
    try {
      const res = await getDinasJabatan(params)
      positions.value = res.data.data.map(item => ({
        text: item.name,
        value: item.id,
      }))
      positions.value.push({
        text: 'Lainnya',
        value: 'Lainnya',
      })
    }
    catch (e: any) {
      catchError(e)
    }
    finally {
      loading.value = false
    }
  }

  const getPlatform = async () => {
    loading.value = true
    try {
      const res = await getPlatformDinas()
      platform.value = res.data.data.map(item => ({
        id: item.id,
        platform_name: item.platform_name,
        checked: false,
      }))
    }
    catch (e: any) {
      catchError(e)
    }
    finally {
      loading.value = false
    }
  }

  const checkReferralCode = async (params: ReferralCodeParams) => {
    clearError()
    loading.value = true
    try {
      const res = await postDinasReferralCode(params)
      return res
    }
    catch (e: any) {
      catchError(e)
      return e.response
    }
    finally {
      loading.value = false
    }
  }

  const form = computed<DinasForm>(() => store.state.dinas.form)
  const router = useRouter()

  const uploadFileDinas = async (payload: FormData) => {
    clearError()
    loading.value = true
    try {
      const res = await uploadFile(payload)
      return res.data.data.url
    }
    catch (e: any) {
      if (
        e.response?.data?.errors?.[0]?.message === 'failed to parse file'
        && e.response.status === 400
      ) {
        setError('Data yang Anda masukkan salah. Mohon periksa kembali.')
      }
      else {
        setError(
          e.response?.data?.message
            || e.response?.data?.error?.message
            || e.response?.data?.errors?.[0]?.message
            || e.message,
        )
      }
    }
    finally {
      loading.value = false
    }
  }

  const provinceItems = computed<SelectOption[]>(() => {
    const provs = provinces.map((prov: any) => {
      return {
        text: prov.nama,
        value: prov.id,
      }
    })

    const dummyProvinces = [
      {
        text: 'Test',
        value: '9999',
      },
    ]

    const isDev = import.meta.env?.DEV
    const items = [...(isDev ? dummyProvinces : []), ...provs]

    return items.concat().sort(sortBy('text'))
  })

  const requestAccount = async (payload: Record<string, any>) => {
    clearError()
    loading.value = true
    try {
      const res = await createDinasAccount(payload)
      return res
    }
    catch (e: any) {
      if (e.response.status === 400) {
        setError('Data yang Anda masukkan salah. Mohon periksa kembali.')
      }
      else {
        setError(
          e.response.data.message
            || e.response.data.error.message
            || e.response.data.errors[0].message
            || e.message,
        )
      }
    }
    finally {
      loading.value = false
    }
  }

  return {
    cities,
    error,
    loading,
    positions,
    dinasTypes,
    form,
    store,
    platform,
    router,
    provinceItems,
    errorDetail,
    setForm,
    getCities,
    getPositions,
    getPlatform,
    checkReferralCode,
    setFiles,
    uploadFileDinas,
    requestAccount,
    reset,
    clearError,
    setError,
  }
}
