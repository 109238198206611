import { computed } from 'vue'
import { useStore } from 'vuex'
import type { AccountForm } from '@/pages/account/lookup/v2/__partials/types'

export function useAccountLookup() {
  const store = useStore()

  const setForm = (values: Record<string, any>) => {
    store.commit('accountLookup/setForm', values)
  }

  const form = computed<AccountForm>(() => store.state.accountLookup.form)

  const reset = () => {
    store.dispatch('accountLookup/reset')
  }

  return { store, setForm, form, reset }
}
