<script setup lang="ts">
import { Toast } from '@wartek-id/belajar-id-components'
import { bootstrap, setOptions } from 'vue-gtag'
import { useToast } from '@/composables'

const { isOpen, options, message } = useToast()

function loadGA() {
  setOptions({
    config: { id: import.meta.env.VITE_GA_ID },
  })

  bootstrap()
}

onMounted(() => {
  loadGA()
})
</script>

<template>
  <RouterView />
  <ReloadPrompt />

  <!-- global toast -->
  <Toast v-model="isOpen" v-bind="options">
    {{ message }}
  </Toast>
</template>
