export const useLayout = () => {
  const store = useStore()

  const hasAnnouncement = computed({
    get() {
      return store.state.layout.isBannerOpen
    },
    set(val) {
      store.commit('layout/setBanner', val)
    },
  })

  return { hasAnnouncement, store }
}
