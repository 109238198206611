<script setup lang="ts">
import { ref, toRefs, watch } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { Icon } from '../index'
import type { SelectItem } from './types'

interface Props {
  modelValue?: SelectItem
  items: SelectItem[]
  placeholder?: string
  checkIcon?: boolean
  outlined?: boolean
  large?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  placeholder: 'Choose',
  checkIcon: false,
  outlined: false,
  large: false,
})

const emit = defineEmits(['update:modelValue'])

const { modelValue } = toRefs(props)

const selected = ref<SelectItem | undefined>(modelValue?.value)

watch(
  () => props.modelValue,
  (val) => {
    selected.value = val
  },
)

watch(selected, (val) => {
  emit('update:modelValue', val)
})
</script>

<template>
  <Listbox v-model="selected" class="w-full">
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full pl-3 pr-10 text-left rounded-lg border border-gray-50 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
        :class="[
          outlined
            ? 'bg-transparent border-icon-inverse text-inverse-subdued'
            : 'bg-white',
          large ? 'py-3' : 'py-2',
        ]"
      >
        <span class="block truncate">{{ selected?.text || placeholder }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <Icon
            :name="outlined ? 'select.white' : 'select'"
            :size="24"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <slot name="prepend" />
          <ListboxOption
            v-for="item in items"
            v-slot="{ active, selected: selectedOption }"
            :key="item.text"
            :value="item"
            :disabled="item.disabled"
            as="template"
          >
            <ul class="px-1 list-none">
              <li v-if="item.divider" class="border-b -mx-1" />
              <li
                v-else
                class="cursor-default select-none relative py-2 pr-4 rounded"
                :class="[
                  checkIcon ? 'pl-10' : 'pl-4',
                  item.disabled
                    ? 'text-disabled bg-surface-disabled rounded-none cursor-not-allowed'
                    : active
                      ? 'bg-surface-pressed'
                      : 'text-gray-900',
                ]"
              >
                <span
                  class="block truncate"
                  :class="[selectedOption ? 'font-medium' : 'font-normal']"
                >
                  {{ item.text }}
                </span>
                <span
                  v-if="selectedOption && checkIcon"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                >
                  <Icon name="check" class="text-blue-70" :size="20" aria-hidden="true" />
                </span>
              </li>
            </ul>
          </ListboxOption>
          <slot name="append" />
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
