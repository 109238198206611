<script lang="ts">
import { type Component, defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
    size: {
      type: Number,
      default: undefined,
    },
    fill: {
      type: String,
      default: 'none',
    },
  },
  computed: {
    iconComponent(): Promise<Component> {
      return defineAsyncComponent(() => import(`./icons/${this.name}.svg`))
    },
  },
})
</script>

<template>
  <svg
    class="inline"
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
    :width="size || width"
    :height="size || height"
    :viewBox="`0 0 ${size || width} ${size || height}`"
    aria-hidden="true"
  >
    <g>
      <component :is="iconComponent" class="icon" />
    </g>
  </svg>
</template>

<style scoped>
.icon {
  @apply h-4;
}
</style>
