import { getAnnouncement } from '@/services'
import type { Announcement } from '@/types'

export const useAnnouncementBar = () => {
  const loading = ref()
  const items = ref<Announcement[]>([])
  const error = ref()

  const fetchItems = async () => {
    try {
      const res = await getAnnouncement()
      items.value = res.data.data
      return res
    }
    catch (err) {
      error.value = err
      return err
    }
  }

  return {
    loading,
    items,
    fetchItems,
  }
}
