export interface IToggle {
  webinars: boolean
  introductionVideo: boolean
  partnerRegistration: boolean
  guides: boolean
  accountLookup: boolean
  googleSignIn: boolean
  accountCreation: boolean
  testAnnouncementBar: boolean
  requestPasswordByPhone: boolean

  [feature: string]: boolean
}

/**
 * Default features.
 * Used to reset current enabled features to its original state.
 */
export const DEFAULT_FEATURES: IToggle = {
  webinars: true,
  introductionVideo: true,
  partnerRegistration: true,
  guides: true,
  accountLookup: true,
  googleSignIn: false,
  accountCreation: false,
  testAnnouncementBar: false,
  requestPasswordByPhone: true,
}

/**
 * Current enabled features.
 * Can be changed by `$route.query.enable_feature` on certain pages.
 */
export const FEATURES: IToggle = {
  webinars: true,
  introductionVideo: true,
  partnerRegistration: true,
  guides: true,
  accountLookup: true,
  googleSignIn: false,
  accountCreation: false,
  testAnnouncementBar: false,
  requestPasswordByPhone: true,
}

export default FEATURES
