<script setup lang="ts">
import { useField } from 'vee-validate'
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import { ValidationError } from '@/components'

const props = defineProps<{
  name: string
  label?: string
  rules?: string
}>()

const { name, rules } = toRefs(props)

const { value, errorMessage, setErrors } = useField<string>(name, rules)

const date = ref<string | number>('')
const month = ref<string | number>('')
const year = ref<string | number>('')

watch(value, (val) => {
  if (!val)
    return

  const [eDate = '', eMonth = '', eYear = ''] = val.split('-')
  date.value = +eDate
  month.value = +eMonth
  year.value = +eYear
})

watch([date, month, year], ([newDate, newMonth, newYear]) => {
  if (newDate && newMonth && newYear) {
    const monthStr = String(newMonth).padStart(2, '0')
    const dateStr = String(newDate).padStart(2, '0')
    const dateObj = dayjs(`${newYear}-${monthStr}-${dateStr}T00:00:00.000Z`) // ISO 8601
    if (dateObj.isValid()) {
      value.value = dateObj.format('DD-MM-YYYY')
    }
    else {
      value.value = ''
      setErrors('Tanggal Tidak Valid')
    }
  }
  else {
    setErrors('Wajib Diisi')
  }
})

const currentYear = new Date().getFullYear()

const months: Record<number, string> = {
  1: 'Januari',
  2: 'Februari',
  3: 'Maret',
  4: 'April',
  5: 'Mei',
  6: 'Juni',
  7: 'Juli',
  8: 'Agustus',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Desember',
}
</script>

<template>
  <div class="mb-4">
    <label class="text-black block mb-1 text-15" :for="name">{{ label }}</label>
    <div class="flex gap-2">
      <select v-model="date" class="form-input">
        <option value="">
          Tanggal
        </option>
        <option v-for="i in 31" :key="i" :value="i">
          {{ i }}
        </option>
      </select>
      <select v-model="month" class="form-input">
        <option value="">
          Bulan
        </option>
        <option v-for="i in 12" :key="i" :value="i">
          {{ months[i] }}
        </option>
      </select>
      <select v-model="year" class="form-input">
        <option value="">
          Tahun
        </option>
        <option v-for="i in 101" :key="i" :value="currentYear - i">
          {{ currentYear - i }}
        </option>
      </select>
    </div>
    <ValidationError :message="errorMessage" />
  </div>
</template>
