import type { Module } from 'vuex'
import type { Toast } from '@wartek-id/belajar-id-components'
import type { RootState } from '../index'

export type ToastOptions = Omit<
  InstanceType<typeof Toast>['$props'],
  'modelValue'
> & { message?: string }

export interface ToastState {
  isOpen: boolean
  message: string
  options: ToastOptions
}

export const toast = {
  namespaced: true,
  state: {
    isOpen: false,
    message: '',
    options: {},
  },
  mutations: {
    show(state, { message, ...options }) {
      state.isOpen = true
      state.message = message
      state.options = options
    },
    hide(state) {
      state.isOpen = false
    },
    setIsOpen(state, val) {
      state.isOpen = val
    },
  },
} as Module<ToastState, RootState>
