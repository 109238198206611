<script setup lang="ts">
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from '@wartek-id/belajar-id-components'
import { useVModel } from '@vueuse/core'

interface Props {
  modelValue?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'zendesk:open'): void
}>()

const isOpen = useVModel(props, 'modelValue', emit)

const showZendeskWidget = () => {
  (window as any).zE('webWidget', 'open')
  emit('zendesk:open')
}
</script>

<template>
  <Modal v-slot="{ close }" v-model="isOpen" :width="380">
    <ModalHeader
      class="text-interactive-critical-default bg-white border-none"
      @close="close"
    >
      Data email atau nomor handphone belum terdaftar di Dapodik.
    </ModalHeader>
    <ModalBody class="pt-0 pb-5">
      <p class="font-semibold text-xl mb-5">
        Hubungi Butuh Bantuan untuk informasi lebih lanjut
      </p>

      <Button
        id="showWidget"
        variant="ultimate"
        type="button"
        full-width
        size="lg"
        prefix-icon="ri:question-line"
        @click="showZendeskWidget"
      >
        Butuh Bantuan
      </Button>
    </ModalBody>
  </Modal>
</template>
