import { defineComponent } from 'vue'
import type { IToggle } from '@/toggles'

export const featureToggleMixin = defineComponent({
  computed: {
    TOGGLES(): IToggle {
      return (this.$store.state as any).features
    },
  },
  watch: {
    '$route.query.enable_feature': {
      immediate: true,
      handler(features, prev) {
        if (features)
          this.enableFeaturesTmp(features)
        else if (prev && !features)
          this.resetFeatures()
      },
    },
  },
  methods: {
    enableFeaturesTmp(features: string) {
      features.split(',').forEach((feat) => {
        this.$store.commit('features/enable', feat)
      })
    },
    resetFeatures() {
      this.$store.commit('features/reset')
    },
  },
})
