export interface Faq {
  q: string
  a: string
  trackingValue?: string
  featured?: boolean
  isOpen?: boolean
  isHTML?: boolean
}

export interface FaqCategory {
  text: string
  value: any
  children?: FaqCategory[]
  items?: Faq[]
}

export default <FaqCategory[]>[
  {
    text: 'Umum',
    value: 'Umum',
    children: [
      {
        text: 'Informasi Umum',
        value: 'Informasi Umum',
        children: [
          {
            text: 'Tentang Akun belajar.id',
            value: 'Tentang Akun belajar.id',
            items: [
              {
                q: 'Apa itu Akun belajar.id?',
                a: 'Akun belajar.id merupakan nama akun (*User ID*) yang bertanda belajar.id dan kata sandi (password) yang dapat digunakan untuk mengakses berbagai aplikasi pembelajaran.',
                featured: true,
                trackingValue: 'faq_apa_itu_akun_pembelajaran',
              },
              {
                q: 'Apa manfaat dari memiliki Akun belajar.id?',
                a: `Dengan memiliki Akun belajar.id, Anda dapat:
            
1. Lebih mudah dan efektif dalam melaksanakan kegiatan belajar mengajar (konferensi video, dokumen daring, pengarsipan kelas, dan lain-lain)
2. Mengakses berbagai platform Kemendikbudristek menggunakan akun belajar.id 
3. Menyimpan data secara daring dengan lebih aman
4. Mengakses dan menggunakan Chromebook
5. Menerima informasi resmi dari Kemendikbudristek`,
                featured: false,
              },
              {
                q: 'Apakah Akun belajar.id dipungut biaya?',
                a: 'Penggunaan Akun belajar.id bebas biaya atau gratis.',
                featured: false,
              },
              {
                q: 'Siapa saja yang dapat memiliki Akun belajar.id?',
                a: `1. Peserta Didik, meliputi:
- Pendidikan Anak Usia Dini (PAUD)
- Sekolah Dasar (SD) dan Program Paket A kelas 1-6 
- Sekolah Menengah Pertama (SMP) dan Program Paket B kelas 7–9 
- Sekolah Menengah Atas (SMA) dan Program Paket C kelas 10–12 
- Sekolah Menengah Kejuruan (SMK) kelas 10–13 
- Sekolah Luar Biasa (SLB) kelas 5–12

2. Pendidik pada pendidikan anak usia dini, pendidikan dasar, dan pendidikan menengah

3. Tenaga Kependidikan pada pendidikan anak usia dini, pendidikan dasar, dan pendidikan menengah, meliputi: 
- Kepala Satuan Pendidikan (Kepala Sekolah)
- Operator Satuan Pendidikan (Operator Sekolah) yang terdata di Data Pokok Pendidikan (Dapodik)

4. Dinas Pendidikan, meliputi:
- Kepala Dinas Pendidikan
- Kepala Bidang pada Dinas Pendidikan
- Pengawas Sekolah
- Penilik Sekolah
- Pamong Belajar`,
                featured: true,
                trackingValue: 'faq_pemilik_akun_pembelajaran',
              },
              {
                q: 'Bagaimana cara mendapatkan Akun belajar.id?',
                a: `Akun belajar.id, berupa nama akun (*User ID*) dan kata sandi (password), bisa didapatkan secara mandiri melalui langkah berikut:
1. Buka halaman https://belajar.id/ 
    
2. Masukkan data-data berikut: 

    - Nama Lengkap
    - Nama Ibu Kandung
    - Tipe Pengguna 
    - Tanggal Lahir 
    - Nomor Pokok Satuan Pendidikan Nasional (NPSN)
    
3. Kirim detail akun ke email pribadi apabila belum pernah mendapatkan password.

4. Cek akun (*User ID*) dan kata sandi (password) pada email pribadi yang Anda daftarkan.

Selain itu, Akun belajar.id juga bisa didapatkan melalui Operator Satuan Pendidikan (Operator Sekolah) di sekolah Anda.`,
                featured: true,
                trackingValue: 'faq_cara_mendapatkan',
              },
              {
                q:
                  'Bagaimana cara membedakan nama akun (*User ID*) berdasarkan tipe pengguna?',
                a: `Setiap nama akun (*User ID*) memiliki domain khusus berdasarkan tipe pengguna sebagai berikut:
              
<div class="overflow-x-auto">
<table class="table-bordered">
  <thead>
    <tr>
      <th class="w-1/3">Jenjang</th>
      <th class="w-1/3">Peran</th>
      <th class="w-1/3">Nama Akun</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td rowspan="3">Pendidikan Anak Usia Dini</td>
      <td>Peserta Didik</td>
      <td>namaakun@PAUD.belajar.id</td>
    </tr>
    <tr>
      <td>Pendidik</td>
      <td>namaakun@Pendidik.PAUD.belajar.id</td>
    </tr>
    <tr>
      <td>Tenaga Kependidikan</td>
      <td>namaakun@admin.PAUD.belajar.id</td>
    </tr>
    <tr>
      <td rowspan="3">Sekolah Dasar</td>
      <td>Peserta Didik</td>
      <td>namaakun@sd.belajar.id</td>
    </tr>
    <tr>
      <td>Pendidik</td>
      <td>namaakun@Pendidik.sd.belajar.id</td>
    </tr>
    <tr>
      <td>Tenaga Kependidikan</td>
      <td>namaakun@admin.sd.belajar.id</td>
    </tr>
    <tr>
      <td rowspan="3">Sekolah Menengah Pertama</td>
      <td>Peserta Didik</td>
      <td>namaakun@smp.belajar.id</td>
    </tr>
    <tr>
      <td>Pendidik</td>
      <td>namaakun@Pendidik.smp.belajar.id</td>
    </tr>
    <tr>
      <td>Tenaga Kependidikan</td>
      <td>namaakun@admin.smp.belajar.id</td>
    </tr>
    <tr>
      <td rowspan="3">Sekolah Menengah Atas</td>
      <td>Peserta Didik</td>
      <td>namaakun@sma.belajar.id</td>
    </tr>
    <tr>
      <td>Pendidik</td>
      <td>namaakun@Pendidik.sma.belajar.id</td>
    </tr>
    <tr>
      <td>Tenaga Kependidikan</td>
      <td>namaakun@admin.sma.belajar.id</td>
    </tr>
    <tr>
      <td rowspan="3">Sekolah Menengah Kejuruan</td>
      <td>Peserta Didik</td>
      <td>namaakun@smk.belajar.id</td>
    </tr>
    <tr>
      <td>Pendidik</td>
      <td>namaakun@Pendidik.smk.belajar.id</td>
    </tr>
    <tr>
      <td>Tenaga Kependidikan</td>
      <td>namaakun@admin.smk.belajar.id</td>
    </tr>
    <tr>
      <td rowspan="3">Sekolah Luar Biasa</td>
      <td>Peserta Didik</td>
      <td>namaakun@slb.belajar.id</td>
    </tr>
    <tr>
      <td>Pendidik</td>
      <td>namaakun@Pendidik.slb.belajar.id</td>
    </tr>
    <tr>
      <td>Tenaga Kependidikan</td>
      <td>namaakun@admin.slb.belajar.id</td>
    </tr>
    <tr>
      <td rowspan="3">Program Paket A, Program Paket B, dan Program Paket C</td>
      <td>Peserta Didik</td>
      <td>namaakun@kesetaraan.belajar.id</td>
    </tr>
    <tr>
      <td>Pendidik</td>
      <td>namaakun@pendidik.kesetaraan.belajar.id</td>
    </tr>
    <tr>
      <td>Tenaga Kependidikan</td>
      <td>namaakun@admin.kesetaraan.belajar.id</td>
    </tr>
  </tbody>
</table>
</div>
                `,
                featured: false,
              },
            ],
          },
          {
            text: 'Status dan Aktivasi Akun',
            value: 'Status dan Aktivasi Akun',
            items: [
              {
                q:
                  'Bagaimana cara memeriksa ketersediaan Akun belajar.id saya?',
                a: `Anda dapat melakukan pengecekan di laman https://belajar.id/ dengan cara:
1. Periksa ketersediaan akun dengan memasukkan data-data berikut: 

    - Nama Lengkap
    - Nama Ibu Kandung
    - Tipe Pengguna 
    - Tanggal Lahir 
    - Nomor Pokok Satuan Pendidikan Nasional (NPSN)

2. Anda akan mendapatkan informasi apakah Akun belajar.id sudah tersedia atau belum.
  
    - Apabila akun sudah tersedia, Anda dapat mengirimkan detail informasi ke email pribadi Anda.
    - Apabila akun belum tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 

Selain itu, Anda juga dapat menanyakan kepada Operator Satuan Pendidikan (Operator Sekolah) di sekolah Anda untuk mengetahui status dan ketersediaan Akun belajar.id.
    `,
                featured: false,
              },
              {
                q:
                  'Akun saya sudah tersedia, bagaimana cara mendapatkan detail masuk akun (login) dan kata sandi (password)?',
                a: 'Silakan klik **"Kirim detail akun ke email pribadi saya"**, Anda akan mendapatkan informasi berupa akun (*User ID*) dan kata sandi (*Password*).',
                featured: false,
              },
              {
                q: 'Mengapa Akun belajar.id saya belum aktif?',
                a: 'Apabila status Akun belajar.id Anda belum aktif, Anda perlu melakukan aktivasi secara mandiri di gmail.com',
                featured: false,
              },
              {
                q: 'Bagaimana cara mengaktifkan Akun belajar.id?',
                a: `Setelah mendapatkan akun, Anda perlu melakukan aktivasi sesuai langkah berikut:
                
1. Buka halaman https://mail.google.com/ 
2. Masuk atau log in menggunakan nama akun (*User ID*) dan kata sandi (password) Anda
3. Setujui syarat dan ketentuan penggunaan Akun belajar.id
4. Lakukan penggantian kata sandi (password) Akun belajar.id
                `,
                featured: false,
              },
              {
                q:
                  'Mengapa Akun belajar.id saya sudah aktif padahal saya belum pernah mengaktivasi?',
                a: 'Perlu diketahui, Akun belajar.id hanya dapat diaktivasi secara mandiri oleh pemilik akun. Apabila Anda merasa tidak pernah mengaktifkan akun namun status akun telah aktif, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melakukan reset kata sandi (password)',
                featured: false,
              },
              {
                q:
                  'Bagaimana jika email belum masuk setelah klik "Kirim detail akun ke email pribadi saya"?',
                a: `1. Silakan tunggu beberapa saat sampai detail Akun belajar.id terkirim ke email pribadi Anda atau periksa folder spam
2. Apabila masih belum muncul silakan kirim ulang dengan klik kembali **"Kirim detail akun ke email pribadi saya"**
3. Apabila belum berhasil, Anda dapat menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melaporkan kendala yang dialami 
                `,
                featured: false,
              },
              {
                q:
                  'Bagaimana jika muncul keterangan "email tidak terdaftar" saat masuk akun (login) padahal saya sudah memiliki akun?',
                a: `Pastikan nama akun (*User ID*) dan kata sandi (password) yang Anda masukkan sudah benar. Apabila kendala masih dialami, silakan hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 
                `,
                featured: false,
              },
              {
                q: 'Mengapa akun saya tidak dapat ditemukan?',
                a: `Pada saat pemeriksaan ketersediaan akun, pastikan kembali data yang Anda masukkan berikut ini sudah benar:
            
  - Nama Lengkap
  - Nama Ibu Kandung
  - Peran Pengguna 
  - Tanggal Lahir 
  - Nomor Pokok Satuan Pendidikan Nasional (NPSN)
    
Perlu diketahui, Akun belajar.id dibuat berdasarkan data yang tercantum di Data Pokok Pendidikan (Dapodik). Jika Anda sudah memastikan ke Operator Satuan Pendidikan (Operator Sekolah) bahwa data Anda sudah terdaftar di Dapodik namun status Akun belajar.id tidak tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
                featured: false,
              },
            ],
          },
          {
            text: 'Perubahan Data Akun',
            value: 'Perubahan Data Akun',
            items: [
              {
                q:
                  'Bagaimana cara mengganti email pribadi di laman belajar.id?',
                a: `Untuk mengganti email pribadi silakan lakukan langkah-langkah berikut: 
1. Klik tombol **"untuk ganti email pribadi klik di sini"**
2. Masukkan email pribadi yang baru

Perlu diketahui, data email pribadi yang Anda masukkan hanya untuk keperluan mengirim detail Akun belajar.id, dan tidak akan mengubah data di Data Pokok Pendidikan (Dapodik).`,
                featured: false,
              },
              {
                q:
                  'Bagaimana cara mengganti email Akun belajar.id (belajar.id) saya?',
                a: 'Nama akun (*User ID*) Anda tidak dapat diganti karena sudah dirancang sesuai dengan ketentuan yang berlaku.',
                featured: false,
              },
              {
                q:
                  'Bagaimana jika Pendidik dan Peserta Didik lupa kata sandi (password) Akun belajar.id?',
                a: `Apabila Pendidik dan Peserta Didik tidak mengingat kata sandi (password), silakan menghubungi Admin Sekolah untuk mengajukan reset password.

Perlu diketahui, Anda hanya dapat melakukan reset password dengan dua kondisi berikut:
- Sesaat setelah aktivasi
- Setelah Anda menjadi pengguna aktif atau apabila Anda lupa kata sandi (password)

Admin Sekolah dapat melakukan reset password dengan mengikuti panduan [di sini](https://sites.google.com/wartek.belajar.id/bantuanbelajarid/seputar-akun/perubahan-data-akun/panduan-mengganti-password-akun-pembelajaran-untuk-admin-sekolah?authuser=0).

Selain itu, Anda juga dapat menghubungi *helpdesk* dengan klik tombol **Butuh Bantuan** di bagian kanan bawah layar Anda.`,
                featured: true,
                trackingValue: 'faq_lupa_kata_sandi',
              },
              {
                q: 'Saya tidak ingat *User ID* Akun belajar.id ',
                a: `Berikut cara mengakses detail ketersediaan Akun belajar.id Anda:
1. Buka halaman https://belajar.id/ 
2. Periksa ketersediaan akun dengan memasukkan Nama Lengkap, Nama Ibu Kandung, Jenis Pengguna, Tanggal Lahir, dan Nomor Pokok Satuan Pendidikan Nasional (NPSN) Anda
3. Kirim detail akun ke email pribadi apabila belum pernah mendapatkan kata sandi (*Password*). 
4. Periksa akun (*User ID*) dan kata sandi (password) pada email pribadi yang Anda daftarkan.`,
                featured: false,
              },
              {
                q:
                  'Saya ingin mengganti kata sandi (password) Akun belajar.id saya',
                a: `Anda dapat melakukan pergantian kata sandi (*password*) dengan dua kondisi:
1. Sesaat setelah aktivasi
2. Setelah Anda menjadi pengguna aktif atau apabila Anda lupa kata sandi (*password*).

Namun perlu diingat, ketika Anda sudah menjadi pengguna aktif, Anda hanya dapat mengganti password dengan cara menghubungi Admin Sekolah atau melalui Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kepemilikan Akun belajar.id',
            value: 'Kepemilikan Akun belajar.id',
            items: [
              {
                q:
                  'Apakah saya bisa memiliki lebih dari satu Akun belajar.id?',
                a: 'Bisa saja satu pengguna (Pendidik) memiliki beberapa Akun belajar.id. Banyaknya akun ini berdasarkan jumlah jenjang yang diampu yang terdata di Data Pokok Pendidikan (Dapodik)',
                featured: false,
              },
              {
                q:
                  'Sampai kapan Pendidik dan Peserta Didik dapat mengakses Akun belajar.id?',
                a: 'Akun belajar.id dapat digunakan selama status Anda sebagai Pendidik atau Peserta Didik masih tercatat di Data Pokok Pendidikan (Dapodik). Akun akan dihapus jika Anda sudah tidak berstatus sebagai Pendidik atau Peserta Didik. Namun jangan khawatir, sebelum akun dihapus, Anda akan diberitahu terlebih dahulu sehingga Anda dapat memindahkan data ke email atau perangkat lain.',
                featured: false,
              },
            ],
          },
          {
            text: 'Transisi Akun belajar.id',
            value: 'Transisi Akun belajar.id',
            items: [
              //               {
              //                 q:
              //                   'Bagaimana status kegunaan Akun belajar.id jika Pendidik berpindah sekolah atau jenjang?',
              //                 a: `Jika Pendidik berpindah sekolah tetapi masih dalam jenjang yang sama, maka Akun belajar.id tetap bisa digunakan. Namun, apabila Pendidik tersebut berpindah jenjang, maka Pendidik akan mendapatkan Akun belajar.id yang baru setelah perpindahan terdaftar di Data Pokok Pendidikan (Dapodik).`,
              //                 featured: false,
              //               },
              //               {
              //                 q:
              //                   'Bagaimana status kegunaan Akun belajar.id jika Peserta Didik berpindah sekolah atau jenjang?',
              //                 a: `Jika Peserta Didik berpindah sekolah tetapi masih dalam jenjang yang sama, maka Akun belajar.id tetap bisa digunakan. Akan tetapi, jika Peserta Didik tersebut berpindah jenjang, maka akan mendapatkan Akun belajar.id yang baru setelah perpindahannya terdaftar di Data Pokok Pendidikan (Dapodik).`,
              //                 featured: false,
              //               },
              //               {
              //                 q:
              //                   'Bagaimana proses transisi Akun belajar.id jika Pendidik berpindah jenjang?',
              //                 a: `Baik Pendidik maupun Peserta Didik harus membuat Akun belajar.id baru apabila berpindah jenjang, dengan detail proses:
              // 1. Operator Satuan Pendidikan (Operator Sekolah) akan mengganti data Pendidik atau Peserta Didik di Data Pokok Pendidikan (Dapodik)
              // 2. Pendidik atau Peserta Didik akan mendapatkan notifikasi melalui email bahwa Akun belajar.id akan dihapus dalam waktu 30 hari sejak notifikasi diterima
              // 3. Pendidik atau Peserta Didik mengajukan pembuatan Akun belajar.id baru melalui Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
              //                 featured: false,
              //               },
              //               {
              //                 q:
              //                   'Kapan notifikasi akan diberitahukan sebelum Akun belajar.id dihapus?',
              //                 a: `Pendidik atau Peserta Didik akan diberi peringatan berupa notifikasi sebanyak 3 kali:
              // 1. 30 hari sebelumnya
              // 2. Satu minggu sebelumnya
              // 3. Satu hari sebelumnya.
              // Sebelum Akun belajar.id dihapus, silakan mengajukan pembuatan Akun belajar.id baru ke Operator Satuan Pendidikan (Operator Sekolah). Namun, apabila notifikasi yang Anda terima salah, silakan laporkan hal tersebut ke Operator Satuan Pendidikan (Operator Sekolah) agar penghapusan akun dapat dihentikan.`,
              //                 featured: false,
              //               },
              //               {
              //                 q:
              //                   'Kapan batas waktu untuk melapor ke Operator Satuan Pendidikan (Operator Sekolah) jika notifikasi yang saya dapatkan salah?',
              //                 a: `Batas waktu pelaporan adalah lima hari setelah tanggal penghapusan yang tertera pada email notifikasi (termasuk akhir pekan). Operator Satuan Pendidikan (Operator Sekolah) harus segera melapor ke tim Unit Layanan Terpadu (ULT) agar laporan dapat segera ditindaklanjuti.
              // Jika setelah sepuluh hari dari tanggal penghapusan tidak ada laporan, maka Akun belajar.id akan dihapus dari sistem dan tidak dapat dipulihkan kembali.`,
              //                 featured: false,
              //               },
            ],
          },
          {
            text: 'Akun belajar.id Tidak Aktif',
            value: 'Akun belajar.id Tidak Aktif',
            items: [
              {
                q: 'Apa penyebab Akun belajar.id menjadi tidak aktif?',
                a: `1. Status pengguna tidak lagi terdaftar di Data Pokok Pendidikan (Dapodik)
2. Pengguna melakukan pelanggaran Syarat dan Ketentuan dari Google 
3. Perpindahan jenjang yang mengharuskan Akun lama menjadi tidak aktif`,
                featured: false,
              },
              {
                q: 'Mengapa akun saya di non-aktifkan?',
                a: 'Akun non-aktif bisa terjadi apabila Anda melakukan pelanggaran Syarat dan Ketentuan dari Akun belajar.id. Untuk informasi lebih lanjut silakah hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/',
                featured: false,
              },
            ],
          },
        ],
      },
      {
        text: 'Pemanfaatan Akun belajar.id',
        value: 'Bentuk dan fungsi Akun belajar.id',
        children: [
          {
            text: 'Bentuk dan fungsi Akun belajar.id',
            value: 'Bentuk dan fungsi Akun belajar.id',
            items: [
              {
                q: 'Bagaimana cara menggunakan Akun belajar.id?',
                a: `Setelah Pendidik atau Peserta Didik melakukan aktivasi Akun belajar.id, akun tersebut dapat langsung digunakan untuk mengakses aplikasi-aplikasi yang tersedia dalam Google Workspace (seperti Google Classroom, Google Drive, Google Docs, Google Sheets, Gmail, dan Google Calendar) serta aplikasi pembelajaran lainnya. Daftar aplikasi pembelajaran yang dapat diakses menggunakan Akun belajar.id tercantum pada laman utama www.belajar.id
            
Dengan Akun belajar.id ini pula, Anda bisa mencari *User ID* Pendidik dan Peserta Didik di sekolah Anda, dan menambahkannya ke dalam kelas di Google Classroom atau aplikasi Google lainnya.`,
                featured: false,
              },
              {
                q:
                  'Apakah Akun belajar.id selamanya harus berbentuk akun Google?',
                a: 'Terdapat kemungkinan bahwa di kemudian hari Kemendikbudristek dapat memutuskan untuk menggunakan sistem lain untuk Akun belajar.id.',
                featured: false,
              },
              {
                q:
                  'Apakah karena Akun belajar.id berbentuk akun Google, maka pengguna tidak bisa mengakses aplikasi perusahaan lain?',
                a: 'Tentu saja. Tidak hanya dapat digunakan untuk mengakses produk Google saja, namun Akun belajar.id juga dapat digunakan untuk mengakses aplikasi Kemendikbudristek, serta berbagai aplikasi pembelajaran lainnya di luar ekosistem Google.',
                featured: false,
              },
              {
                q: 'Mengapa Akun belajar.id menggunakan domain @belajar.id?',
                a: '@belajar.id merupakan nama domain yang dipilih Kemendikbudristek atas dasar kemudahan diingat oleh pengguna Akun belajar.id serta kesesuaian dengan esensi dari pendidikan itu sendiri.',
                featured: false,
              },
              {
                q: 'Apakah penggunaan Akun belajar.id diwajibkan?',
                a: `Penggunaan Akun belajar.id bersifat opsional. Namun, Kemendikbudristek menyarankan Peserta Didik, Pendidik, dan Tenaga Kependidikan untuk menggunakan Akun belajar.id karena:

1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek.`,
                featured: false,
              },
              {
                q:
                  'Apakah penggunaan Akun belajar.id menjadi persyaratan untuk menerima bantuan Teknologi Informasi dan Komunikasi (TIK) dari Kemendikbudristek?',
                a: 'Penggunaan Akun belajar.id tidak menjadi persyaratan untuk menerima bantuan TIK dari Kemendikbudristek.',
                featured: false,
              },
              {
                q:
                  'Jika saya sudah memiliki akun Google pribadi, apa yang harus saya lakukan?',
                a: `Pendidik dan Peserta Didik dapat memiliki akun Google pribadi sekaligus Akun belajar.id belajar.id. Penggunaan Akun belajar.id sangat dibutuhkan karena:
1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek, misalnya terkait bantuan pemerintah dan Asesmen Nasional.`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kebijakan penggunaan dan perlindungan data',
            value: 'Kebijakan penggunaan dan perlindungan data',
            items: [
              {
                q:
                  'Bagaimana perlindungan data pengguna pada Akun belajar.id?',
                a: `Perlindungan data pengguna diatur sesuai ketetapan Pusat Data dan Teknologi Informasi terhadap: 
a. kerahasiaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id; dan
b. kemungkinan terjadinya kelalaian dalam penggunaan dan/atau penyalahgunaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id.`,
                featured: false,
              },
              {
                q:
                  'Apa saja ketentuan data yang boleh disimpan di Akun belajar.id?',
                a: 'Sesuai dengan syarat dan ketentuan yang sudah ditetapkan, data yang tidak berhubungan dengan kegiatan sekolah atau belajar mengajar tidah boleh disimpan di Akun belajar.id. Data aktivitas pengguna Akun belajar.id juga tidak boleh digunakan untuk kepentingan komersil.',
                featured: false,
              },
              {
                q:
                  'Bagaimana cara memindahkan data dari Akun belajar.id lama ke yang baru?',
                a: 'Untuk mencadangkan atau memindahkan data dari dan ke Akun belajar.id, Anda dapat mengikuti langkah-langkahnya pada artikel berikut https://bit.ly/bid-transfer-data',
                featured: false,
              },
              {
                q:
                  'Apakah saya bisa membagikan akses data ke email selain belajar.id?',
                a: `Perlu diketahui, fitur membagikan akses fail atau dokumen ke email berbeda dengan Akun belajar.id hanya dapat dilakukan oleh Pendidik dan Tenaga Kependidikan saja.
Bagi Peserta Didik, dokumen yang disimpan pada Google Drive Akun belajar.id hanya bisa dibagikan ke sesama Akun belajar.id dengan jenjang yang sama dengan pemilik dokumen, baik membagikan akses melalui penambahan email maupun akses melalui tautan.`,
                featured: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    text: 'Pendidik',
    value: 'Pendidik',
    children: [
      {
        text: 'Informasi Umum',
        value: 'Informasi Umum',
        children: [
          {
            text: 'Tentang Akun belajar.id',
            value: 'Tentang Akun belajar.id',
            items: [
              {
                q: 'Bagaimana cara mendapatkan Akun belajar.id?',
                a: `Akun belajar.id, berupa nama akun (User ID) dan kata sandi (password), bisa didapatkan secara mandiri melalui langkah berikut:
1. Buka halaman https://belajar.id/

2. Masukkan data-data berikut:

  - Nama Lengkap
  - Nama Ibu Kandung
  - Tipe Pengguna 
  - Tanggal Lahir 
  - Nomor Pokok Satuan Pendidikan Nasional (NPSN)

3. Kirim detail akun ke email pribadi apabila belum pernah mendapatkan password.

4. Cek akun (User ID) dan kata sandi (password) pada email pribadi yang Anda daftarkan.

Selain itu, Akun belajar.id juga bisa didapatkan melalui Operator Satuan Pendidikan (Operator Sekolah) di sekolah Anda.`,
              },
            ],
          },
          {
            text: 'Status dan Aktivasi Akun',
            value: 'Status dan Aktivasi Akun',
            items: [
              {
                q: 'Bagaimana cara memeriksa ketersediaan Akun belajar.id saya?',
                a: `Anda dapat melakukan pengecekan di laman https://belajar.id/ dengan cara:
1. Periksa ketersediaan akun dengan memasukkan data-data berikut: 

- Nama Lengkap
- Nama Ibu Kandung
- Tipe Pengguna 
- Tanggal Lahir 
- Nomor Pokok Satuan Pendidikan Nasional (NPSN)

2. Anda akan mendapatkan informasi apakah Akun belajar.id sudah tersedia atau belum.
  
  - Apabila akun sudah tersedia, Anda dapat mengirimkan detail informasi ke email pribadi Anda.
  - Apabila akun belum tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 

Selain itu, Anda juga dapat menanyakan kepada Operator Satuan Pendidikan (Operator Sekolah) di sekolah Anda untuk mengetahui status dan ketersediaan Akun belajar.id.`,
              },
              {
                q:
                  'Akun saya sudah tersedia, bagaimana cara mendapatkan detail masuk akun (login) dan kata sandi (password)?',
                a: 'Silakan klik **"Kirim detail akun ke email pribadi saya"**, Anda akan mendapatkan informasi berupa akun (*User ID*) dan kata sandi (*Password*).',
                featured: false,
              },
              {
                q: 'Mengapa Akun belajar.id saya belum aktif?',
                a: 'Apabila status Akun belajar.id Anda belum aktif, Anda perlu melakukan aktivasi secara mandiri di gmail.com',
                featured: false,
              },
              {
                q: 'Bagaimana cara mengaktifkan Akun belajar.id?',
                a: `Setelah mendapatkan akun, Anda perlu melakukan aktivasi sesuai langkah berikut:
                
1. Buka halaman https://mail.google.com/ 
2. Masuk atau log in menggunakan nama akun (*User ID*) dan kata sandi (password) Anda
3. Setujui syarat dan ketentuan penggunaan Akun belajar.id
4. Lakukan penggantian kata sandi (password) Akun belajar.id
                `,
                featured: false,
              },
              {
                q:
                  'Mengapa Akun belajar.id saya sudah aktif padahal saya belum pernah mengaktivasi?',
                a: 'Perlu diketahui, Akun belajar.id hanya dapat diaktivasi secara mandiri oleh pemilik akun. Apabila Anda merasa tidak pernah mengaktifkan akun namun status akun telah aktif, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melakukan reset kata sandi (password)',
                featured: false,
              },
              {
                q:
                  'Bagaimana jika email belum masuk setelah klik "Kirim detail akun ke email pribadi saya"?',
                a: `1. Silakan tunggu beberapa saat sampai detail Akun belajar.id terkirim ke email pribadi Anda atau periksa folder spam
2. Apabila masih belum muncul silakan kirim ulang dengan klik kembali **"Kirim detail akun ke email pribadi saya"**
3. Apabila belum berhasil, Anda dapat menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melaporkan kendala yang dialami 
                `,
                featured: false,
              },
              {
                q:
                  'Bagaimana jika muncul keterangan "email tidak terdaftar" saat masuk akun (login) padahal saya sudah memiliki akun?',
                a: `Pastikan nama akun (*User ID*) dan kata sandi (password) yang Anda masukkan sudah benar. Apabila kendala masih dialami, silakan hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 
                `,
                featured: false,
              },
              {
                q: 'Mengapa akun saya tidak dapat ditemukan?',
                a: `Pada saat pemeriksaan ketersediaan akun, pastikan kembali data yang Anda masukkan berikut ini sudah benar:
            
  - Nama Lengkap
  - Nama Ibu Kandung
  - Peran Pengguna 
  - Tanggal Lahir 
  - Nomor Pokok Satuan Pendidikan Nasional (NPSN)
    
Perlu diketahui, Akun belajar.id dibuat berdasarkan data yang tercantum di Data Pokok Pendidikan (Dapodik). Jika Anda sudah memastikan ke Operator Satuan Pendidikan (Operator Sekolah) bahwa data Anda sudah terdaftar di Dapodik namun status Akun belajar.id tidak tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
                featured: false,
              },
            ],
          },
          {
            text: 'Perubahan Data Akun',
            value: 'Perubahan Data Akun',
            items: [
              {
                q:
                  'Bagaimana cara mengganti email pribadi di laman belajar.id?',
                a: `Untuk mengganti email pribadi silakan lakukan langkah-langkah berikut: 
1. Klik tombol **"untuk ganti email pribadi klik di sini"**
2. Masukkan email pribadi yang baru

Perlu diketahui, data email pribadi yang Anda masukkan hanya untuk keperluan mengirim detail Akun belajar.id, dan tidak akan mengubah data di Data Pokok Pendidikan (Dapodik).`,
                featured: false,
              },
              {
                q:
                  'Bagaimana cara mengganti email Akun belajar.id (belajar.id) saya?',
                a: 'Nama akun (*User ID*) Anda tidak dapat diganti karena sudah dirancang sesuai dengan ketentuan yang berlaku.',
                featured: false,
              },
              {
                q:
                  'Bagaimana jika saya lupa kata sandi (password) Akun belajar.id?',
                a: `Apabila Anda tidak mengingat kata sandi (*password*), silakan menghubungi Admin Sekolah untuk mengajukan *reset password*.

Perlu diketahui, Anda hanya dapat melakukan *reset password* dengan dua kondisi berikut:
- Sesaat setelah aktivasi
- Setelah Anda menjadi pengguna aktif atau apabila Anda lupa kata sandi (*password*)

Admin Sekolah dapat melakukan *reset password* dengan mengikuti panduan [di sini](https://sites.google.com/wartek.belajar.id/bantuanbelajarid/seputar-akun/perubahan-data-akun/panduan-mengganti-password-akun-pembelajaran-untuk-admin-sekolah?authuser=0).

Selain itu, Anda juga dapat menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/.`,
                featured: false,
              },
              {
                q: 'Saya tidak ingat *User ID* Akun belajar.id ',
                a: `Berikut cara mengakses detail ketersediaan Akun belajar.id Anda:
1. Buka halaman https://belajar.id/ 
2. Periksa ketersediaan akun dengan memasukkan Nama Lengkap, Nama Ibu Kandung, Jenis Pengguna, Tanggal Lahir, dan Nomor Pokok Satuan Pendidikan Nasional (NPSN) Anda
3. Kirim detail akun ke email pribadi apabila belum pernah mendapatkan kata sandi (*Password*). 
4. Periksa akun (*User ID*) dan kata sandi (password) pada email pribadi yang Anda daftarkan.`,
                featured: false,
              },
              {
                q:
                  'Saya ingin mengganti kata sandi (password) Akun belajar.id saya',
                a: `Anda dapat melakukan pergantian kata sandi (password) dengan dua kondisi:
1. Sesaat setelah aktivasi
2. Setelah Anda menjadi pengguna aktif atau apabila Anda lupa kata sandi (password).

Namun perlu diingat, ketika Anda sudah menjadi pengguna aktif, Anda hanya dapat mengganti password dengan cara menghubungi Admin Sekolah atau melalui Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kepemilikan Akun belajar.id',
            value: 'Kepemilikan Akun belajar.id',
            items: [
              {
                q:
                  'Apakah saya bisa memiliki lebih dari satu Akun belajar.id?',
                a: 'Pendidik bisa saja memiliki beberapa Akun belajar.id. Banyaknya akun ini berdasarkan jumlah jenjang yang diampu yang terdata di Data Pokok Pendidikan (Dapodik)',
                featured: false,
              },
              {
                q:
                  'Sampai kapan Pendidik dan Peserta Didik dapat mengakses Akun belajar.id?',
                a: 'Akun belajar.id dapat digunakan selama status Anda sebagai Pendidik atau Peserta Didik masih tercatat di Data Pokok Pendidikan (Dapodik). Akun akan dihapus jika Anda sudah tidak berstatus sebagai Pendidik atau Peserta Didik. Namun jangan khawatir, sebelum akun dihapus, Anda akan diberitahu terlebih dahulu sehingga Anda dapat memindahkan data ke email atau perangkat lain.',
                featured: false,
              },
            ],
          },
          {
            text: 'Transisi Akun belajar.id',
            value: 'Transisi Akun belajar.id',
            items: [
              {
                q: 'Bagaimana status kegunaan Akun belajar.id jika saya berpindah sekolah atau jenjang?',
                a: 'Jika Anda berpindah sekolah tetapi masih dalam jenjang yang sama, maka Akun belajar.id tetap bisa digunakan. Namun, apabila Anda berpindah jenjang, maka Anda akan mendapatkan Akun belajar.id yang baru setelah perpindahan terdaftar di Data Pokok Pendidikan (Dapodik).',
              },
              {
                q: 'Bagaimana proses transisi Akun belajar.id jika saya berpindah jenjang?',
                a: `Pendidik harus membuat Akun belajar.id baru apabila berpindah jenjang, dengan detail proses:

1. Operator Satuan Pendidikan (Operator Sekolah) akan mengganti data Pendidik di Data Pokok Pendidikan (Dapodik)
2. Pendidik akan mendapatkan notifikasi melalui email bahwa Akun belajar.id akan dihapus dalam waktu 30 hari sejak notifikasi diterima 
3. Pendidik mengajukan pembuatan Akun belajar.id baru melalui Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
              },
              {
                q: 'Kapan notifikasi akan diberitahukan sebelum Akun belajar.id dihapus?',
                a: `Anda akan diberi peringatan berupa notifikasi sebanyak 2 kali: 
1. 30 hari sebelumnya 
2. Tiga hari sebelumnya 

Sebelum Akun belajar.id dihapus, silakan mengajukan pembuatan Akun belajar.id baru ke Operator Satuan Pendidikan (Operator Sekolah). Namun, apabila notifikasi yang Anda terima salah, silakan laporkan hal tersebut ke Operator Satuan Pendidikan (Operator Sekolah) agar penghapusan akun dapat dihentikan.`,
              },
              {
                q: 'Kapan batas waktu untuk melapor ke Operator Satuan Pendidikan (Operator Sekolah) jika notifikasi yang saya dapatkan salah?',
                a: `Batas waktu pelaporan adalah lima hari setelah tanggal penghapusan yang tertera pada email notifikasi (termasuk akhir pekan). Operator Satuan Pendidikan (Operator Sekolah) harus segera melapor ke tim Unit Layanan Terpadu (ULT) agar laporan dapat segera ditindaklanjuti. 

Jika setelah sepuluh hari dari tanggal penghapusan tidak ada laporan, maka Akun belajar.id akan dihapus dari sistem dan tidak dapat dipulihkan kembali.`,
              },
            ],
          },
          {
            text: 'Akun belajar.id Tidak Aktif',
            value: 'Akun belajar.id Tidak Aktif',
            items: [
              {
                q: 'Apa penyebab Akun belajar.id menjadi tidak aktif?',
                a: `1. Status pengguna tidak lagi terdaftar di Data Pokok Pendidikan (Dapodik)
2. Pengguna melakukan pelanggaran Syarat dan Ketentuan dari Google 
3. Perpindahan jenjang yang mengharuskan Akun lama menjadi tidak aktif`,
                featured: false,
              },
              {
                q: 'Mengapa akun saya di non-aktifkan?',
                a: 'Akun non-aktif bisa terjadi apabila Anda melakukan pelanggaran Syarat dan Ketentuan dari Akun belajar.id. Untuk informasi lebih lanjut silakah hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/',
                featured: false,
              },
            ],
          },
        ],
      },
      {
        text: 'Pemanfaatan Akun belajar.id',
        value: 'Pemanfaatan Akun belajar.id',
        children: [
          {
            text: 'Bentuk dan fungsi Akun belajar.id',
            value: 'Bentuk dan fungsi Akun belajar.id',
            items: [
              {
                q: 'Bagaimana cara menggunakan Akun belajar.id?',
                a: `Setelah Pendidik atau Peserta Didik melakukan aktivasi Akun belajar.id, akun tersebut dapat langsung digunakan untuk mengakses aplikasi-aplikasi yang tersedia dalam Google Workspace (seperti Google Classroom, Google Drive, Google Docs, Google Sheets, Gmail, dan Google Calendar) serta aplikasi pembelajaran lainnya. Daftar aplikasi pembelajaran yang dapat diakses menggunakan Akun belajar.id tercantum pada laman utama www.belajar.id
            
Dengan Akun belajar.id ini pula, Anda bisa mencari *User ID* Pendidik dan Peserta Didik di sekolah Anda, dan menambahkannya ke dalam kelas di Google Classroom atau aplikasi Google lainnya.`,
                featured: false,
              },
              {
                q: 'Apakah penggunaan Akun belajar.id diwajibkan?',
                a: `Penggunaan Akun belajar.id bersifat opsional. Namun, Kemendikbudristek menyarankan Peserta Didik, Pendidik, dan Tenaga Kependidikan untuk menggunakan Akun belajar.id karena:
            
1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek.`,
                featured: false,
              },
              {
                q:
                  'Apakah penggunaan Akun belajar.id menjadi persyaratan untuk menerima bantuan Teknologi Informasi dan Komunikasi (TIK) dari Kemendikbudristek?',
                a: 'Penggunaan Akun belajar.id tidak menjadi persyaratan untuk menerima bantuan TIK dari Kemendikbudristek.',
                featured: false,
              },
              {
                q:
                  'Jika saya sudah memiliki akun Google pribadi, apa yang harus saya lakukan?',
                a: `Pendidik dan Peserta Didik dapat memiliki akun Google pribadi sekaligus Akun belajar.id belajar.id. Penggunaan Akun belajar.id sangat dibutuhkan karena:
1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek, misalnya terkait bantuan pemerintah dan Asesmen Nasional.`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kebijakan penggunaan dan perlindungan data',
            value: 'Kebijakan penggunaan dan perlindungan data',
            items: [
              {
                q:
                  'Bagaimana perlindungan data pengguna pada Akun belajar.id?',
                a: `Perlindungan data pengguna diatur sesuai ketetapan Pusat Data dan Teknologi Informasi terhadap: 
a. kerahasiaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id; dan
b. kemungkinan terjadinya kelalaian dalam penggunaan dan/atau penyalahgunaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id.`,
                featured: false,
              },
              {
                q:
                  'Apa saja ketentuan data yang boleh disimpan di Akun belajar.id?',
                a: 'Sesuai dengan syarat dan ketentuan yang sudah ditetapkan, data yang tidak berhubungan dengan kegiatan sekolah atau belajar mengajar tidah boleh disimpan di Akun belajar.id. Data aktivitas pengguna Akun belajar.id juga tidak boleh digunakan untuk kepentingan komersil.',
                featured: false,
              },
              {
                q:
                  'Bagaimana cara memindahkan data dari Akun belajar.id lama ke yang baru?',
                a: 'Untuk mencadangkan atau memindahkan data dari dan ke Akun belajar.id, Anda dapat mengikuti langkah-langkahnya pada artikel berikut https://bit.ly/bid-transfer-data',
                featured: false,
              },
              {
                q:
                  'Apakah saya bisa membagikan akses data ke email selain belajar.id?',
                a: 'Bisa. Fitur membagikan akses fail atau dokumen ke email berbeda dengan Akun belajar.id dapat dilakukan oleh Pendidik dan Tenaga Kependidikan saja.',
                featured: false,
              },
              {
                q: 'Apakah Pendidik dapat melakukan perekaman pada saat rapat?',
                a: `Ya. Pendidik dapat menggunakan fitur perekaman saat rapat (recording Meet) hingga 10 Januari 2022. Setelah itu, fitur recording Meet akan dinonaktifkan oleh Google Workspace of Education bagi seluruh pengguna.

Sebagai alternatif, Anda dapat menggunakan fitur perekaman rapat yang tersedia di komputer masing-masing. Silakan ikuti panduan penggunaannya pada tautan berikut: http://bit.ly/TutorialPerekamanVideo`,
                featured: false,
              },
              {
                q:
                  'Bagaimana dengan video perekaman yang telah saya rekam jika fitur perekaman saat rapat dinonatifkan?',
                a: 'Tidak perlu khawatir, video yang Anda rekam sebelum 10 Januari 2022 akan tetap tersimpan dan dapat diakses pada Drive Akun belajar.id Anda.',
                featured: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    text: 'Peserta Didik',
    value: 'Peserta Didik',
    children: [
      {
        text: 'Informasi Umum',
        value: 'Informasi Umum',
        children: [
          {
            text: 'Tentang Akun belajar.id',
            value: 'Tentang Akun belajar.id',
            items: [
              {
                q: 'Bagaimana cara mendapatkan Akun belajar.id?',
                a: `Akun belajar.id, berupa nama akun (*User ID*) dan kata sandi (password), bisa didapatkan secara mandiri melalui langkah berikut:
1. Buka halaman https://belajar.id/ 
    
2. Masukkan data-data berikut: 

    - Nama Lengkap
    - Nama Ibu Kandung
    - Tipe Pengguna 
    - Tanggal Lahir 
    - Nomor Pokok Satuan Pendidikan Nasional (NPSN)
    
3. Kirim detail akun ke email pribadi apabila belum pernah mendapatkan password.

4. Cek akun (*User ID*) dan kata sandi (password) pada email pribadi yang Anda daftarkan.

Selain itu, Akun belajar.id juga bisa didapatkan melalui Operator Satuan Pendidikan (Operator Sekolah) di sekolah Anda.`,
                featured: true,
                trackingValue: 'faq_cara_mendapatkan',
              },
            ],
          },
          {
            text: 'Status dan Aktivasi Akun',
            value: 'Status dan Aktivasi Akun',
            items: [
              {
                q:
                  'Bagaimana cara memeriksa ketersediaan Akun belajar.id saya?',
                a: `Anda dapat melakukan pengecekan di laman https://belajar.id/ dengan cara:
1. Periksa ketersediaan akun dengan memasukkan data-data berikut: 

    - Nama Lengkap
    - Nama Ibu Kandung
    - Tipe Pengguna 
    - Tanggal Lahir 
    - Nomor Pokok Satuan Pendidikan Nasional (NPSN)

2. Anda akan mendapatkan informasi apakah Akun belajar.id sudah tersedia atau belum.
  
    - Apabila akun sudah tersedia, Anda dapat mengirimkan detail informasi ke email pribadi Anda.
    - Apabila akun belum tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 

Selain itu, Anda juga dapat menanyakan kepada Operator Satuan Pendidikan (Operator Sekolah) di sekolah Anda untuk mengetahui status dan ketersediaan Akun belajar.id.
    `,
                featured: false,
              },
              {
                q:
                  'Akun saya sudah tersedia, bagaimana cara mendapatkan detail masuk akun (login) dan kata sandi (password)?',
                a: 'Silakan klik **"Kirim detail akun ke email pribadi saya"**, Anda akan mendapatkan informasi berupa akun (*User ID*) dan kata sandi (*Password*).',
                featured: false,
              },
              {
                q: 'Mengapa Akun belajar.id saya belum aktif?',
                a: 'Apabila status Akun belajar.id Anda belum aktif, Anda perlu melakukan aktivasi secara mandiri di gmail.com',
                featured: false,
              },
              {
                q: 'Bagaimana cara mengaktifkan Akun belajar.id?',
                a: `Setelah mendapatkan akun, Anda perlu melakukan aktivasi sesuai langkah berikut:
                
1. Buka halaman https://mail.google.com/ 
2. Masuk atau log in menggunakan nama akun (*User ID*) dan kata sandi (password) Anda
3. Setujui syarat dan ketentuan penggunaan Akun belajar.id
4. Lakukan penggantian kata sandi (password) Akun belajar.id
                `,
                featured: false,
              },
              {
                q:
                  'Mengapa Akun belajar.id saya sudah aktif padahal saya belum pernah mengaktivasi?',
                a: 'Perlu diketahui, Akun belajar.id hanya dapat diaktivasi secara mandiri oleh pemilik akun. Apabila Anda merasa tidak pernah mengaktifkan akun namun status akun telah aktif, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melakukan reset kata sandi (password)',
                featured: false,
              },
              {
                q:
                  'Bagaimana jika email belum masuk setelah klik "Kirim detail akun ke email pribadi saya"?',
                a: `1. Silakan tunggu beberapa saat sampai detail Akun belajar.id terkirim ke email pribadi Anda atau periksa folder spam
2. Apabila masih belum muncul silakan kirim ulang dengan klik kembali **"Kirim detail akun ke email pribadi saya"**
3. Apabila belum berhasil, Anda dapat menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melaporkan kendala yang dialami 
                `,
                featured: false,
              },
              {
                q:
                  'Bagaimana jika muncul keterangan "email tidak terdaftar" saat masuk akun (login) padahal saya sudah memiliki akun?',
                a: `Pastikan nama akun (*User ID*) dan kata sandi (password) yang Anda masukkan sudah benar. Apabila kendala masih dialami, silakan hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 
                `,
                featured: false,
              },
              {
                q: 'Mengapa akun saya tidak dapat ditemukan?',
                a: `Pada saat pemeriksaan ketersediaan akun, pastikan kembali data yang Anda masukkan berikut ini sudah benar:
            
  - Nama Lengkap
  - Nama Ibu Kandung
  - Peran Pengguna 
  - Tanggal Lahir 
  - Nomor Pokok Satuan Pendidikan Nasional (NPSN)
    
Perlu diketahui, Akun belajar.id dibuat berdasarkan data yang tercantum di Data Pokok Pendidikan (Dapodik). Jika Anda sudah memastikan ke Operator Satuan Pendidikan (Operator Sekolah) bahwa data Anda sudah terdaftar di Dapodik namun status Akun belajar.id tidak tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
                featured: false,
              },
            ],
          },
          {
            text: 'Perubahan Data Akun',
            value: 'Perubahan Data Akun',
            items: [
              {
                q:
                  'Bagaimana cara mengganti email pribadi di laman belajar.id?',
                a: `Untuk mengganti email pribadi silakan lakukan langkah-langkah berikut: 
1. Klik tombol **"untuk ganti email pribadi klik di sini"**
2. Masukkan email pribadi yang baru

Perlu diketahui, data email pribadi yang Anda masukkan hanya untuk keperluan mengirim detail Akun belajar.id, dan tidak akan mengubah data di Data Pokok Pendidikan (Dapodik).`,
                featured: false,
              },
              {
                q:
                  'Bagaimana cara mengganti email Akun belajar.id (belajar.id) saya?',
                a: 'Nama akun (*User ID*) Anda tidak dapat diganti karena sudah dirancang sesuai dengan ketentuan yang berlaku.',
                featured: false,
              },
              {
                q:
                  'Bagaimana jika saya lupa kata sandi (password) Akun belajar.id?',
                a: `Apabila Anda tidak mengingat kata sandi (*password*), silakan menghubungi Admin Sekolah untuk mengajukan *reset password*.

Perlu diketahui, Anda hanya dapat melakukan *reset password* dengan dua kondisi berikut:
- Sesaat setelah aktivasi
- Setelah Anda menjadi pengguna aktif atau apabila Anda lupa kata sandi (*password*)

Admin Sekolah dapat melakukan *reset password* dengan mengikuti panduan [di sini](https://sites.google.com/wartek.belajar.id/bantuanbelajarid/seputar-akun/perubahan-data-akun/panduan-mengganti-password-akun-pembelajaran-untuk-admin-sekolah?authuser=0).

Selain itu, Anda juga dapat menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/.`,
                featured: false,
              },
              {
                q: 'Saya tidak ingat *User ID* Akun belajar.id ',
                a: `Berikut cara mengakses detail ketersediaan Akun belajar.id Anda:
1. Buka halaman https://belajar.id/ 
2. Periksa ketersediaan akun dengan memasukkan Nama Lengkap, Nama Ibu Kandung, Jenis Pengguna, Tanggal Lahir, dan Nomor Pokok Satuan Pendidikan Nasional (NPSN) Anda
3. Kirim detail akun ke email pribadi apabila belum pernah mendapatkan kata sandi (*Password*). 
4. Periksa akun (*User ID*) dan kata sandi (password) pada email pribadi yang Anda daftarkan.`,
                featured: false,
              },
              {
                q:
                  'Saya ingin mengganti kata sandi (password) Akun belajar.id saya',
                a: `Anda dapat melakukan pergantian kata sandi (password) dengan dua kondisi:
1. Sesaat setelah aktivasi
2. Setelah Anda menjadi pengguna aktif atau apabila Anda lupa kata sandi (password).

Namun perlu diingat, ketika Anda sudah menjadi pengguna aktif, Anda hanya dapat mengganti password dengan cara menghubungi Admin Sekolah atau melalui Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kepemilikan Akun belajar.id',
            value: 'Kepemilikan Akun belajar.id',
            items: [
              {
                q:
                  'Apakah saya bisa memiliki lebih dari satu Akun belajar.id?',
                a: 'Tidak bisa. Peserta Didik hanya dapat memiliki satu Akun belajar.id.',
                featured: false,
              },
              {
                q:
                  'Sampai kapan Pendidik dan Peserta Didik dapat mengakses Akun belajar.id?',
                a: 'Akun belajar.id dapat digunakan selama status Anda sebagai Pendidik atau Peserta Didik masih tercatat di Data Pokok Pendidikan (Dapodik). Akun akan dihapus jika Anda sudah tidak berstatus sebagai Pendidik atau Peserta Didik. Namun jangan khawatir, sebelum akun dihapus, Anda akan diberitahu terlebih dahulu sehingga Anda dapat memindahkan data ke email atau perangkat lain.',
                featured: false,
              },
            ],
          },
          {
            text: 'Transisi Akun belajar.id',
            value: 'Transisi Akun belajar.id',
            items: [
              {
                q: 'Bagaimana status kegunaan Akun belajar.id jika saya berpindah sekolah atau jenjang?',
                a: 'Jika Anda berpindah sekolah tetapi masih dalam jenjang yang sama, maka Akun belajar.id tetap bisa digunakan. Namun, apabila Anda berpindah jenjang, maka Anda akan mendapatkan Akun belajar.id yang baru setelah perpindahan terdaftar di Data Pokok Pendidikan (Dapodik).',
              },
              {
                q: 'Kapan notifikasi akan diberitahukan sebelum Akun belajar.id dihapus?',
                a: `Anda akan diberi peringatan berupa notifikasi sebanyak 3 kali: 
1. 30 hari sebelumnya 
2. Satu minggu sebelumnya 
3. Satu hari sebelumnya. 
                
Sebelum Akun belajar.id dihapus, silakan mengajukan pembuatan Akun belajar.id baru ke Operator Satuan Pendidikan (Operator Sekolah). Namun, apabila notifikasi yang Anda terima salah, silakan laporkan hal tersebut ke Operator Satuan Pendidikan (Operator Sekolah) agar penghapusan akun dapat dihentikan.`,
              },
              {
                q: 'Kapan batas waktu untuk melapor ke Operator Satuan Pendidikan (Operator Sekolah) jika notifikasi yang saya dapatkan salah?',
                a: `Batas waktu pelaporan adalah lima hari setelah tanggal penghapusan yang tertera pada email notifikasi (termasuk akhir pekan). Operator Satuan Pendidikan (Operator Sekolah) harus segera melapor ke tim Unit Layanan Terpadu (ULT) agar laporan dapat segera ditindaklanjuti. 

Jika setelah sepuluh hari dari tanggal penghapusan tidak ada laporan, maka Akun belajar.id akan dihapus dari sistem dan tidak dapat dipulihkan kembali.`,
              },
            ],
          },
          {
            text: 'Akun belajar.id Tidak Aktif',
            value: 'Akun belajar.id Tidak Aktif',
            items: [
              {
                q: 'Apa penyebab Akun belajar.id menjadi tidak aktif?',
                a: `1. Status pengguna tidak lagi terdaftar di Data Pokok Pendidikan (Dapodik)
2. Pengguna melakukan pelanggaran Syarat dan Ketentuan dari Google 
3. Perpindahan jenjang yang mengharuskan Akun lama menjadi tidak aktif`,
                featured: false,
              },
              {
                q: 'Mengapa akun saya di non-aktifkan?',
                a: 'Akun non-aktif bisa terjadi apabila Anda melakukan pelanggaran Syarat dan Ketentuan dari Akun belajar.id. Untuk informasi lebih lanjut silakah hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/',
                featured: false,
              },
            ],
          },
        ],
      },
      {
        text: 'Pemanfaatan Akun belajar.id',
        value: 'Pemanfaatan Akun belajar.id',
        children: [
          {
            text: 'Bentuk dan fungsi Akun belajar.id',
            value: 'Bentuk dan fungsi Akun belajar.id',
            items: [
              {
                q: 'Bagaimana cara menggunakan Akun belajar.id?',
                a: `Setelah Pendidik atau Peserta Didik melakukan aktivasi Akun belajar.id, akun tersebut dapat langsung digunakan untuk mengakses aplikasi-aplikasi yang tersedia dalam Google Workspace (seperti Google Classroom, Google Drive, Google Docs, Google Sheets, Gmail, dan Google Calendar) serta aplikasi pembelajaran lainnya. Daftar aplikasi pembelajaran yang dapat diakses menggunakan Akun belajar.id tercantum pada laman utama www.belajar.id
            
Dengan Akun belajar.id ini pula, Anda bisa mencari *User ID* Pendidik dan Peserta Didik di sekolah Anda, dan menambahkannya ke dalam kelas di Google Classroom atau aplikasi Google lainnya.`,
                featured: false,
              },
              {
                q: 'Apakah penggunaan Akun belajar.id diwajibkan?',
                a: `Penggunaan Akun belajar.id bersifat opsional. Namun, Kemendikbudristek menyarankan Peserta Didik, Pendidik, dan Tenaga Kependidikan untuk menggunakan Akun belajar.id karena:
            
1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek.`,
                featured: false,
              },
              {
                q:
                  'Jika saya sudah memiliki akun Google pribadi, apa yang harus saya lakukan?',
                a: `Pendidik dan Peserta Didik dapat memiliki akun Google pribadi sekaligus Akun belajar.id belajar.id. Penggunaan Akun belajar.id sangat dibutuhkan karena:
1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek, misalnya terkait bantuan pemerintah dan Asesmen Nasional.`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kebijakan penggunaan dan perlindungan data',
            value: 'Kebijakan penggunaan dan perlindungan data',
            items: [
              {
                q:
                  'Bagaimana perlindungan data pengguna pada Akun belajar.id?',
                a: `Perlindungan data pengguna diatur sesuai ketetapan Pusat Data dan Teknologi Informasi terhadap: 
a. kerahasiaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id; dan
b. kemungkinan terjadinya kelalaian dalam penggunaan dan/atau penyalahgunaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id.`,
                featured: false,
              },
              {
                q:
                  'Apa saja ketentuan data yang boleh disimpan di Akun belajar.id?',
                a: 'Sesuai dengan syarat dan ketentuan yang sudah ditetapkan, data yang tidak berhubungan dengan kegiatan sekolah atau belajar mengajar tidah boleh disimpan di Akun belajar.id. Data aktivitas pengguna Akun belajar.id juga tidak boleh digunakan untuk kepentingan komersil.',
                featured: false,
              },
              {
                q:
                  'Bagaimana cara memindahkan data dari Akun belajar.id lama ke yang baru?',
                a: 'Untuk mencadangkan atau memindahkan data dari dan ke Akun belajar.id, Anda dapat mengikuti langkah-langkahnya pada artikel berikut https://bit.ly/bid-transfer-data',
                featured: false,
              },
              {
                q:
                  'Apakah saya bisa membagikan akses data ke email selain belajar.id?',
                a: `Perlu diketahui, fitur membagikan akses fail atau dokumen ke email berbeda dengan Akun belajar.id hanya dapat dilakukan oleh Pendidik dan Tenaga Kependidikan saja.
Bagi Peserta Didik, dokumen yang disimpan pada Google Drive Akun belajar.id hanya bisa dibagikan ke sesama Akun belajar.id dengan jenjang yang sama dengan pemilik dokumen, baik membagikan akses melalui penambahan email maupun akses melalui tautan.`,
                featured: false,
              },
              {
                q: 'Apakah saya dapat membuat Drive Bersama?',
                a: 'Tidak bisa. Peserta Didik tidak dapat membuat, mengubah sharing setting, ataupun menambahkan anggota ke Drive Bersama pada Akun belajar.id.',
                featured: false,
              },
              {
                q: 'Apakah saya dapat menggunakan fitur Google Chat dengan email selain belajar.id?',
                a: 'Tidak bisa. Fitur Google Chat pada Akun belajar.id Anda, baik dalam membuat dan bergabung di ruangan percakapan, hanya dapat digunakan ke sesama domain Akun belajar.id (belajar.id) pada jenjang yang sama. ',
                featured: false,
              },
              {
                q: 'Apakah saya dapat melakukan perekaman pada saat rapat?',
                a: 'Peserta Didik tidak bisa melakukan perekaman saat rapat menggunakan fitur recording Meet. Namun, Anda dapat menggunakan fitur perekaman rapat yang tersedia di komputer masing-masing. Silakan ikuti panduan penggunaannya pada tautan berikut: http://bit.ly/TutorialPerekamanVideo',
                featured: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    text: 'Tenaga Kependidikan',
    value: 'Tenaga Kependidikan',
    children: [
      {
        text: 'Informasi Umum',
        value: 'Informasi Umum',
        children: [
          {
            text: 'Tentang Akun belajar.id',
            value: 'Tentang Akun belajar.id',
            items: [
              {
                q:
                  'Bagaimana cara mendapatkan Akun belajar.id bagi Operator Satuan Pendidikan (Operator Sekolah)?',
                a: `Operator Satuan Pendidikan (Operator Sekolah) bisa mendapatkan Akun belajar.id dengan cara berikut:
1. Buka laman http://pd.data.kemdikbud.go.id/

2. Log in menggunakan akun SSO Data Pokok Pendidikan (Dapodik) di mana akun terdaftar di http://sdm.data.kemdikbud.go.id

3. Klik tombol **“Unduh Akun”**, pilih **“Peserta Didik**” atau **“PTK”** untuk mengunduh data dengan format .csv berisi nama akun (*User ID*) dan akses masuk kata sandi akun (password)

4. Buka data dengan format .csv yang sudah diunduh, lalu berikan informasi akun (*User ID* dan kata sandi atau password) kepada pengguna. Pastikan informasi hanya diberikan kepada pemilik resmi akun sesuai data di Data Pokok Pendidikan (Dapodik).
    `,
                featured: false,
              },
            ],
          },
          {
            text: 'Status dan Aktivasi Akun',
            value: 'Status dan Aktivasi Akun',
            items: [
              {
                q:
                  'Bagaimana cara memeriksa ketersediaan Akun belajar.id saya?',
                a: `Anda dapat melakukan pengecekan di laman https://belajar.id/ dengan cara:
1. Periksa ketersediaan akun dengan memasukkan data-data berikut: 

    - Nama Lengkap
    - Nama Ibu Kandung
    - Tipe Pengguna 
    - Tanggal Lahir 
    - Nomor Pokok Satuan Pendidikan Nasional (NPSN)

2. Anda akan mendapatkan informasi apakah Akun belajar.id sudah tersedia atau belum.
  
    - Apabila akun sudah tersedia, Anda dapat mengirimkan detail informasi ke email pribadi Anda.
    - Apabila akun belum tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 

Selain itu, Anda juga dapat menanyakan kepada Operator Satuan Pendidikan (Operator Sekolah) di sekolah Anda untuk mengetahui status dan ketersediaan Akun belajar.id.
    `,
                featured: false,
              },
              {
                q:
                  'Akun saya sudah tersedia, bagaimana cara mendapatkan detail masuk akun (login) dan kata sandi (password)?',
                a: 'Silakan klik **"Kirim detail akun ke email pribadi saya"**, Anda akan mendapatkan informasi berupa akun (*User ID*) dan kata sandi (*Password*).',
                featured: false,
              },
              {
                q: 'Mengapa Akun belajar.id saya belum aktif?',
                a: 'Apabila status Akun belajar.id Anda belum aktif, Anda perlu melakukan aktivasi secara mandiri di gmail.com',
                featured: false,
              },
              {
                q: 'Bagaimana cara mengaktifkan Akun belajar.id?',
                a: `Setelah mendapatkan akun, Anda perlu melakukan aktivasi sesuai langkah berikut:
                
1. Buka halaman https://mail.google.com/ 
2. Masuk atau log in menggunakan nama akun (*User ID*) dan kata sandi (password) Anda
3. Setujui syarat dan ketentuan penggunaan Akun belajar.id
4. Lakukan penggantian kata sandi (password) Akun belajar.id
                `,
                featured: false,
              },
              {
                q:
                  'Mengapa Akun belajar.id saya sudah aktif padahal saya belum pernah mengaktivasi?',
                a: 'Perlu diketahui, Akun belajar.id hanya dapat diaktivasi secara mandiri oleh pemilik akun. Apabila Anda merasa tidak pernah mengaktifkan akun namun status akun telah aktif, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melakukan reset kata sandi (password)',
                featured: false,
              },
              {
                q:
                  'Bagaimana jika email belum masuk setelah klik "Kirim detail akun ke email pribadi saya"?',
                a: `1. Silakan tunggu beberapa saat sampai detail Akun belajar.id terkirim ke email pribadi Anda atau periksa folder spam
2. Apabila masih belum muncul silakan kirim ulang dengan klik kembali **"Kirim detail akun ke email pribadi saya"**
3. Apabila belum berhasil, Anda dapat menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ untuk melaporkan kendala yang dialami 
                `,
                featured: false,
              },
              {
                q:
                  'Bagaimana jika muncul keterangan "email tidak terdaftar" saat masuk akun (login) padahal saya sudah memiliki akun?',
                a: `Pastikan nama akun (*User ID*) dan kata sandi (password) yang Anda masukkan sudah benar. Apabila kendala masih dialami, silakan hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ 
                `,
                featured: false,
              },
              {
                q: 'Mengapa akun saya tidak dapat ditemukan?',
                a: `Pada saat pemeriksaan ketersediaan akun, pastikan kembali data yang Anda masukkan berikut ini sudah benar:
            
  - Nama Lengkap
  - Nama Ibu Kandung
  - Peran Pengguna 
  - Tanggal Lahir 
  - Nomor Pokok Satuan Pendidikan Nasional (NPSN)
    
Perlu diketahui, Akun belajar.id dibuat berdasarkan data yang tercantum di Data Pokok Pendidikan (Dapodik). Jika Anda sudah memastikan ke Operator Satuan Pendidikan (Operator Sekolah) bahwa data Anda sudah terdaftar di Dapodik namun status Akun belajar.id tidak tersedia, silakan menghubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/`,
                featured: false,
              },
            ],
          },
          {
            text: 'Perubahan Data Akun',
            value: 'Perubahan Data Akun',
            items: [
              {
                q:
                  'Bagaimana cara mengganti email pribadi di laman belajar.id?',
                a: `Untuk mengganti email pribadi silakan lakukan langkah-langkah berikut: 
1. Klik tombol **"untuk ganti email pribadi klik di sini"**
2. Masukkan email pribadi yang baru

Perlu diketahui, data email pribadi yang Anda masukkan hanya untuk keperluan mengirim detail Akun belajar.id, dan tidak akan mengubah data di Data Pokok Pendidikan (Dapodik).`,
                featured: false,
              },
              {
                q:
                  'Bagaimana cara mengganti email Akun belajar.id (belajar.id) saya?',
                a: 'Nama akun (*User ID*) Anda tidak dapat diganti karena sudah dirancang sesuai dengan ketentuan yang berlaku.',
                featured: false,
              },
              {
                q: 'Saya tidak ingat *User ID* Akun belajar.id ',
                a: `Berikut cara mengakses detail ketersediaan Akun belajar.id Anda:
1. Buka halaman https://belajar.id/ 
2. Periksa ketersediaan akun dengan memasukkan Nama Lengkap, Nama Ibu Kandung, Jenis Pengguna, Tanggal Lahir, dan Nomor Pokok Satuan Pendidikan Nasional (NPSN) Anda
3. Kirim detail akun ke email pribadi apabila belum pernah mendapatkan kata sandi (*Password*). 
4. Periksa akun (*User ID*) dan kata sandi (password) pada email pribadi yang Anda daftarkan.`,
                featured: false,
              },
              {
                q:
                  'Bagaimana cara Admin Sekolah melakukan reset kata sandi (password) untuk akun Pendidik atau Peserta Didik?',
                a: `1. Buka admin console di laman https://admin.google.com/ kemudian klik user atau pengguna.
2. Pilih akun user, lalu klik reset password
3. Masukan password baru di kolom yang tersedia`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kepemilikan Akun belajar.id',
            value: 'Kepemilikan Akun belajar.id',
            items: [
              {
                q:
                  'Apakah saya bisa memiliki lebih dari satu Akun belajar.id?',
                a: 'Tidak bisa.',
              },
            ],
          },
          {
            text: 'Transisi Akun belajar.id',
            value: 'Transisi Akun belajar.id',
            items: [
              {
                q:
                  'Apa yang harus dilakukan Operator Satuan Pendidikan (Operator Sekolah) jika Pendidik atau Peserta Didik mendapatkan notifikasi yang salah?',
                a: `Setelah Pendidik atau Peserta Didik melapor notifikasi yang didapatkannya salah, maka Operator Satuan Pendidikan (Operator Sekolah) harus segera melapor ke tim Unit Layanan Terpadu (ULT) agar laporan dapat segera ditindaklanjuti. 

Jika setelah sepuluh hari dari tanggal penghapusan tidak ada laporan, maka Akun belajar.id akan dihapus dari sistem dan tidak dapat dipulihkan kembali.`,
              },
            ],
          },
          {
            text: 'Akun belajar.id Tidak Aktif',
            value: 'Akun belajar.id Tidak Aktif',
            items: [
              {
                q: 'Apa penyebab Akun belajar.id menjadi tidak aktif?',
                a: `1. Status pengguna tidak lagi terdaftar di Data Pokok Pendidikan (Dapodik)
2. Pengguna melakukan pelanggaran Syarat dan Ketentuan dari Google
3. Perpindahan jenjang yang mengharuskan akun lama menjadi tidak aktif`,
              },
              {
                q: 'Mengapa akun saya di non-aktifkan?',
                a: 'Akun non-aktif bisa terjadi apabila Anda melakukan pelanggaran Syarat dan Ketentuan dari Akun belajar.id. Untuk informasi lebih lanjut silakah hubungi Unit Layanan Terpadu (ULT) di http://ult.kemdikbud.go.id/ ',
              },
            ],
          },
        ],
      },
      {
        text: 'Pemanfaatan Akun belajar.id',
        value: 'Pemanfaatan Akun belajar.id',
        children: [
          {
            text: 'Bentuk dan fungsi Akun belajar.id',
            value: 'Bentuk dan fungsi Akun belajar.id',
            items: [
              {
                q: 'Apakah penggunaan Akun belajar.id diwajibkan?',
                a: `Penggunaan Akun belajar.id bersifat opsional. Namun, Kemendikbudristek menyarankan Peserta Didik, Pendidik, dan Tenaga Kependidikan untuk menggunakan Akun belajar.id karena:
            
1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek.`,
                featured: false,
              },
              {
                q:
                  'Apakah penggunaan Akun belajar.id menjadi persyaratan untuk menerima bantuan Teknologi Informasi dan Komunikasi (TIK) dari Kemendikbudristek?',
                a: 'Penggunaan Akun belajar.id tidak menjadi persyaratan untuk menerima bantuan TIK dari Kemendikbudristek.',
                featured: false,
              },
              {
                q:
                  'Jika saya sudah memiliki akun Google pribadi, apa yang harus saya lakukan?',
                a: `Pendidik dan Peserta Didik dapat memiliki akun Google pribadi sekaligus Akun belajar.id belajar.id. Penggunaan Akun belajar.id sangat dibutuhkan karena:
1. Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemendikbudristek kepada Peserta Didik, Pendidik, dan Tenaga Kependidikan
2. Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemendikbudristek
3. Akun belajar.id digunakan sebagai kontak utama (email) dalam menyampaikan materi dan informasi dari Kemendikbudristek, misalnya terkait bantuan pemerintah dan Asesmen Nasional.`,
                featured: false,
              },
            ],
          },
          {
            text: 'Kebijakan penggunaan dan perlindungan data',
            value: 'Kebijakan penggunaan dan perlindungan data',
            items: [
              {
                q:
                  'Bagaimana perlindungan data pengguna pada Akun belajar.id?',
                a: `Perlindungan data pengguna diatur sesuai ketetapan Pusat Data dan Teknologi Informasi terhadap: 
a. kerahasiaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id; dan
b. kemungkinan terjadinya kelalaian dalam penggunaan dan/atau penyalahgunaan data, informasi, dan/atau dokumen aktivitas Akun belajar.id.`,
                featured: false,
              },
              {
                q:
                  'Apa saja ketentuan data yang boleh disimpan di Akun belajar.id?',
                a: 'Sesuai dengan syarat dan ketentuan yang sudah ditetapkan, data yang tidak berhubungan dengan kegiatan sekolah atau belajar mengajar tidah boleh disimpan di Akun belajar.id. Data aktivitas pengguna Akun belajar.id juga tidak boleh digunakan untuk kepentingan komersil.',
                featured: false,
              },
              {
                q:
                  'Bagaimana cara memindahkan data dari Akun belajar.id lama ke yang baru?',
                a: 'Untuk mencadangkan atau memindahkan data dari dan ke Akun belajar.id, Anda dapat mengikuti langkah-langkahnya pada artikel berikut https://bit.ly/bid-transfer-data',
                featured: false,
              },
              {
                q:
                  'Apakah saya bisa membagikan akses data ke email selain belajar.id?',
                a: 'Bisa. Fitur membagikan akses fail atau dokumen ke email berbeda dengan Akun belajar.id dapat dilakukan oleh Pendidik dan Tenaga Kependidikan saja.',
                featured: false,
              },
              {
                q:
                  'Apakah Tenaga Kependidikan dapat melakukan perekaman pada saat rapat?',
                a: `Ya. Tenaga Kependidikan dapat menggunakan fitur perekaman saat rapat (recording Meet) hingga 10 Januari 2022. Setelah itu, fitur recording Meet akan dinonaktifkan oleh Google Workspace of Education bagi seluruh pengguna.

Sebagai alternatif, Anda dapat menggunakan fitur perekaman rapat yang tersedia di komputer masing-masing. Silakan ikuti panduan penggunaannya pada tautan berikut: http://bit.ly/TutorialPerekamanVideo`,
                featured: false,
              },
              {
                q:
                  'Bagaimana dengan video perekaman yang telah saya rekam jika fitur perekaman saat rapat dinonatifkan?',
                a: 'Tidak perlu khawatir, video yang Anda rekam sebelum 10 Januari 2022 akan tetap tersimpan dan dapat diakses pada Drive Akun belajar.id Anda.',
                featured: false,
              },
            ],
          },
        ],
      },
    ],
  },
  // Dinas
  {
    text: 'Dinas Pendidikan',
    value: 'Dinas Pendidikan',
    children: [
      {
        text: 'Informasi Umum',
        value: 'Informasi Umum',
        children: [
          {
            text: 'Tentang Akun belajar.id',
            value: 'Tentang Akun belajar.id',
            items: [
              {
                q:
                  'Siapa saja Dinas Pendidikan yang bisa mendapatkan Akun belajar.id?',
                a: `Dinas Pendidikan yang bisa mendapatkan Akun belajar.id, meliputi: 
- Kepala Dinas Pendidikan
- Kepala Bidang pada Dinas Pendidikan
- Pengawas Sekolah
- Penilik Sekolah
- Pamong Belajar`,
                featured: false,
              },
              {
                q:
                  'Bagaimana cara mendapatkan Akun belajar.id untuk Dinas Pendidikan?',
                a: `Akun belajar.id bagi Dinas Pendidikan bisa didapatkan melalui langkah berikut:
1. Mengisi Formulir Pembuatan Akun belajar.id Dinas Pendidikan di sini
2. Formulir yang telah dikirim akan diverfikasi oleh PUSDATIN
3. Estimasi proses pembuatan akun adalah dua minggu sejak formulir diterima
4. Detail Akun belajar.id akan dikirimkan ke email yang didaftarkan pada formulir`,
                featured: false,
              },
              {
                q:
                  'Berapa lama proses pembuatan Akun belajar.id untuk Dinas Pendidikan?',
                a: 'Estimasi proses pembuatan Akun belajar.id untuk Dinas Pendidikan adalah dua minggu sejak formulir diterima.',
                featured: false,
              },
              {
                q:
                  'Mengapa Dinas Pendidikan harus menggunakan Akun belajar.id?',
                a: 'Dinas Pendidikan dapat menggunakan Akun belajar.id (belajar.id) untuk mengakses aplikasi-aplikasi yang tersedia dalam Google Workspace dan aplikasi Kemendikbudristek, salah satunya Platform Merdeka Mengajar.',
                featured: false,
              },
            ],
          },
        ],
      },
    ],
  },
]
