<script setup lang="ts">
import { ref, toRefs, watch } from 'vue'
import Button from '@/components/Button/Button.vue'
import Sheet from '@/components/Sheet/Sheet.vue'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    userTypes: { text: string; value: string }[]
  }>(),
  {
    modelValue: false,
    userTypes: () => [],
  },
)

const emit = defineEmits(['choose', 'close', 'update:modelValue'])

const { modelValue } = toRefs(props)
const isOpen = ref(modelValue.value)

watch(modelValue, (val) => {
  isOpen.value = val
})

watch(isOpen, (val) => {
  emit('update:modelValue', val)
})
</script>

<template>
  <Sheet v-model="isOpen" hide-header>
    <h3 class="text-lg font-semibold mb-2 leading-6">
      Mana jenis akun yang menunjukkan peran Anda?
    </h3>

    <div class="mt-6 space-y-3">
      <Button
        v-for="type in userTypes"
        :key="type.value"
        block
        large
        variant="secondary"
        @click="emit('choose', type.value)"
      >
        {{ type.text }}
      </Button>
    </div>
  </Sheet>
</template>
