<script setup lang="ts">
import {
  Alert,
  AlertBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  TextField,
} from '@wartek-id/belajar-id-components'
import { useVModel } from '@vueuse/core'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { verifyAccountResetPassword } from '@/services'
import { useGA } from '@/composables'

interface Props {
  modelValue?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const isOpen = useVModel(props, 'modelValue', emit)

const loading = ref(false)
const isOpenModalChooseMethod = ref(false)
const error = ref('')

const { handleSubmit, resetForm } = useForm({
  validationSchema: object({
    email: string().email().required('Wajib Diisi').label('Email'),
    dob: string().required('Wajib Diisi').label('Tanggal Lahir'),
  }),
})

watch(isOpen, () => {
  resetForm()
  error.value = ''
})

const formatDate = (dateString: string) => {
  const [date, month, year] = dateString.split('-')
  return `${year}-${month}-${date}`
}

const store = useStore()
const { trackActivity } = useGA()

const onSubmit = handleSubmit(async (values) => {
  error.value = ''
  loading.value = true

  const dob = formatDate(values.dob)

  trackActivity({
    activity_type: 'SUBMIT_FIND_ACCOUNT_RESET_PASSWORD',
    page_title: 'Belajar.id | Home Page',
    dob,
    email: values.email,
  })

  try {
    const res = await verifyAccountResetPassword({
      belajaridEmail: values.email,
      dob,
    })

    trackActivity({
      activity_type: 'ACCOUNT_FOUND_RESET_PASSWORD',
      page_title: 'Belajar.id | Home Page',
      email: res.data.email,
      email_eligible: res.data.email_eligible,
      phone: res.data.phone,
      phone_eligible: res.data.phone_eligible,
    })

    store.commit('resetPassword/setAccount', {
      lookup: {
        email: values.email,
        dob,
      },
      ...res.data,
    })
    resetForm()

    isOpenModalChooseMethod.value = true
  }
  catch (e: any) {
    error.value = e.response.data.message ?? 'Akun tidak ditemukan'

    trackActivity({
      activity_type: 'ACCOUNT_NOT_FOUND_RESET_PASSWORD',
      page_title: 'Belajar.id | Home Page',
      dob,
      email: values.email,
    })
  }
  finally {
    loading.value = false
  }
})

const onSuccess = () => {
  isOpen.value = false
}
</script>

<template>
  <Modal
    v-slot="{ close }"
    v-model="isOpen"
    class="modal-reset-password"
    :width="380"
  >
    <ModalHeader class="text-base bg-white border-none" @close="close">
      Masuk ke Akun belajar.id
    </ModalHeader>
    <ModalBody class="pt-0 pb-5">
      <h3 class="font-semibold text-[22px] mb-5">
        Reset Kata Sandi Anda
      </h3>

      <Alert v-if="error" class="mb-5" variant="critical">
        <AlertBody>
          {{ error }}
        </AlertBody>
      </Alert>

      <div v-if="loading" class="text-center">
        <Spinner variant="primary" />
      </div>

      <form v-else @submit="onSubmit">
        <TextField
          name="email"
          label="Akun belajar.id"
          placeholder="contoh: xyz@admin.belajar.id"
          wrapper-class="mb-4"
        />

        <BirthDateField
          name="dob"
          label="Tanggal Lahir"
          wrapper-class="mb-4"
          format="YYYY-MM-DD"
        />

        <Button variant="ultimate" type="submit" full-width size="lg">
          <img src="/logo-original.png" alt="Logo Belajar.id" class="w-6 h-6">
          Cari Akun belajar.id
        </Button>
      </form>
    </ModalBody>

    <!-- Modal Choose Method -->
    <ResetPasswordMethod
      v-model="isOpenModalChooseMethod"
      @success="onSuccess"
    />
  </Modal>
</template>
