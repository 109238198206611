<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SheetChooseUserType from './SheetChooseUserType.vue'
import { useAccountLookup, useGA } from '@/composables'
import Select from '@/components/select/Select.vue'
import { Icon, Button as VButton } from '@/components'

const userTypes = ref([
  {
    text: 'Pendidik dan Tenaga Kependidikan',
    value: 'ptk',
  },
  {
    text: 'Peserta Didik',
    value: 'pd',
  },
  {
    text: 'Dinas',
    value: 'dinas',
  },
])

const userType = ref()
const loading = ref(false)
const isSheetOpen = ref(false)

const router = useRouter()
const store = useStore()

const { setForm } = useAccountLookup()
const { pageView, trackActivity } = useGA()

const trackAccountType = (type: string) => {
  const params: Record<string, Record<string, any>> = {
    ptk: {
      activity_type: 'CHOOSE_ACCOUNT_TYPE',
      account_type: 'Pendidik dan Tenaga Kependidikan',
      page_title: 'Belajar.id | Home Page',
    },
    pd: {
      activity_type: 'CHOOSE_ACCOUNT_TYPE',
      account_type: 'Peserta Didik',
      page_title: 'Belajar.id | Home Page',
    },
    dinas: {
      activity_type: 'CHOOSE_ACCOUNT_TYPE',
      account_type: 'Dinas',
      page_title: 'Belajar.id | Home Page',
    },
  }

  if (!params[type])
    return

  trackActivity(params[type])
}

const onTypeChange = (e: { text: string; value: string }) => {
  trackAccountType(e.value)
}

const onChoose = (type: string) => {
  store.commit('accountLookup/setUserType', type)
  setForm({ userType: type })

  if (type === 'dinas')
    router.push('/account/dinas')
  else
    router.push('/account/lookup/v2')
}

const onSubmit = () => {
  loading.value = true

  const type = userType.value?.value

  switch (type) {
    case 'pd':
      pageView('PD lookup account', '/account/lookup/v2', {
        user_type: type,
      })
      trackActivity({
        activity_type: 'CLICK_ACCOUNT_LOOKUP',
        account_type: 'Peserta Didik',
        page_title: 'Belajar.id | Home Page',
      })
      break
    case 'ptk':
      pageView('PTK lookup account', '/account/lookup/v2', {
        user_type: type,
      })
      trackActivity({
        activity_type: 'CLICK_ACCOUNT_LOOKUP',
        account_type: 'Pendidik dan Tenaga Kependidikan',
        page_title: 'Belajar.id | Home Page',
      })
      break
    case 'dinas':
      pageView('Dinas lookup account', '/account/lookup/v2', {
        user_type: type,
      })
      trackActivity({
        activity_type: 'CLICK_ACCOUNT_LOOKUP',
        account_type: 'Dinas',
        page_title: 'Belajar.id | Home Page',
      })
      break
  }

  onChoose(type)
}
</script>

<template>
  <!-- section account lookup -->
  <section id="home" class="py-5 sm:py-20 px-4 sm:px-0">
    <div
      class="container mx-auto flex flex-col sm:flex-row items-center justify-between gap-7 sm:gap-4"
    >
      <div class="space-y-4">
        <h1 class="text-36 sm:text-6xl font-semibold leading-none">
          Akses Resmi Beragam Teknologi Pendidikan
        </h1>
        <div class="text-subdued text-xl">
          Cari tahu, pelajari, dan manfaatkan Akun belajar.id melalui website
          ini.
        </div>
      </div>
      <div>
        <div
          class="bg-expressive-dark-blue rounded px-6 py-3 w-full sm:w-[328px]"
        >
          <div
            class="text-white text-28 text-center mb-3 leading-tight font-semibold"
          >
            Cari tahu status Akun belajar.id Anda di bawah
          </div>
          <Select
            v-model="userType"
            :items="userTypes"
            placeholder="Pilih tipe pengguna"
            class="mb-3 hidden sm:block"
            outlined
            large
            @update:model-value="onTypeChange"
          />

          <button
            aria-label="Pilih tipe pengguna"
            class="px-4 py-2 rounded-lg border w-full mb-5 text-white inline-flex items-center justify-between sm:hidden text-15 mt-3"
            @click="isSheetOpen = true"
          >
            Pilih tipe pengguna
            <Icon name="select.white" :size="24" aria-hidden="true" />
          </button>

          <VButton
            variant="secondary"
            :disabled="!userType"
            block
            large
            :loading="loading"
            @click="onSubmit"
          >
            Cari Akun belajar.id
          </VButton>
        </div>
      </div>
    </div>
  </section>
  <!-- ./section account lookup -->

  <!-- modal -->
  <SheetChooseUserType
    v-model="isSheetOpen"
    :user-types="userTypes"
    @choose="onChoose"
  />
</template>
