<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { RouteLocation as Location } from 'vue-router'
import Spinner from '../Spinner.vue'

export default defineComponent({
  name: 'Btn',
  components: { Spinner },
  props: {
    variant: {
      type: String,
      validator: (v: string) =>
        ['ultimate', 'primary', 'secondary', 'quiet', ''].includes(v),
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object] as PropType<string | Location | null>,
      default: null,
    },
    normal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Loading...',
    },
    loadingClass: {
      type: String,
      default: 'text-white',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isHyperlink(): boolean {
      return this.href !== null || this.to !== null
    },
    isRouterLink(): boolean {
      return this.to !== null
    },
    tag(): string {
      if (this.to)
        return 'router-link'
      if (this.href)
        return 'a'
      return 'button'
    },
    buttonClasses(): any {
      const variantClass = `btn-${this.variant}`

      return [
        variantClass,
        'truncate',
        {
          'btn': true,
          'btn-small': this.small,
          'btn-large': this.large,
          'btn-block': this.block,
          'btn-normal': this.normal,
          'btn-loading': this.loading,
          'btn-text': this.text,
          'btn-icon': this.icon,
          'btn-dense': this.dense,
        },
      ]
    },
    dynamicAttrs() {
      const attrs: Record<string, any> = {}

      if (this.disabled) {
        attrs['aria-disabled'] = true
        attrs.disabled = true
      }

      if (this.isHyperlink) {
        attrs.target = this.newTab ? '_blank' : null
        attrs.rel = this.newTab ? 'noopener' : null
      }

      if (this.to)
        attrs.to = this.to

      if (this.href)
        attrs.href = this.href

      return attrs
    },
  },
})
</script>

<template>
  <component
    :is="tag"
    :class="buttonClasses"
    v-bind="{ ...$attrs, ...dynamicAttrs }"
  >
    <slot v-if="loading" name="loading">
      <span aria-label="Loading" class="inline-flex gap-2 items-center">
        <Spinner
          class="inline" :class="[
            loadingClass,
            small ? 'w-4 h-4' : '',
            !disabled && variant === 'secondary' ? 'text-gray-50' : '',
            !disabled && variant === 'quiet' ? 'text-blue-50' : '',
            disabled ? 'text-disabled' : '',
          ]"
        />
        <span>{{ loadingText }}</span>
      </span>
    </slot>
    <slot v-else />
  </component>
</template>

<style scoped src="@/assets/styles/components/button.css"></style>
