import type { Module } from 'vuex'
import type { RootState } from '..'
import FEATURES, { DEFAULT_FEATURES } from '@/toggles'
import type { IToggle } from '@/toggles'

export const features = {
  namespaced: true,
  state: FEATURES,
  mutations: {
    set(state, features: IToggle) {
      Object.keys((key: string) => {
        state[key] = features[key]
      })
    },
    enable(state, feature: string) {
      if (feature in state)
        state[feature] = true
    },
    disable(state, feature: string) {
      if (feature in state)
        state[feature] = false
    },
    reset(state) {
      Object.keys(DEFAULT_FEATURES).forEach((feat) => {
        state[feat] = DEFAULT_FEATURES[feat]
      })
    },
  },
} as Module<IToggle, RootState>
