<script lang="ts">
import { defineComponent } from 'vue'
import type { SwiperOptions } from 'swiper'
import VLazyImage from 'v-lazy-image'
import { SwiperSlide } from 'swiper/vue'
import type { RegistrationStep } from '@/content'
import { Button, Carousel } from '@/components'
import { gtmMixin } from '@/mixins'

export default defineComponent({
  components: {
    Button,
    Carousel,
    VLazyImage,
    SwiperSlide,
  },
  mixins: [gtmMixin],
  props: {
    hideImage: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    carouselHowToOptions: SwiperOptions
    activeStep: number
  } => ({
    carouselHowToOptions: {
      slidesPerView: 1,
      spaceBetween: 2,
      loop: true,
      autoHeight: false,
    },
    activeStep: 0,
  }),
  computed: {
    items(): RegistrationStep[] {
      return this.$getContent('app.registrationSteps.items')
    },
  },
  methods: {
    trackOnClick() {
      this.gtmClickButton('video_cara_aktivasi')
    },
  },
})
</script>

<template>
  <section id="cara-aktivasi" class="w-full">
    <div class="container mx-auto py-16 px-4 3xl:px-0">
      <ClientOnly>
        <Carousel
          v-model="activeStep"
          progress-bar
          :navigation="{
            nextEl: '.sw-button-next',
            prevEl: '.sw-button-prev',
          }"
          :pagination="{ el: '.sw-pagination', clickable: true }"
          v-bind="carouselHowToOptions as any"
        >
          <SwiperSlide
            v-for="(item, i) in items"
            :key="i"
            class="flex flex-col sm:flex-row w-full gap-10"
            :class="[hideImage ? 'justify-center' : '']"
          >
            <div v-if="!hideImage" class="w-full sm:w-6/12 space-y-2">
              <VLazyImage
                :src="item.image"
                :alt="item.title"
                class="step-image"
                v-bind="item.imgProps"
              />

              <VLazyImage
                v-if="item.image2"
                :src="item.image2"
                :alt="item.title"
                class="step-image"
                v-bind="item.imgProps"
              />
            </div>
            <div
              class="w-full sm:w-6/12"
              :class="[hideImage ? 'text-center' : '']"
            >
              <h3
                class="text-blue-600 text-2xl sm:text-42 font-semibold mb-3 sm:mb-6"
              >
                {{ item.title }}
              </h3>
              <h2
                class="text-default text-4xl sm:text-54 font-semibold leading-none mb-5 sm:mb-8"
              >
                {{ item.subtitle }}
              </h2>
              <p class="text-default text-base sm:text-lg">
                {{ item.description }}
              </p>
            </div>
          </SwiperSlide>

          <div
            class="swiper-progress-bar flex flex-col sm:flex-row items-center mb-8 gap-2"
          >
            <div class="sw-pagination" />
            <div class="flex flex-row items-center justify-center gap-4">
              <div class="sw-button-prev" />
              <div class="sw-button-next" />
            </div>
          </div>
        </Carousel>
      </ClientOnly>

      <div class="flex justify-center gap-4">
        <Button
          variant="primary"
          large
          :href="$getContent('app.registrationSteps.cta.href')"
          new-tab
          @click="trackOnClick"
        >
          {{ $getContent("app.registrationSteps.cta.text") }}
        </Button>
        <Button
          variant="secondary"
          large
          :href="$getContent('app.registrationSteps.cta.href')"
          new-tab
          @click="trackOnClick"
        >
          Pelajari Lebih Lanjut
        </Button>
      </div>
    </div>
  </section>
</template>

<style scoped>
.step-image {
  width: auto;
  max-width: 100%;
  height: 300px;
  max-height: 300px;

  @apply mx-auto object-contain border rounded-lg border-high;
}
</style>
