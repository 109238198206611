

import __layout_0 from '/builds/wartek-id/belajar-id/belajar-id-web/src/layouts/default.vue'
export const layouts = {
'blank': () => import('/builds/wartek-id/belajar-id/belajar-id-web/src/layouts/blank.vue'),
'default': __layout_0,
'eligible': () => import('/builds/wartek-id/belajar-id/belajar-id-web/src/layouts/eligible.vue'),
'redesign': () => import('/builds/wartek-id/belajar-id/belajar-id-web/src/layouts/redesign.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
