import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface LayoutState {
  isBannerOpen: boolean
}

export const layout = {
  namespaced: true,
  state: {
    isBannerOpen: false,
  },
  getters: {},
  mutations: {
    setBanner(state, val) {
      state.isBannerOpen = val
    },
    openBanner(state) {
      state.isBannerOpen = true
    },
    closeBanner(state) {
      state.isBannerOpen = false
    },
  },
  actions: {},
} as Module<LayoutState, RootState>
