import type { Identity } from '@ory/client'
import type { Module } from 'vuex'
import type { RootState } from '@/store'

export interface AuthState {
  user: Identity | null
}

export const auth = {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user: Identity) {
      state.user = user
    },
  },
} as Module<AuthState, RootState>
