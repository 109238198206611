import type { RouteLocationNormalized } from 'vue-router'
import { useOidcStore } from '@/pinia'

export const requiresAuthMiddleware = async (to: RouteLocationNormalized) => {
  const oidcStore = useOidcStore()

  if (import.meta.env.DEV)
    return true

  const [hasAccess, alreadyRedirected] = await oidcStore.checkAccess(to)
  if (!hasAccess && !alreadyRedirected)
    return '/auth/login'

  else return true
}
