export interface Product {
  name: string
  description: string
  image?: string
  url?: string
  tutorialUrl?: string
  noButton?: boolean
  imgProps?: Record<string, any>
}

export const products: Product[] = [
  {
    name: 'Panggilan Video',
    description:
      'Lakukan kelas interaktif secara daring (online) dalam bentuk video dan suara (audio) yang dapat direkam oleh guru.',
    url: 'https://meet.google.com/new',
    image: '/images/products/gmeet.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'Rapor Pendidikan',
    description: 'Lihat, refleksi, dan rencanakan peningkatan kualitas berbasis data',
    url: 'https://raporpendidikan.kemdikbud.go.id/app',
    image: '/images/products/icon-rapor-pendidikan@2x.png',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'Aplikasi Merdeka Mengajar',
    description:
      'Mempermudah guru mengajar sesuai kemampuan murid, menyediakan pelatihan untuk tingkatkan kompetensi, serta berkarya untuk menginspirasi rekan sejawat.',
    url: 'https://guru.kemdikbud.go.id/home',
    image: '/images/products/merdeka-belajar-with-text.webp',
    imgProps: {
      width: 88.19,
      height: 43,
    },
  },
  {
    name: 'Penyimpanan Daring',
    description: 'Mencadangkan file, menyimpan file, membagikan file dan mengerjakan file secara bersama-sama melalui perangkat android, tablet maupun komputer dari manapun secara online.',
    url:
      'http://drive.google.com/?utm_source=id&utm_medium=button&utm_campaign=web&utm_content=gotodrive&utm_term=carousel&usp=about_start&urp=https%3A%2F%2Fwww.google.com%2F',
    image: '/images/products/gdrive.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'Kelas Daring',
    description: 'Diskusi topik-topik dengan komunikasi secara dua arah, permudah pembagian dan pengumpulan tugas murid, lakukan penilaian dan arsip nilai, dan membuat jadwal kelas.',
    url: 'https://classroom.google.com/',
    image: '/images/products/classroom.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'Chromebook',
    description:
      'Salah satu jenis komputer baru yang dirancang khusus untuk membantu anda menyelesaikan berbagai hal dengan lebih cepat dan lebih mudah.',
    url: '',
    image: '/images/products/chromebook.webp',
    noButton: true,
    imgProps: {
      width: 66.14,
      height: 43,
    },
  },
  {
    name: 'Presentasi daring',
    description: 'Slide memberikan kemudahan untuk file presentasi yang bisa dibagikan dan dikerjakan bersama dan diakses oleh antar guru dan murid.',
    url: 'https://slides.new',
    image: '/images/products/gslides.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'Formulir Daring',
    description:
      'Layanan berbasis situs web gratis untuk membuat kuis, soal ulangan dan kuisioner atau survei.',
    url: 'https://forms.new',
    image: '/images/products/gform.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'Dokumen Daring',
    description: 'Kemudahan untuk mengerjakan file bersama (kolaborasi) dimanapun dan kapanpun secara online ataupun offline.',
    url: 'https://docs.new',
    image: '/images/products/gdocs.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'SIMPKB',
    description:
      'Aplikasi induk dalam manajemen pengembangan keprofesian dan keberlanjutan.',
    url: 'https://paspor-gtk.belajar.kemdikbud.go.id/casgpo/login',
    image: '/images/logo.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'Pengolah Angka',
    description: 'Kemudahan dalam berkolaborasi membuat tabel, perhitungan sederhana, atau pengolahan data.',
    url: 'https://sheets.new',
    image: '/images/products/gsheet.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  {
    name: 'TanyaBOS',
    description: 'TanyaBOS merupakan forum tanya jawab sekaligus wadah berbagi pengalaman dan praktik dalam pengelolaan dana BOS, yang jawabannya sudah terverifikasi dan tervalidasi oleh Kemendikbudristek.',
    url: 'https://tanyabos.kemdikbud.go.id/',
    image: '/images/logo.webp',
    imgProps: {
      width: 43,
      height: 43,
    },
  },
  // {
  //   name: 'SISTesi',
  //   description: `Software manajemen sekolah yang mampu mencatat history siswa (biodata, nilai, konseling, ekstrakulikuler, keuangan/SPP, pinjaman buku, presensi, dan lain lain) dari Siswa masuk hingga lulus atau keluar dari sekolah tersebut.`,
  //   url: 'http://sistesi.id/',
  //   image: '/images/products/FA_LogoSISTesi_Fin.png',
  //   imgProps: {
  //     width: 43,
  //     height: 43,
  //   },
  // },
  // {
  //   name: 'MISTAR',
  //   description: `Manajemen Informasi Sekolah Terintegrasi merupakan sebuah software pendidikan multiplatform berbasis cloud untuk mempermudah proses administratif dan Kegiatan Belajar Mengajar serta mengelola data Sekolah, Database Siswa, Guru, Tenaga Kependidikan, Nilai, Bimbingan Konseling, Presensi, Keuangan (SPP/Iuran), dan lain lain.`,
  //   url: 'http://mistar.co.id/',
  //   image: '/images/products/FA_LogoMISTAR_Fin.png',
  //   imgProps: {
  //     width: 67.76,
  //     height: 43,
  //   },
  // },
  {
    name: 'Rumah Belajar',
    description: 'Portal pembelajaran yang menyediakan bahan belajar serta fasilitas komunikasi yang mendukung interaksi antar komunitas. Rumah Belajar hadir sebagai bentuk inovasi pembelajaran di era industri 4.0 yang dapat dimanfaatkan oleh siswa dan guru',
    url: 'https://belajar.kemdikbud.go.id/',
    image: '/images/products/rumbel-02(tulisan hitam).png.png',
    imgProps: {
      width: 228.27,
      height: 43,
    },
  },
  {
    name: 'Canva untuk Pendidikan (Canva for Education)',
    description: 'Merupakan paket peningkatan gratis dari Canva yang khusus disediakan untuk Pendidik, Peserta Didik, dan Tenaga Kependidikan jenjang PAUD hingga SMA/SMK/Sederajat untuk mendukung kreativitas dan kolaborasi di kelas',
    url: 'https://www.canva.com/id_id/',
    image: '/images/products/canva.webp',
    imgProps: {
      width: 76.44,
      height: 43,
    },
  },
  {
    name: 'Quizizz',
    description: 'Platform berbasis gamifikasi yang dapat digunakan untuk memudahkan Pendidik dan Tenaga Kependidikan di jenjang PAUD hingga SMA/SMK dan kesetaraan dalam menyelenggarakan pembelajaran dan asesmen yang interaktif dan menyenangkan untuk Peserta Didik.',
    url: 'https://quizizz.com/',
    image: '/images/products/kuis7.webp',
    imgProps: {
      width: 300,
      height: 43,
    },
  },
]
