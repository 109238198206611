import { defineComponent } from 'vue'

export const pwaUpdateAppMixin = defineComponent({
  data() {
    return {
      registration: null,
      refreshing: false,
      snackBtnText: 'Update',
      snackWithBtnText: 'Update available',
      snackWithButtons: false,
    }
  },
  created() {
    if (!import.meta.env.SSR) {
      // Listen for swUpdated event and display refresh snackbar as required.
      document.addEventListener('swUpdated', this.forceUpdateApp, {
        once: true,
      })
      if ('serviceWorker' in navigator) {
        // Refresh all open app tabs when a new service worker is installed.
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing)
            return
          this.refreshing = true
          // window.location.reload();
        })
      }
    }
  },
  methods: {
    showRefreshUI(e: any) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail
      this.snackBtnText = 'Update'
      this.snackWithBtnText = 'New version available!'
      this.snackWithButtons = true
    },
    updateApp() {
      this.snackWithButtons = false
      // Protect against missing registration.waiting.
      if (!this.registration || !(this.registration as any)?.waiting)
        return;

      // (this.registration as any)?.waiting?.postMessage('skipWaiting');

      // this is new way
      (this.registration as any).waiting?.postMessage({ type: 'SKIP_WAITING' })

      // console.log('App updated!')
    },
    forceUpdateApp() {
      (this.registration as any).waiting?.postMessage({ type: 'SKIP_WAITING' })

      // console.log('App updated!')
    },
    skipUpdateApp() {
      this.snackWithButtons = false
    },
  },
})
