<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { useHead } from '@vueuse/head'
import { useRemoteConfig } from '@/firebase'

const LazyFooter = defineAsyncComponent(() => import('@/components/composite/Footer.vue'))

const { features, fetchOnMounted } = useRemoteConfig()

fetchOnMounted()

const hasAnnouncement = ref(false)

const canTestAnnouncementBar = computed(() => features.TEST_ANNOUNCEMENT_BAR)

if (!import.meta.env.SSR) {
  watch(canTestAnnouncementBar, (val) => {
    hasAnnouncement.value = Boolean(val)
  })

  watch(
    hasAnnouncement,
    (val) => {
      if (val)
        document.body.classList.add('has-announcement')
      else document.body.classList.remove('has-announcement')
    },
    { immediate: true },
  )
}

// head
const siteData = reactive({
  title: 'Akun belajar.id | Kemdikbudristek',
  description:
    'Akun belajar.id merupakan akun elektronik yang diterbitkan oleh Kementerian Pendidikan dan Kebudayaan.',
})

useHead({
  // Can be static or computed
  title: computed(() => siteData.title),
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description),
    },
  ],
})

const route = useRoute()
const isAccountLookupPage = computed(() => {
  return route.name === 'account-lookup'
})
</script>

<template>
  <div id="app" class="flex flex-col min-h-screen" data-app="">
    <VNavbar />

    <div class="flex-grow" :class="[isAccountLookupPage ? 'bg-blue-10' : '']">
      <router-view />
    </div>

    <LazyFooter />

    <AuthModal />

    <!-- modal outlet -->
    <div id="modal-outlet" />
    <div id="toast-outlet" />
  </div>
</template>
