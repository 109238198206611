<script setup lang="ts">
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal as VModal,
} from '@wartek-id/belajar-id-components'
import { computed, toRefs } from 'vue'
import { useAuthModal } from '@/composables'

const props = withDefaults(
  defineProps<{
    title?: string
    footerContent?: string
  }>(),
  {
    title: 'Masuk ke Akun belajar.id',
    footerContent: '',
  },
)

const { show, type, footerText, error } = useAuthModal()

const { title, footerContent } = toRefs(props)

if (footerContent.value)
  footerText.value = footerContent.value

const subTitle = computed(() => {
  if (!error.value) {
    if (type.value === 'pengajuan-akun')
      return 'Pengajuan akun untuk Peserta Didik di Sekolah Anda'

    return 'Portal Admin Website Akun belajar.id'
  }

  if (type.value === 'pengajuan-akun') {
    if (error.value.text === 'Not school admin')
      return 'Proses pengajuan akun harus menggunakan Akun belajar.id milik Admin Sekolah'
    else if (error.value.text === 'Not belajar.id account')
      return 'Gunakan Akun belajar.id untuk proses pengajuan.'
    else
      return 'Alamat email Anda belum terdaftar di database kami. Hubungi tim Helpdesk untuk bantuan lebih lanjut. '
  }

  if (error.value.text === 'Not belajar.id account')
    return 'Gunakan Akun belajar.id untuk masuk ke Portal Admin Website Akun belajar.id.'

  return 'Portal Admin Website Akun belajar.id'
})

const dynamicTitle = computed(() => {
  if (error.value && error.value.text !== 'Wrong password')
    return 'Akun salah'
  else if (error.value && error.value.text === 'Wrong password')
    return 'Salah Password'

  return title.value
})

const footerType = computed(() => typeof footerText.value)

const onUpdate = (val: boolean) => {
  if (!val)
    show.value = false
}

defineExpose({
  subTitle,
  dynamicTitle,
  footerType,
  show,
  onUpdate,
})
</script>

<template>
  <div id="auth-modal" class="auth-modal">
    <VModal
      v-slot="{ close }"
      v-model="show"
      class="tes-auth-modal"
      :width="420"
      @update:model-value="onUpdate"
    >
      <ModalHeader
        class="bg-white border-0 items-start gap-4 mb-0"
        @close="close"
      >
        <div>
          <p
            :class="{ '!text-red-50': error }"
            class="text-sm mb-4 font-medium"
          >
            {{ dynamicTitle }}
          </p>
          <p
            :class="{
              'font-normal': error && error.text !== 'Wrong password',
            }"
            class="text-lg"
          >
            {{ subTitle }}
          </p>
        </div>
      </ModalHeader>
      <ModalBody class="pt-0">
        <AuthModalForm :type="type" />
      </ModalBody>
      <ModalFooter
        v-if="footerText"
        class="bg-white font-semibold border-0 pt-0"
      >
        <span v-if="footerType === 'string'">{{ footerText }}</span>
        <div v-else>
          <Button
            class="!p-0 !text-gray-100"
            variant="quiet"
            :to="footerText.to"
          >
            {{ footerText.text }}
          </Button>
        </div>
      </ModalFooter>
    </VModal>
  </div>
</template>

<style scoped>
:deep(.modal-panel) {
  @apply !max-w-[21rem];
}
</style>
