<script lang="ts" setup>
import { ref } from 'vue'
import { Button, Icon } from '@/components'

const LazyMarkdown = defineAsyncComponent(() => import('@/components/Markdown.vue'))

interface Props {
  modelValue?: boolean
  message?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const { modelValue } = toRefs(props)
const isOpen = ref(modelValue.value)
const isMobile = ref(false)

watch(modelValue, (val) => {
  isOpen.value = val
})
watch(isOpen, (val) => {
  emit('update:modelValue', val)
})

function setIsMobile() {
  if (typeof window !== 'undefined')
    isMobile.value = window.innerWidth < 600
}

onMounted(() => {
  setIsMobile()
  window.addEventListener('resize', setIsMobile)
})

onUnmounted(() => {
  window.removeEventListener('resize', setIsMobile)
})
</script>

<template>
  <transition transition="fade">
    <div
      v-if="isOpen"
      class="sm:hidden block transition duration-300 sm:transition-none fixed inset-0 z-20 bg-black bg-opacity-50 w-full h-screen"
      @click="isOpen = false"
    />
  </transition>
  <transition :name="isMobile ? 'slide-in-up' : ''">
    <div
      v-if="isOpen"
      class="inset-x-0 fixed sm:relative z-30 bottom-0 md:bottom-auto px-4 sm:px-0"
    >
      <div
        class="bg-expressive-dark-blue px-0 sm:px-6 py-2 text-white sm:top-0 flex flex-col sm:flex-row justify-between gap-4 items-center w-full p-6 rounded-t-xl sm:rounded-none transition sm:transition-none duration-300"
      >
        <div
          class="sm:hidden flex border-b border-blue-800 w-full gap-2 px-6 pb-3 justify-between items-center py-2"
        >
          <div class="font-semibold">
            Pengumuman
          </div>
          <Button aria-label="Close" class="hidden" text icon @click="isOpen = false">
            <Icon :size="20" name="x" />
          </Button>
        </div>

        <div class="container mx-auto">
          <div
            class="text-left mb-5 sm:mb-0 sm:text-center flex-grow px-4 sm:px-6 py-4 sm:py-0"
          >
            <slot>
              <div
                class="prose prose-blue max-w-full markdown-content text-white banner-content"
              >
                <Suspense>
                  <LazyMarkdown :text="message" />
                  <template #fallback>
                    Loading...
                  </template>
                </Suspense>
              </div>
            </slot>
          </div>
        </div>

        <Button
          v-if="!isMobile"
          class="absolute right-6"
          text
          icon
          aria-label="Close"
          @click="isOpen = false"
        >
          <Icon :size="20" name="x" />
        </Button>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.banner-content a {
  @apply font-semibold;
}
</style>
