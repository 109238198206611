import { defineComponent } from 'vue'

export const activeMenuMixin = defineComponent({
  computed: {
    scrollHash: {
      get() {
        return (this.$store.state as any).scrollHash
      },
      set(val: string) {
        this.$store?.commit('setScrollHash', val)
      },
    },
    activeMenu: {
      get() {
        return (this.$store.state as any).activeMenu
      },
      set(val: number) {
        this.$store?.commit('setActiveMenu', val)
      },
    },
  },
})
