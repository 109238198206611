import { initializeApp } from 'firebase/app'
import {
  type RemoteConfig,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

export const app = initializeApp(firebaseConfig)

const defaultConfig = {
  ACCOUNT_CREATION: false,
  REQUEST_PASSWORD_BY_PHONE: false,
  TEST_ANNOUNCEMENT_BAR: false,
  LOOKUP_DINAS: false,
  ANNOUNCEMENT_MESSAGE: '',
  ANNOUNCEMENT_START_DATE: '',
  ANNOUNCEMENT_END_DATE: '',
  API_PLATFORM: false,
  API_GOVERNMENT_STATEMENT: false,
  LOGIN: false,
  API_ANNOUNCEMENT: false,
}

// keyof default config
export interface Features extends Record<string, any> {
  ACCOUNT_CREATION: boolean
  REQUEST_PASSWORD_BY_PHONE: boolean
  TEST_ANNOUNCEMENT_BAR: boolean
  LOOKUP_DINAS: boolean
  LOGIN: boolean
  API_ANNOUNCEMENT: boolean

  [key: string]: any
}

export const useRemoteConfig = () => {
  const remoteConfig = ref<RemoteConfig>()

  const loadConfig = () => {
    try {
      remoteConfig.value = getRemoteConfig(app)

      remoteConfig.value.settings.minimumFetchIntervalMillis = Number(
        import.meta.env.VITE_FIREBASE_REMOTE_CONFIG_INTERVAL || 3600000, // 60minutes
      )

      remoteConfig.value.defaultConfig = defaultConfig
    }
    catch (e: any) {
      // eslint-disable-next-line no-console
      console.log('Remote Config Init Error', e)
    }
  }

  const features = reactive<Features>(defaultConfig)

  const fetchBooleanConfig = (configKey: string) => {
    const val = getValue(remoteConfig.value!, configKey)

    features[configKey] = val.asBoolean()
  }

  const getConfig = (configKey: string) => {
    const val = getValue(remoteConfig.value!, configKey)

    return val.asBoolean()
  }

  const fetchStringConfig = (configKey: string) => {
    const val = getValue(remoteConfig.value!, configKey)

    features[configKey] = val.asString()
  }

  const isAccountCreationEnabled = computed(() => features.ACCOUNT_CREATION)

  const canRequestPasswordByPhone = computed(
    () => features.REQUEST_PASSWORD_BY_PHONE,
  )

  const fetchConfig = async () => {
    try {
      await fetchAndActivate(remoteConfig.value!)

      fetchBooleanConfig('ACCOUNT_CREATION')
      fetchBooleanConfig('REQUEST_PASSWORD_BY_PHONE')
      fetchBooleanConfig('TEST_ANNOUNCEMENT_BAR')
      fetchBooleanConfig('LOOKUP_DINAS')
      fetchBooleanConfig('API_PLATFORM')
      fetchBooleanConfig('API_GOVERNMENT_STATEMENT')
      fetchBooleanConfig('API_ANNOUNCEMENT')
      fetchStringConfig('ANNOUNCEMENT_MESSAGE')
      fetchStringConfig('ANNOUNCEMENT_START_DATE')
      fetchStringConfig('ANNOUNCEMENT_END_DATE')
      fetchStringConfig('LOGIN')
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log('Firebase config load error:', e)
    }
  }

  const fetchOnMounted = () =>
    onMounted(async () => {
      await loadConfig()
      fetchConfig()
    })

  fetchOnMounted()

  return {
    features,
    isAccountCreationEnabled,
    canRequestPasswordByPhone,
    fetchConfig,
    fetchBooleanConfig,
    getConfig,
    fetchOnMounted,
    loadConfig,
  }
}
