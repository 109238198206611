import VueGtag from 'vue-gtag'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { SetupCalendar } from 'v-calendar'
import type { App as VueApp } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { ViteSSG } from 'vite-ssg'
import axios from 'axios'
import { createPinia } from 'pinia'
import { setupLayouts } from 'virtual:generated-layouts'
import { requiresAuthMiddleware } from './middleware/auth'
// import { parseMarkdown } from './utilities'
// import { routes } from './router'
import store from './store'
import getContent from './content'
import App from './App.vue'
import '@/assets/styles/index.css'
import '@/assets/styles/custom.css'
import generatedRoutes from '~pages'

if (typeof window !== 'undefined')
  import('./firebase')

const routes = setupLayouts(generatedRoutes)

// axios
axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
axios.defaults.withCredentials = true

// static token for development purposes
if (import.meta.env.DEV) {
  axios.defaults.headers.common.Authorization
    = 'Bearer B3laJ4R!d-Sk1p@utHT0k3N'
}

const pinia = createPinia()

function registerPlugins(app: VueApp, router: any) {
  app.use(store)
  if (typeof window !== 'undefined') {
    app.use(pinia)
    app.use(SetupCalendar, {})
    app.use(VueReCaptcha, {
      siteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY,
    } as any)
    app.use(
      VueGtag,
      {
        disableScriptLoad: true,
        bootstrap: false,
        config: { id: import.meta.env.VITE_GA_ID },
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to: RouteRecordRaw) {
          return {
            page_title: (to.meta as any)?.ga?.page_title,
            page_path: to.path,
            page_location: window.location.href,
            page_referrer: document.referrer,
            campaign: '',
            medium: '',
            activity_type: 'page_view',
            session_start: '',
            first_visit: '',
            engagement_time_msec: '',
          }
        },
      } as any,
      router,
    )
  }

  app.config.globalProperties.$getContent = getContent
  // app.config.globalProperties.$parseMarkdown = parseMarkdown
}

// `export const createApp` is required instead of the original `createApp(App).mount('#app')`
export const createApp = ViteSSG(
  // the root component
  App,
  // vue-router options
  { routes },
  // function to have custom setups
  ({ app, router, isClient }) => {
    router.beforeEach(async (to) => {
      if (to.meta.middleware === 'auth')
        return requiresAuthMiddleware(to)

      return true
    })

    // install plugins etc.
    registerPlugins(app, router)

    // register sw on router ready
    // router.isReady().then(() => {
    //   registerSW({ immediate: true });
    // });

    if (isClient) {
      import('vue3-scroll-spy').then((lib) => {
        lib.registerScrollSpy(app as any)
      })
    }
  },
)
