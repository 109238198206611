import { useStore } from 'vuex'
import { computed } from 'vue'

export const useAuthModal = () => {
  const store = useStore()

  const reset = () => {
    store.commit('authModal/setShow', false)
    store.commit('authModal/setType', '')
    store.commit('authModal/setFooterText', null)
    store.commit('authModal/setErrors', null)
  }

  const show = computed({
    get: () => store.state.authModal.show,
    set: (newVal) => {
      store.commit('authModal/setShow', newVal)
    },
  })

  const type = computed({
    get: () => store.state.authModal.type,
    set: (newVal) => {
      if (newVal.value)
        store.commit('authModal/setType', newVal.value)
      else
        store.commit('authModal/setType', newVal)
    },
  })

  const headerText = computed({
    get: () => store.state.authModal.headerText,
    set: (newVal) => {
      store.commit('authModal/setHeaderText', newVal)
    },
  })

  const footerText = computed({
    get: () => store.state.authModal.footerText,
    set: (newVal) => {
      store.commit('authModal/setFooterText', newVal)
    },
  })

  const error = computed({
    get: () => store.state.authModal.errors,
    set: (newVal) => {
      store.commit('authModal/setErrors', newVal)
    },
  })

  const loading = computed({
    get: () => store.state.authModal.loading,
    set: (newVal) => {
      store.commit('authModal/setLoading', newVal)
    },
  })

  const isFailedState = computed({
    get: () => store.state.authModal.isFailedState,
    set: (newVal) => {
      store.commit('authModal/setFailedState', newVal)
    },
  })

  const showMenu = computed({
    get: () => store.state.authModal.showMenu,
    set: (newVal) => {
      store.commit('authModal/setShowMenu', newVal)
    },
  })

  return {
    show,
    type,
    reset,
    footerText,
    headerText,
    error,
    loading,
    isFailedState,
    showMenu,
  }
}
