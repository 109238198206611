<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { Autoplay, Navigation, Pagination } from 'swiper'
import type { SwiperOptions } from 'swiper'
import { Swiper } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

type CarouselVariant = 'default' | 'white'

export default defineComponent({
  name: 'Carousel',
  components: { Swiper },
  props: {
    value: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    options: {
      type: Object as PropType<SwiperOptions>,
      default: () => ({}),
    },
    variant: {
      type: String as PropType<CarouselVariant>,
      default: 'default',
    },
    btnMiddle: {
      type: Boolean,
      default: false,
    },
    gradientEffect: {
      type: Boolean,
      default: false,
    },
    progressBar: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    autoplayDelay: {
      type: Number,
      default: 5000,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    hideNext: {
      type: Boolean,
      default: false,
    },
    hidePrev: {
      type: Boolean,
      default: false,
    },
    outside: {
      type: Boolean,
      default: false,
    },
    navigation: {
      type: [Boolean, Object],
      default: false,
    },
    pagination: {
      type: [Boolean, Object],
      default: false,
    },
  },
  data: (): {
    swiper: typeof Swiper | null
    activeIndex: number
    modules: any
  } => ({
    swiper: null,
    activeIndex: 0,
    modules: [Navigation, Pagination, Autoplay],
  }),
  computed: {
    identifier(this: any): string {
      return (
        this.name ?? `carousel-${Math.floor(Math.random() * 1e6).toString(36)}`
      )
    },
    autoplayOptions(): any {
      return {
        autoplay: {
          delay: this.autoplayDelay,
          disableOnInteraction: false,
        },
        watchSlidesProgress: true,
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.activeIndex = val
        this.swiper?.slideTo(val)
      },
    },
  },
  mounted(this: any) {
    if (this.$slots.default) {
      // this.swiper = new Swiper(`#${this.identifier}`, {
      //   ...defaultOptions,
      //   ...(this.autoplay ? this.autoplayOptions : {}),
      //   ...this.options,
      // });
      // this.swiper.on("activeIndexChange", (e: any) => {
      //   this.activeIndex = e.activeIndex;
      //   this.$emit("input", e.activeIndex);
      // });

      if (this.autoplay)
        this.registerMouseEvent()
    }
  },
  methods: {
    registerMouseEvent() {
      // https://codepen.io/bjlashbrook/pen/byXzdB
      document.addEventListener(
        'mouseenter',
        (event) => {
          const el: any = event.target
          if (el && el.matches && el.matches('.swiper-container')) {
            el?.swiper?.autoplay?.stop()
            el?.classList?.add('swiper-paused')

            const activeNavItem = el?.querySelector(
              '.swiper-pagination-bullet-active',
            )
            activeNavItem.style.animationPlayState = 'paused'
          }
        },
        true,
      )

      document.addEventListener(
        'mouseleave',
        (event) => {
          const el: any = event.target
          if (el && el.matches && el.matches('.swiper-container')) {
            el?.swiper?.autoplay?.start()
            el?.classList?.remove('swiper-paused')

            const activeNavItem = el?.querySelector(
              '.swiper-pagination-bullet-active',
            )

            activeNavItem?.classList?.remove('swiper-pagination-bullet-active')

            setTimeout(() => {
              activeNavItem?.classList?.add('swiper-pagination-bullet-active')
            }, 10)
          }
        },
        true,
      )
    },
  },
})
</script>

<template>
  <div class="carousel-wrapper relative">
    <Swiper
      v-bind="$attrs"
      :navigation="
        outside
          ? {
            nextEl: '.sw-outside-button-next',
            prevEl: '.sw-outside-button-prev',
          }
          : navigation
      "
      :pagination="
        outside ? { el: '.sw-outside-pagination', clickable: true } : pagination
      "
      :modules="modules"
      class="swiper-container"
      :class="{
        'swiper-gradient-effect': gradientEffect,
        'swiper-progress-bar': progressBar,
      }"
    >
      <slot />
    </Swiper>

    <slot v-if="outside" name="outside" v-bind="$props">
      <div
        class="swiper-outside flex flex-col sm:flex-row items-center mb-8 gap-2"
        :class="[hidePagination ? '' : '']"
      >
        <div
          class="sw-outside-pagination"
          :class="[{ hidden: hidePagination }]"
        />
        <div
          class="flex flex-row items-center justify-center gap-4"
          :class="[hidePagination ? 'w-full justify-between z-5' : '']"
        >
          <div
            class="sw-outside-button-prev"
            :class="[{ hidden: hidePrev }]"
          />
          <div
            class="sw-outside-button-next"
            :class="[{ hidden: hideNext }]"
          />
        </div>
      </div>
    </slot>
  </div>
</template>

<style src="@/assets/styles/components/carousel.css"></style>
