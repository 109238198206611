<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { Icon } from './'

export default defineComponent({
  components: {
    Icon,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Date, Object] as PropType<string | number | any>,
      default: '',
    },
    modelValue: {
      type: [String, Number, Date, Object] as PropType<string | number | any>,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 20,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    appendIconProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    appendIconBtnProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    prependIconProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    prependIconBtnProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    appendIconFill: {
      type: String,
      default: 'none',
    },
    prependIconFill: {
      type: String,
      default: 'none',
    },
  },
  emits: [
    'update:modelValue',
    'blur',
    'enter',
    'append:click',
    'prepend:click',
    'clear',
  ],
  data() {
    return {
      innerValue: '',
      isSecured: false,
    }
  },
  computed: {
    inputType(): string {
      const type = this.type === 'password' ? 'text' : this.type
      return this.isSecured ? 'password' : type
    },
    isPassword(): boolean {
      return this.type === 'password'
    },
    appendedIcon(): string {
      if (this.isPassword)
        return this.isSecured ? 'eye.close' : 'eye.open'

      return this.appendIcon
    },
    hasButtons(): boolean {
      return (
        !!this.$slots.append
        || !!this.$slots.prepend
        || !!this.prependIcon
        || !!this.appendIcon
      )
    },
  },
  watch: {
    innerValue(val: any) {
      this.$emit('update:modelValue', val)
    },
    modelValue: {
      immediate: true,
      handler(val: any) {
        this.innerValue = val || this.value
      },
    },
    value: {
      immediate: true,
      handler(val: any) {
        this.innerValue = val || this.modelValue
      },
    },
    type: {
      immediate: true,
      handler(val: string) {
        if (val === 'password')
          this.isSecured = true
      },
    },
  },
  methods: {
    onAppendClick() {
      this.$emit('append:click')

      if (this.isPassword)
        this.isSecured = !this.isSecured
    },
    onPrependClick() {
      this.$emit('prepend:click')
    },
    focus() {
      if (this.$refs.inputRef)
        (this.$refs.inputRef as HTMLInputElement)?.focus()
    },
    clear() {
      this.innerValue = ''
      this.$nextTick(this.focus)
      this.$emit('clear')
    },
  },
})
</script>

<template>
  <div
    class="flex input-wrapper items-center gap-2 h-12 transition duration-300 rounded border w-full"
    :class="[
      disabled ? 'text-field-disabled' : '',
      error
        ? 'border-error bg-surface-critical-subdued  focus-within:ring-error focus-within:border-error'
        : 'border-gray-50  bg-white',
    ]"
  >
    <slot name="prepend">
      <button
        v-if="prependIcon"
        type="button"
        aria-label="Prepended Icon"
        class="h-full text-gray-60 hover:text-gray-80 focus:text-gray-80 active:text-gray-50 flex items-center justify-center pl-4 focus:outline-none"
        :disabled="disabled"
        v-bind="prependIconBtnProps"
        @click="onPrependClick"
      >
        <Icon
          :name="prependIcon"
          :size="iconSize"
          :fill="prependIconFill"
          v-bind="prependIconProps"
        />
      </button>
    </slot>
    <input
      ref="inputRef"
      v-model="innerValue"
      class="text-field placeholder:text-gray-45 py-2.5 text-sm h-12 bg-transparent w-full focus-within:outline-none focus:ring-0 focus:border-transparent focus:border-none border-none border-transparent"
      :class="[hasButtons ? '' : 'px-4 rounded-lg']"
      :type="inputType"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      v-bind="$attrs"
      @blur="$emit('blur')"
      @keydown="$emit('enter')"
    >
    <slot name="clearable" :clearable="clearable" :clear="clear">
      <button
        v-if="clearable && innerValue"
        type="button"
        aria-label="Clear"
        class="h-full text-gray-60 hover:text-gray-80 focus:text-gray-80 active:text-gray-50 flex items-center justify-center px-4 focus:outline-none focus:ring"
        @click="clear"
      >
        <Icon name="x" :size="24" />
      </button>
    </slot>
    <slot name="append" :append-click="onAppendClick">
      <button
        v-if="appendIcon || isPassword"
        type="button"
        aria-label="Appended Icon"
        class="h-full text-gray-60 hover:text-gray-80 focus:text-gray-80 active:text-gray-50 flex items-center justify-center px-4 focus:outline-none"
        :disabled="disabled"
        v-bind="appendIconBtnProps"
        @click="onAppendClick"
      >
        <Icon
          :name="appendedIcon"
          :size="iconSize"
          :fill="appendIconFill"
          v-bind="appendIconProps"
        />
      </button>
    </slot>
  </div>
</template>

<style scoped>
input.text-field::-webkit-outer-spin-button,
input.text-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].text-field {
  -moz-appearance: textfield;
}
.text-field-disabled {
  @apply bg-surface-disabled text-disabled;
}
</style>
