interface BulkRequestState {
  selectedItems: Array<string | number>
}

export const bulkRequest = {
  namespaced: true,

  state: (): BulkRequestState => ({
    selectedItems: [],
  }),

  mutations: {
    setSelectedItems(state: BulkRequestState, payload: any) {
      state.selectedItems = payload
    },
  },

  getters: {
    selectedItems: (state: BulkRequestState) => state.selectedItems,
  },
}
