import { defineComponent } from 'vue'

export const gtmMixin = defineComponent({
  methods: {
    gtmPush(event: string, params = {}) {
      (this as any).$gtag.event(event, {
        event_category: '',
        event_action: '',
        event_value: '',
        source: 'web',
        campaign: 'ga-event',
        medium: 'true',
        page_title: '',
        page_location: window.location.href,
        page_referrer: document.referrer,
        ga_session_number: '',
        ga_session_id: '',
        link_url: window.location.href,
        link_domain: '',
        link_classes: '',
        outbound: '',
        link_name: '',
        ...params,
      })
    },
    gtmClick(params = {}) {
      this.gtmPush('BELAJAR_ID_ACTIVITY', {
        event_category: 'activity',
        event_action: 'click',
        activity_type: 'activity',
        action: 'click',
        ...params,
      })
    },
    gtmClickLink(linkName: string, params = {}) {
      this.gtmClick({
        event_label: 'click_link',
        event_value: linkName,
        activity_type: 'click_link',
        link_name: linkName,
        ...params,
      })
    },
    gtmClickButton(linkName: string, params = {}) {
      this.gtmClick({
        event_label: 'click_button',
        event_value: linkName,
        activity_type: 'click_button',
        link_name: linkName,
        ...params,
      })
    },
  },
})
