import type { ToastOptions } from '@/store/modules/toast'

export const useToast = () => {
  const store = useStore()
  const isOpen = computed({
    get() {
      return store.state.toast.isOpen
    },
    set(val) {
      store.commit('toast/setIsOpen', val)
    },
  })

  const show = (payload?: ToastOptions) => {
    store.commit('toast/show', payload)
  }

  const hide = () => {
    store.commit('toast/hide')
  }

  const options = computed(() => {
    return store.state.toast.options
  })

  const message = computed(() => {
    return store.state.toast.message
  })

  return { isOpen, show, hide, message, options }
}
