import axios, { type AxiosResponse } from 'axios'
import type { AccountTypes } from '@/store/modules/accountLookup'

export interface AccountLookupBody {
  nama: string
  npsn: string
  nama_ibu?: string
  tempat_lahir?: string
  tanggal_lahir: string
  recaptcha_token: string
  name?: string
  birthDate?: string
  userType?: AccountTypes
}

export interface AccountLookupUser {
  username: string
  email: string
  school_type: string
  status: 'NOT_ACTIVATED' | 'ACTIVATED'
}

export interface AccountLookupData {
  accounts: AccountLookupUser[]
  personal_email: string
  token: string
}

export interface AccountLookupResponse {
  data: AccountLookupData
}

export type AccountLookupType = 'student' | 'teacher'

export type AccountType = 'pd' | 'ptk'

export interface RequestPasswordBody {
  email: string
  token?: string
  type: AccountType
}

export const lookupAccount = <R extends AccountLookupResponse>(
  type: AccountType,
  body: AccountLookupBody,
): Promise<AxiosResponse<R>> => {
  return axios.post<R>(`belajar-id/account-lookup/v1/lookup/${type}`, body)
}

export type RequestPasswordMethod = 'email' | 'phone'

export const requestPassword = (
  body: RequestPasswordBody,
  method: RequestPasswordMethod = 'email',
): Promise<AxiosResponse<{ data: boolean }>> => {
  return axios.post(
    'belajar-id/account-lookup/v1/lookup/request-password',
    body,
    {
      params: { method },
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    },
  )
}

export const changePrivateEmail = (
  token: string,
  email: string,
): Promise<AxiosResponse<{ data: boolean }>> => {
  return axios.post(
    'belajar-id/account-lookup/v1/lookup/change-email',
    {
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export interface AccountCreationPayload {
  full_name: string
  npsn: string
  birth_date: string
  email?: string
  phone?: string
  mother_name?: string
}

export const requestAccountCreation = (
  type: AccountType | AccountTypes,
  payload: AccountCreationPayload,
): Promise<AxiosResponse<any>> => {
  return axios.post(
    `belajar-id/account-lookup/v1/lookup/${type}/request-account-creation`,
    payload,
  )
}

/**
 * Check NPSN.
 *
 * @param npsn string
 */
export const checkNpsn = <T = any>(npsn: string): Promise<AxiosResponse<T>> => {
  return axios.get('/belajar-id/account-lookup/v1/lookup/sekolah', {
    params: { npsn },
  })
}

export interface SekolahData {
  id: string
  npsn: string
  name: string
  city: string
  type: string
}
export interface SearchSekolahResponse {
  data: SekolahData[]
  meta: any
}

/**
 * List Sekolah by name search.
 *
 * @param npsn string
 */

export const searchSekolahByName = <R extends SearchSekolahResponse>(searchQuery: string): Promise<AxiosResponse<R>> => {
  return axios.get<R>('/belajar-id/account-lookup/v1/lookup/sekolah/list', {
    params: { name: searchQuery },
  })
}
