import { createStore } from 'vuex'
import * as modules from './modules'

export interface RootState {
  activeMenu: number
  scrollHash: string
  isModalLogoutOpen: boolean

  [x: string]: any
}

export const store = createStore({
  modules,
  state: {
    activeMenu: -1,
    scrollHash: '#',
    isModalLogoutOpen: false,
  },
  mutations: {
    setActiveMenu(state, index: number) {
      state.activeMenu = index
    },
    setScrollHash(state, hash: string) {
      state.scrollHash = hash
    },
    setIsModalLogoutOpen(state, val) {
      state.isModalLogoutOpen = val
    },
  },
})

export default store
