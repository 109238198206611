import { defineAsyncComponent } from 'vue'
// import Button from './Button.vue';
// import TextField from './TextField.vue';
// import FileInput from './FileInput.vue';

export const Icon = defineAsyncComponent(() => import('./Icon/index.vue'))
export const TextField = defineAsyncComponent(() => import('./TextField.vue'))
// export const FileInput = defineAsyncComponent(() => import('./FileInput.vue'));
export const SelectField = defineAsyncComponent(
  () => import('./SelectField.vue'),
)
export const Button = defineAsyncComponent(() => import('./Button/Button.vue'))
export const Hyperlink = defineAsyncComponent(
  () => import('./Hyperlink/Hyperlink.vue'),
)
export const ValidationError = defineAsyncComponent(
  () => import('./ValidationError.vue'),
)
export const Alert = defineAsyncComponent(() => import('./Alert/Alert.vue'))
export const Modal = defineAsyncComponent(() => import('./Modal/Modal.vue'))
export const Tabs = defineAsyncComponent(() => import('./Tabs/Tabs.vue'))
export const Tab = defineAsyncComponent(() => import('./Tabs/Tab.vue'))
export const Card = defineAsyncComponent(
  () => import('./composite/Card/Card.vue'),
)
export const FaqList = defineAsyncComponent(
  () => import('./composite/FaqList.vue'),
)
export const Carousel = defineAsyncComponent(
  () => import('./Carousel/Carousel.vue'),
)
export const ProgressBar = defineAsyncComponent(
  () => import('./ProgressBar/ProgressBar.vue'),
)
export const Collapsible = defineAsyncComponent(
  () => import('./Collapsible.vue'),
)
export const Accordion = defineAsyncComponent(
  () => import('./composite/Accordion.vue'),
)
export const Banner = defineAsyncComponent(() => import('./Banner/Banner.vue'))

export const Navbar = defineAsyncComponent(
  () => import('./composite/Navbar.vue'),
)
export const Footer = defineAsyncComponent(
  () => import('./composite/Footer.vue'),
)

// export { FileInput };
// export { Button, TextField };
