<script setup lang="ts">
import { Form } from 'vee-validate'
import { Button } from '@wartek-id/belajar-id-components'
import { useAuthModal } from '@/composables'
import { useOidcStore } from '@/pinia'

interface Props {
  errorCode?: any
  loading?: boolean
  type?: 'pengajuan-akun' | 'portal-admin'
}

withDefaults(defineProps<Props>(), {
  errorCode: null,
  loading: false,
})

const oidcStore = useOidcStore()
const { type, error, isFailedState, loading, footerText } = useAuthModal()

const form = reactive({
  email_belajar_id: '',
  password: '',
})

const onSubmit = (values: any, actions: any) => {
  if (type.value === 'pengajuan-akun') {
    const returnURL = `${import.meta.env.VITE_WEB_BASE_URL}/eligible/peserta-didik`
    oidcStore.signInRedirect({ redirectPath: returnURL, ref: type.value })
  }
  else {
    window.location.href = import.meta.env.VITE_ADMIN_BASE_URL
  }
}
</script>

<template>
  <Transition name="fade">
    <Form v-if="!loading && !error" @submit="onSubmit">
      <Button
        type="submit"
        class="gap-x-5 w-full"
        full-width
        variant="ultimate"
      >
        <template #prefix>
          <img width="24" src="/images/logo-120.webp" alt="Logo Kemendikbud">
        </template>
        <span class="text-xs"> Masuk dengan Akun belajar.id </span>
      </Button>
    </Form>
    <div v-else-if="!loading && error.text">
      <div v-if="type === 'pengajuan-akun'">
        <PengajuanAkunNotSchoolAdmin
          v-if="error.text === 'Not school admin'"
          :form="form"
        />
        <PengajuanAkunNotBelajarIdAccount
          v-else-if="
            error.text === 'Not belajar.id account' && type === 'pengajuan-akun'
          "
          :form="form"
        />
      </div>
      <div v-else>
        <PortalAdminNotBelajarIdAccount
          v-if="
            error.text === 'Not belajar.id account' && type === 'portal-admin'
          "
          :form="form"
        />
      </div>
    </div>
    <Loading v-else />
  </Transition>
</template>
