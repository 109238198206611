<script lang="ts">
import { defineComponent } from 'vue'
import type { TabItem } from '@/components/Tabs'

export default defineComponent({
  emits: ['update:activeTab'],
  data: (): {
    activeTab: number
    tabs: TabItem[]
  } => ({
    activeTab: 0,
    tabs: [
      {
        text: 'Apa Itu Belajar.id',
      },
      {
        text: 'Government\'s Acknowledgement',
      },
      {
        text: 'Cara Aktivasi',
      },
      {
        text: 'Painpoints',
      },
      {
        text: 'Materi Belajar',
      },
      {
        text: 'Belajar.id vs. Gmail Biasa',
      },
    ],
  }),
  watch: {
    activeTab(val) {
      this.$emit('update:activeTab', val)
    },
  },
})
</script>

<template>
  <section id="about" class="w-full bg-white">
    <div class="bg-expressive-dark-blue px-4">
      <div
        class="flex flex-col sm:flex-row mt-px gap-4 items-center justify-between container mx-auto py-5 sm:py-20"
      >
        <div class="w-full sm:w-8/12 order-2 sm:order-1">
          <h1
            class="text-3xl sm:text-5xl xl:text-6xl font-semibold mb-4 sm:mb-10 text-white"
          >
            {{ $getContent("about.title") }}
          </h1>
          <p class="text-lg sm:text-xl text-inverse-subdued mb-5">
            {{ $getContent("about.description.0") }}
          </p>

          <p class="text-lg sm:text-xl text-inverse-subdued mb-2">
            {{ $getContent("about.description.1") }}
          </p>
        </div>
        <div class="w-full sm:w-4/12 order-1 sm:order-2">
          <img
            src="/images/belajar-id-illustration.webp"
            src-placeholder="/images/belajar-id-illustration-placeholder.webp"
            alt="ilustrasi guru mengajar di kelas"
            class="max-w-full"
            width="336"
            height="251"
            fetchpriority="high"
          >
        </div>
      </div>
    </div>
  </section>
</template>
