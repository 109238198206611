<script setup lang="ts">
import { useAnnouncementBar, useLayout } from '@/composables'
import { useRemoteConfig } from '@/firebase'

const LazyMarkdown = defineAsyncComponent(() => import('@/components/Markdown.vue'))

const { features, fetchOnMounted } = useRemoteConfig()

fetchOnMounted()

const { hasAnnouncement } = useLayout()
const { items, fetchItems } = useAnnouncementBar()

const canTestAnnouncementBar = computed(() => features.TEST_ANNOUNCEMENT_BAR)

const useAnnouncementAPI = computed(() => features.API_ANNOUNCEMENT)

const messages = ref([
  {
    description: `
<p class="mb-2.5">Saat ini sedang dilakukan Pemutakhiran Data pada sistem Belajar.id. Segera
cek akun email Belajar.id Anda, dan ikuti langkah-langkah yang diminta.
Detail informasi ada di <a class="font-semibold" href="https://bit.ly/PemutakhiranDataBelajarid" target="_blank" rel="noopener noreferer">https://bit.ly/PemutakhiranDataBelajarid</a></p>

Permintaan Pembuatan Akun belajar.id (Akun belajar.id |
Kemdikbudristek ) untuk Pengawas Sekolah dan Penilik Sekolah Dapat
dilakukan Melalui &raquo; <a class="font-semibold" href="https://ringkas.kemdikbud.go.id/permohonanakundinas" target="_blank" rel="noopener noreferer">https://ringkas.kemdikbud.go.id/permohonanakundinas</a>
  `,
    is_active: false,
  },
  {
    description: `<p class="mb-1">Terdapat peningkatan kapasitas laman Akun belajar.id pada 6 September 2022 mulai pukul 13.00 - 18.00 WIB.</p>
    
Bapak/Ibu masih bisa melakukan **pencarian** dan **pengecekan Akun belajar.id**, serta **melapor** ke tombol **Butuh Bantuan**
  `,
    is_active: false,
  },
  {
    description: `<p class="mb-2 font-semibold">Cari tahu soal RUU Sisdiknas dari sumber resmi!</p>

<p class="mb-2">Tonton lebih lanjut penjelasan Mendikbudristek tentang dampak RUU Sisdiknas untuk kesejahteraan guru, dalam tautan berikut: <a class="font-semibold" target="_blank" rel="noopener noreferer" href="https://bit.ly/VideoPenjelasanRUUSisdiknas">https://bit.ly/VideoPenjelasanRUUSisdiknas</a>.</p>

<p>Atau cari tahu lebih lagi tentang RUU Sisdiknas di <a class="font-semibold" target="_blank" rel="noopener noreferer" href="https://sisdiknas.kemdikbud.go.id/">https://sisdiknas.kemdikbud.go.id/</a></p>
  `,
    is_active: false,
  },
  {
    description: '',
    is_active: false,
  },
  {
    description:
      '<p>Pembuatan Akun belajar.id untuk Pengawas Sekolah dan Penilik Sekolah bisa melalui link berikut: <a class="font-semibold" target="_blank" rel="noopener noreferer" href="https://ringkas.kemdikbud.go.id/permohonanakundinas">https://ringkas.kemdikbud.go.id/permohonanakundinas</a>.</p>',
    is_active: false,
  },
  {
    description:
      'Tombol \'Butuh Bantuan\' sudah tidak tersedia di laman belajar.id dan **HANYA** dapat diakses melalui Pusat Informasi Akun belajar.id di Jam Operasional Senin-Jumat, 09.00-17.00 WIB',
    is_active: false,
  },
  {
    description: `<b>Informasi Pemeliharaan Sistem Google Admin Console</b> <br />
    Google Admin Console khusus jenjang PAUD dan SD saat ini tidak dapat diakses
     karena pemeliharaan sistem. Silakan gunakan Portal Admin untuk mengelola Akun belajar.id,
      reset password, dan aktivitas lainnya di sekolah Anda.`,
    is_active: false,
  },
  {
    description:
      'Pada 14-15 Maret 2023, sedang ada pemutakhiran beberapa fitur di website http://belajar.id  yang memungkinkan adanya kendala saat mengakses fitur tersebut. Silakan melakukan pengecekan secara berkala',
    is_active: false,
  },
  {
    description: `**Tidak Ada Operasional Helpdesk selama Idulfitri**
    
Tombol 'Butuh Bantuan' dapat digunakan selama Cuti Bersama Idulfitri 19 - 25 April 2023. Laporan Anda akan ditanggapi kembali oleh tim Helpdesk mulai 26 April 2023.`,
    is_active: false,
  },
])

const activeMessage = computed(() =>
  items.value.find(item => item.is_active) ?? messages.value.find(item => item.is_active),
)

if (!import.meta.env.SSR) {
  watch(canTestAnnouncementBar, (val) => {
    setTimeout(() => {
      hasAnnouncement.value = Boolean(val) && !!activeMessage.value?.description
    }, 2000)
  })

  watch(
    hasAnnouncement,
    (val) => {
      if (val)
        document.body.classList.add('has-announcement')
      else document.body.classList.remove('has-announcement')
    },
    { immediate: true },
  )
}

watchEffect(() => {
  if (useAnnouncementAPI.value)
    fetchItems()
})
</script>

<template>
  <Banner v-model="hasAnnouncement">
    <article
      class="banner-content"
    >
      <Suspense>
        <LazyMarkdown :text="activeMessage?.description" />
        <template #fallback>
          Loading...
        </template>
      </Suspense>
    </article>
    <!--
      v-html="$parseMarkdown(String(activeMessage?.content))"
     -->
  </Banner>
</template>

<style scoped>
.banner-content {
  @apply py-1;
}
</style>
