import type { AxiosResponse } from 'axios'
import axios from 'axios'

export type DinasReponse = Record<string, any>
export interface Province { id: string; name: string }

export interface GetCityParams {
  province_id: string
  dinas_area_id?: string
}

export const getDinasCity = (params: GetCityParams) => {
  return axios.get<{ data: Province[]; meta: ResponseMeta }>(
    'belajar-id/account-lookup/v1/lookup/dinas/city',
    {
      params,
    },
  )
}

export interface ResponseMeta { limit: number; page: number; total: number }

export interface PositionRaw { id: string; name: string }

export interface PlatformDinas { id: string; platform_name: string }

export interface GetPositionParams {
  search?: string
  dinas_area_id?: string
}

export const getDinasJabatan = (params?: GetPositionParams) => {
  return axios.get<{
    data: PositionRaw[]
    meta: ResponseMeta
  }>('belajar-id/account-lookup/v1/lookup/dinas/jabatan', {
    params,
  })
}

export const getPlatformDinas = () => {
  return axios.get<{
    data: PlatformDinas[]
    meta: ResponseMeta
  }>('belajar-id/account-lookup/v1/lookup/dinas/platform/list')
}

export interface ReferralCodeParams {
  referral_code: string
  dinas_type: string
  dinas_area_id: string
  email: string
}

export const postDinasReferralCode = (
  params: ReferralCodeParams,
): Promise<AxiosResponse<DinasReponse>> => {
  return axios.post<DinasReponse>(
    'belajar-id/account-lookup/v1/lookup/dinas/referral-code',
    params,
  )
}

export const createDinasAccount = (
  body: Record<string, any>,
): Promise<AxiosResponse<DinasReponse>> => {
  return axios.post<DinasReponse>(
    'belajar-id/account-lookup/v1/lookup/dinas/account',
    body,
  )
}

export interface FileDinasRaw {
  id: string
  size: number
  category: string
  url: string
}

export interface UploadFileParams {
  file: File
  category: string
  creator?: string
}

export const uploadFile = (body: FormData) => {
  return axios.post<DinasReponse>('belajar-id/file/upload/private', body, {
    withCredentials: false,
  })
}
