import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface ResetPasswordAccount {
  email: string
  email_eligible: boolean
  phone: string
  phone_eligible: boolean
  token: string
  lookup?: {
    email: string
    dob: string
  }
}

export interface ResetPasswordState {
  isOpen: boolean
  account: ResetPasswordAccount | null
  sessionId: string
  otpToken: string
}

export const resetPassword = {
  namespaced: true,
  state: {
    isOpen: false,
    account: null as any,
    sessionId: '',
    otpToken: '',
  },
  getters: {
    account: s => s.account,
  },
  mutations: {
    setModal(state, val) {
      state.isOpen = val
    },
    openModal(state) {
      state.isOpen = true
    },
    closeModal(state) {
      state.isOpen = false
    },
    setAccount(state, val) {
      state.account = val
    },
    setSessionId(state, val) {
      state.sessionId = val
    },
    setOtpToken(state, val) {
      state.otpToken = val
    },
    reset(state) {
      state.account = null
      state.sessionId = ''
    },
  },
  actions: {},
} as Module<ResetPasswordState, RootState>
