import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface DinasForm {
  name: string
  nik: string
  email: string
  phone: string
  birthDate: string
  province: string
  city: string
  dinasType: string
  position: string
  referralCode: string
  suratDinas: string
  nomorSuratDinas: string
  platform: any
  document: File[] | FileList

  [x: string]: any
}

export interface DinasState {
  form: DinasForm
  error: string
  errorDetail: string
  files: File[] | FileList
}

export const dinas = {
  namespaced: true,
  state: {
    form: {
      name: '',
      nik: '',
      email: '',
      phone: '',
      birthDate: '',
      province: '',
      city: '',
      dinasType: '',
      position: '',
      referralCode: '',
      suratDinas: '',
      nomorSuratDinas: '',
      platform: [],
      document: [],
    },
    error: '',
    errorDetail: '',
    files: [], // document
  },
  getters: {},
  mutations: {
    setError(state, error) {
      state.error = error
    },
    setErrorDetail(state, errorDetail) {
      state.errorDetail = errorDetail
    },
    setFiles(state, files) {
      state.files = files
    },
    setForm(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.form[key] = payload[key]
      })
    },
    setFormValue(state, { key, value }) {
      state.form[key] = value
    },
    reset(state) {
      state.form = {
        name: '',
        nik: '',
        email: '',
        phone: '',
        birthDate: '',
        province: '',
        city: '',
        dinasType: '',
        position: '',
        referralCode: '',
        suratDinas: '',
        nomorSuratDinas: '',
        platform: [],
        document: [],
      }
      state.error = ''
      state.files = []
    },
    clearError(state) {
      state.error = ''
      state.errorDetail = ''
    },
  },
  actions: {},
} as Module<DinasState, RootState>
