<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { Button, Toast } from '@wartek-id/belajar-id-components'

const isOpen = ref(false)

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegistered(r: any) {
    setInterval(async () => {
      // eslint-disable-next-line no-console
      console.log('Checking for sw update')
      await r.update()
    }, 20000 /* 20s for testing purposes */)
    // eslint-disable-next-line no-console
    console.log(`SW Registered: ${r}`)
  },
})

const close = async () => {
  offlineReady.value = false
  needRefresh.value = false
  isOpen.value = false
}

watch([offlineReady, needRefresh], ([isOfflineReady, isNeedRefresh]) => {
  isOpen.value = isOfflineReady || isNeedRefresh
})
</script>

<template>
  <Toast v-model="isOpen" class="pwa-toast">
    <template #action>
      <Button v-if="needRefresh" variant="quiet" @click="updateServiceWorker()">
        Muat Ulang
      </Button>
      <Button variant="quiet" @click="close">
        Tutup
      </Button>
    </template>
    <template #default>
      <span v-if="offlineReady">
        Anda bisa mengakses tampilan ini tanpa koneksi internet (secara offline)
      </span>
      <span v-else>
        Muat ulang (refresh) untuk melihat pembaruan fitur / layanan di laman
        ini
      </span>
    </template>
  </Toast>
</template>

<style>
.pwa-toast {
  @apply z-50 bottom-14 sm:bottom-0;
}

.pwa-toast .toast-panel {
  @apply sm:min-w-[500px] flex-col sm:flex-row;
}

.pwa-toast .toast-action {
  @apply w-full sm:w-auto;
}
</style>
