import { event } from 'vue-gtag'
import { useRoute } from 'vue-router'
import type { AccountLookupResult } from '@/pages/account/lookup/__partials/types'
import { getAccountTypeLabel } from '@/store/modules/accountLookup'

export function useGA() {
  const store = useStore()
  const form = store.state.accountLookup.form
  const accountResult = store.state.accountLookup.result as AccountLookupResult

  function push(name: string, params = {}) {
    event(name, {
      event_category: '',
      event_action: '',
      event_value: '',
      source: 'web',
      campaign: 'ga-event',
      medium: 'true',
      page_title: '',
      page_location: window.location.href,
      page_referrer: document.referrer,
      ga_session_number: '',
      ga_session_id: '',
      link_url: window.location.href,
      link_domain: '',
      link_classes: '',
      outbound: '',
      link_name: '',
      ...params,
    })
  }

  const route = useRoute()

  function pageView(title: string, path?: string, params = {}) {
    push('page_view', {
      page_title: title,
      page_path: path || route.path,
      page_location: window.location.href,
      ...params,
    })
  }

  function click(params = {}) {
    push('BELAJAR_ID_ACTIVITY', {
      event_category: 'activity',
      event_action: 'click',
      activity_type: 'activity',
      action: 'click',
      ...params,
    })
  }

  function clickLink(linkName: string, params = {}) {
    click({
      event_label: 'click_link',
      event_value: linkName,
      activity_type: 'click_link',
      link_name: linkName,
      ...params,
    })
  }

  function clickButton(linkName: string, params = {}) {
    click({
      event_label: 'click_button',
      event_value: linkName,
      activity_type: 'click_button',
      link_name: linkName,
      ...params,
    })
  }

  function trackActivity(params = {}) {
    push('BELAJAR_ID_ACTIVITY', params)
  }

  function trackAccountLookup(params = {}, { withUsername = false } = {}) {
    push('BELAJAR_ID_ACTIVITY', {
      account_type: getAccountTypeLabel(),
      npsn: form.npsn,
      full_name: form.name,
      dob: form.birthDate,
      page_title: 'Belajar.id | Account Lookup',
      ...(withUsername && { username: accountResult?.accounts?.[0]?.email }),
      ...params,
    })
  }

  function trackAccountDinas(params = {}) {
    push('BELAJAR_ID_ACTIVITY', {
      account_type: 'Dinas',
      page_title: 'Belajar.id | Account Lookup',
      ...params,
    })
  }

  return { push, click, clickLink, clickButton, pageView, trackActivity, trackAccountLookup, trackAccountDinas }
}
