interface Item {
  [key: string]: any
}

export const sortBy = <T extends Item>(key: keyof T) => {
  return (a: T, b: T) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0)
}

type Func<T extends any[], R> = (...args: T) => R

export function debounce<T extends any[], R>(
  func: Func<T, R>,
  wait: number,
  immediate = false,
): Func<T, void> {
  let timeout: any
  return function (this: unknown, ...args: T) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      if (!immediate)
        func.apply(context, args)
    }, wait)
    if (immediate && !timeout)
      func.apply(context, args)
  }
}

interface Obj { [key: string]: any }

export function deepMerge(target: Obj, ...sources: Obj[]): Obj {
  if (!sources.length)
    return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, { [key]: {} })
        deepMerge(target[key], source[key])
      }
      else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return deepMerge(target, ...sources)
}

export function isObject(item: any): boolean {
  return (item && typeof item === 'object' && !Array.isArray(item))
}
