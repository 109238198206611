<script setup lang="ts">
import {
  Dropdown,
  List,
  ListItem,
  Button as VBtn,
} from '@wartek-id/belajar-id-components'

interface Props {
  items: Record<string, any>[]
  label: string
}

const props = withDefaults(defineProps<Props>(), {
})

const isOpen = ref(false)
</script>

<template>
  <Dropdown>
    <VBtn
      variant="secondary"
      suffix-icon="akar-icons:chevron-down"
      suffix-icon-class="!w-3"
      @click="isOpen = !isOpen"
      @blur="isOpen = false"
    >
      {{ label }}
    </VBtn>
    <template #popper>
      <List rounded="md" class="bg-white m-1 w-[202px]">
        <ListItem
          v-for="(item, index) in items"
          :key="index"
          class="text-start !text-sm"
          @click="item.onClick?.(item)"
        >
          {{ item.text }}
        </ListItem>
      </List>
    </template>
  </Dropdown>
</template>
