import platforms from './platforms.json'
import faqs from './faqs'
import { products } from './products'

export interface RegistrationStep {
  title: string
  subtitle: string
  description: string
  image: string
  image2?: string
  imgProps?: Record<string, any>
}

const content = {
  app: {
    valueProposition: {
      title: 'Mengapa Memakai Akun belajar.id?',
      values: [
        'Materi dan informasi dari Kemdikbudristek, akan dikirimkan ke alamat pos elektronik Akun belajar.id',
        'Akun belajar.id akan menjadi salah satu jalur komunikasi resmi Kemdikbudristek ke peserta didik, pendidik, dan tenaga kependidikan',
        'Akun belajar.id akan digunakan untuk mengakses aplikasi-aplikasi resmi Kemdikbudristek',
        'Akun belajar.id yang sudah siap pakai dan sesuai struktur satuan pendidikan masing-masing pengguna',
      ],
    },
    introductionVideo: {
      title: 'Video Pengenalan Akun belajar.id',
      href: 'https://www.youtube.com/embed/IyUJB6SSl44',
    },
    registrationSteps: {
      title: 'Cara Mendapatkan Akun belajar.id',
      learnMore:
        'Butuh penjelasan lebih lanjut? Silakan klik tombol berikut untuk melihat video petunjuk',
      forStudentsTeachers: {
        title: 'Untuk Siswa, Guru, dan Tenaga Kependidikan',
        videoHref: 'https://www.youtube.com/watch?v=ebElTfaj5nY',
      },
      forOperators: {
        title: 'Untuk Operator Satuan Pendidikan',
        videoHref: 'https://www.youtube.com/watch?v=9YxeBFzkFJo',
      },
      items: [
        {
          title: 'Langkah Awal',
          subtitle: 'Mendapatkan Akun belajar.id',
          description:
            'Silakan buka laman https://belajar.id/ untuk memeriksa ketersediaan akun. Pilih salah satu tipe Pengguna yaitu Pendidik dan Tenaga Kependidikan, Peserta Didik, atau Dinas. Kemudian, masukkan data diri Anda sesuai yang diminta.',
          image: '/images/step-aktivasi/step-aktivasi-1.webp',
          imgProps: {
            'width': 363,
            'height': 280,
            'src-placeholder':
              '/images/step-aktivasi/step-aktivasi-1-placeholder.webp',
          },
        },
        {
          title: 'Langkah Kedua',
          subtitle: 'Dapatkan detail Akun belajar.id Anda',
          description:
            'Untuk mendapatkan detail Akun belajar.id, silakan klik "Kirim detail akun ke email pribadi".',
          image: '/images/step-aktivasi/step-aktivasi-2.webp',
          imgProps: {
            'width': 462,
            'height': 300,
            'src-placeholder':
              '/images/step-aktivasi/step-aktivasi-2-placeholder.webp',
          },
        },
        {
          title: 'Langkah Ketiga',
          subtitle: 'Periksa detail Akun belajar.id Anda di email pribadi',
          description:
            'Periksalah kotak masuk email pribadi Anda. Anda akan mendapatkan email yang berisi identitas pengguna (user ID) dan kata sandi (password) Akun belajar.id Anda.',
          image: '/images/step-aktivasi/step-aktivasi-3.webp',
          imgProps: {
            'width': 462,
            'height': 300,
            'src-placeholder':
              '/images/step-aktivasi/step-aktivasi-3-placeholder.webp',
          },
        },
        {
          title: 'Langkah Keempat',
          subtitle: 'Segera aktifkan Akun belajar.id Anda',
          description:
            'Untuk mengaktifkan Akun belajar.id Anda, silakan buka laman https://mail.google.com/, kemudian masuk dengan identitas pengguna (user ID) dan kata sandi (password) yang dikirim ke email pribadi Anda. ',
          image: '/images/step-aktivasi/step-aktivasi-4-0.webp',
          imgProps: {
            'width': 462,
            'height': 300,
            'src-placeholder':
              '/images/step-aktivasi/step-aktivasi-4-0-placeholder.webp',
          },
        },
        {
          title: 'Langkah Kelima',
          subtitle: 'Setujui Syarat dan Ketentuan yang Berlaku',
          description:
            'Setelah berhasil masuk dengan Akun belajar.id, harap baca dan cermati syarat dan ketentuan yang berlaku, kemudian tekan “Terima”.',
          image: '/images/step-aktivasi/step-aktivasi-5.webp',
          imgProps: {
            'width': 462,
            'height': 300,
            'src-placeholder':
              '/images/step-aktivasi/step-aktivasi-5-placeholder.webp',
          },
        },
        {
          title: 'Langkah Keenam',
          subtitle: 'Selangkah lagi, akun Anda siap digunakan',
          description:
            'Anda akan diarahkan untuk mengganti kata sandi. Silakan masukkan kata sandi baru dan mudah Anda ingat dengan minimum 8 karakter.',
          image: '/images/step-aktivasi/step-aktivasi-6.webp',
          imgProps: {
            'width': 462,
            'height': 300,
            'src-placeholder':
              '/images/step-aktivasi/step-aktivasi-6-placeholder.webp',
          },
        },
        {
          title: 'Langkah Ketujuh',
          subtitle: 'Akun Anda Telah Siap Digunakan',
          description:
            'Setelah melakukan penggantian kata sandi, Anda akan masuk ke dalam email Akun belajar.id. Dengan demikian Akun belajar.id Anda sudah aktif dan siap digunakan.',
          image: '/images/step-aktivasi/step-aktivasi-7.webp',
          imgProps: {
            'width': 462,
            'height': 300,
            'src-placeholder':
              '/images/step-aktivasi/step-aktivasi-7-placeholder.webp',
          },
        },
      ],
      cta: {
        href: 'https://www.youtube.com/watch?v=bYackMO11vE',
        text: 'Lihat video cara aktivasi',
      },
    },
    webinars: {
      section: {
        title: 'Web dan Seminar<br/>(Webinar)',
        description:
          'Anda bisa melihat Web dan seminar (Webinar) yang diselenggarakan secara daring di sini.',
        cta: 'Lihat semua webinar',
      },
      title: 'Webinar Petunjuk',
      invitation:
        'Bergabunglah ke Webinar Akun belajar.id untuk mengenali Akun belajar.id lebih lanjut!',
      leadTexts: [
        'Ingin memahami manfaat Akun belajar.id lebih baik? Hadiri webinar interaktif kami. Setiap webinar memiliki topik yang berbeda dan dipandu oleh instruktur yang siap menjawab pertanyaan Anda.',
        'Tidak bisa menghadiri sebuah webinar? Jangan khawatir, rekaman webinar akan diunggah di kanal YouTube Kemdikbudristek dan dapat diakses melalui situs ini.',
      ],
      moreInfo: {
        text: 'Tidak bisa menghadiri sebuah webinar? Jangan khawatir, rekaman webinar akan diunggah di kanal YouTube Kemdikbudristek.',
        url: '/webinar',
      },
      schedule: {
        title: 'Webinar',
        scheduleTitle: 'Jadwal Webinar',
        textOpening:
          'Ingin memahami manfaat Akun belajar.id lebih baik? Hadiri webinar interaktif kami. Setiap webinar memiliki topik yang berbeda dan dipandu oleh instruktur yang siap menjawab pertanyaan Anda.',
        textClosing:
          'Tidak bisa menghadiri jadwal webinar yang anda ingin pelajari?\nJangan khawatir, rekaman webinar akan diunggah di kanal YouTube Kemdikbudristek.',
      },
      webinars: [
        {
          title: 'Belajar Membuat Kuis di belajar.id',
          date: '2021-08-02T15:00:00+07:00',
          url: 'https://bit.ly/kuisbelajarid',
        },
        {
          title: 'Cara Menugaskan Kuis dengan belajar.id',
          date: '2021-08-03T15:00:00+07:00',
          url: 'https://bit.ly/tugaskuisbelajarid',
        },
        {
          title: 'Belajar Berbagi Tugas di belajar.id',
          date: '2021-08-04T15:00:00+07:00',
          url: 'https://bit.ly/bagitugasbelajarid',
        },
        {
          title: 'Bekerja Sama Membuat Presentasi di belajar.id',
          date: '2021-08-05T15:00:00+07:00',
          url: 'https://bit.ly/presentasibelajarid',
        },
      ],
    },
    guides: {
      title: 'Panduan Penggunaan Aplikasi Pembelajaran dengan Akun belajar.id',
      guides: [
        {
          title: 'Mengenal Akun belajar.id ->',
          href: 'https://bit.ly/modulkenalakunbelajar ',
        },
        {
          title: 'Petunjuk penggunaan akun belajar.id (30 hari pertama) ->',
          href: 'https://bit.ly/modul30hariakunbelajar',
        },
        {
          title: 'Mengajar dimana saja dengan menggunakan Akun belajar.id ->',
          href: 'https://belajardimanasaja.refoindonesia.com/30-hari-pertama-akun-belajar-id/mengajar-dengan-akun-pembelajaran',
        },
        {
          title:
            'Petunjuk penggunaan Google Suites untuk kegiatan pembelajaran & cara migrasi akun ->',
          href: 'https://belajardimanasaja.id/30-hari-pertama-akun-belajar-id',
        },
        {
          title:
            'Kenal lebih dekat dengan Google Grup Pengajar (GEG) di kota Anda ->',
          href: 'https://sites.google.com/view/gegindonesia/home',
        },
      ],
    },
    platforms: {
      title:
        'Contoh Aplikasi Pembelajaran yang Dapat Diakses dengan Akun belajar.id',
      platforms,
    },
    howTo: {
      title: 'Cara Mengakses Aplikasi Pembelajaran dengan Akun belajar.id',
      steps: [
        'Buka laman login Aplikasi Pembelajaran yang ingin Anda gunakan',
        'Pilih opsi ***sign in* dengan Google** di laman login tersebut',
        'Masukkan akun belajar.id dan kata sandi Anda',
        'Aplikasi Pembelajaran sudah siap digunakan',
      ],
    },
    faqs: {
      title: 'Pertanyaan yang Sering Ditanyakan',
      input: {
        placeholder: 'Tuliskan pertanyaan Anda disini',
      },
      faqs,
      hero: {
        title: 'Ada pertanyaan yang belum terjawab?',
        subtitle:
          'Temukan jawaban pertanyaan yang sering ditanyakan oleh pengguna Pembelajaran',
      },
      searchButton: 'Cari',
      content: {
        title: 'Pertanyaan Sering Diajukan (FAQ)',
        subtitle:
          'Temukan jawaban seputar cara penggunaan akun belajar.id dan masalah yang sering ditemui.',
      },
    },
    resources: {
      title: 'Pernyataan Pemerintah',
      description:
        'Pembelajaran merupakan program pemerintah lewat Kemdikbudristek yang bertujuan untuk memudahkan dan mendukung kegiatan belajar dan mengajar.',
      files: [
        {
          title: 'Lihat Persesjen No. 14 Tahun 2024',
          href: '/static/SALINAN_PERSESJEN NOMOR 4 TAHUN 2024_AKUN AKSES.pdf',
        },
        {
          title: 'Lihat Paparan Akun belajar.id',
          href: '/static/Paparan Akun Pembelajaran.pdf',
        },
        {
          title: 'Lihat Surat Edaran',
          href: '/static/SE 23 TAHUN 2021 PEMANFAATAN AKUN AKSES LAYANAN PEMBELAJARAN.pdf',
        },
        {
          title: 'Mengenal Akun belajar.id',
          href: '/static/Mengenal Akun Pembelajaran.pdf',
        },
        {
          title: 'Cara Operator Mengunduh Data Akun belajar.id',
          href: '/static/Cara Operator Mengunduh Data Akun Pembelajaran.pdf',
        },
        {
          title: 'Cara Aktivasi Akun belajar.id',
          href: '/static/Cara Aktivasi Akun Pembelajaran.pdf',
        },
        {
          title: 'Cara Mengecek Ketersediaan Akun belajar.id',
          href: '/static/Cara Mengecek Ketersediaan Akun Pembelajaran.pdf',
        },
        {
          title: 'Ketentuan Penggunaan Akun belajar.id',
          href: '/static/infographic-Belajar.id.pdf',
        },
      ],
    },
  },
  generic: {
    actions: {
      more: 'Selengkapnya',
      goToWebinars: 'Lihat Webinar',
      goToVideoGuide: 'Lihat Video Petunjuk',
    },
  },
  products: {
    title: 'Belajar mengajar jadi lebih mudah',
    description:
      'Anda bisa mengakses beragam aplikasi yang mendukung kegiatan belajar mengajar dengan Akun belajar.id.',
    items: products,
  },
  about: {
    title: 'Selamat Datang di Akun belajar.id',
    description: [
      // paragraph 1
      'Akun belajar.id diberikan kepada peserta didik, pendidik, dan tenaga kependidikan dari berbagai satuan pendidikan, mulai dari PAUD, SD, SMP, SMA, SMK, SLB, dan Kesetaraan.',
      'Dengan Akun belajar.id, kamu bisa mengakses berbagai kebutuhan kegiatan belajar mengajar. Mulai dari mengakses platform Kemdikbudristek sampai beragam aplikasi yang akan memudahkan kegiatan belajar mengajar, baik secara tatap muka ataupun jarak jauh.',
    ],
  },
  accountLookup: {
    title:
      '<span class="text-blue-70">Satu akun</span> untuk<br/>beragam kegiatan<br/>belajar mengajar',
    description: 'Segera periksa dan aktifkan Akun<br/>Pembelajaran',
    form: {
      fullName: 'Nama Lengkap Sesuai Dapodik',
      fullNamePlaceholder: 'Isi Nama Lengkap Sesuai Dapodik',
      nik: 'Nomor Induk Kependudukan (NIK)',
      nikPlaceholder: 'Isi Nomor Induk Kependudukan (NIK)',
      userType: 'Tipe Pengguna',
      userTypePlaceholder: 'Pilih Tipe Pengguna',
      birthDate: 'Tanggal Lahir',
      birthDatePlaceholder: 'Pilih Tanggal Lahir',
      npsn: 'NPSN',
      npsnPlaceholder: 'Masukkan NPSN Anda',
      forgotNpsn: 'Lupa NPSN?',
      submit: 'Periksa Akun belajar.id',
      motherName: 'Nama Ibu Kandung',
      motherNamePlaceholder: 'Isi Nama Ibu Kandung Anda',
      email: 'Email Pribadi Aktif',
      emailPlaceholder: 'Masukan Email Pribadi Aktif Anda',
      phone: 'Nomor HP Pribadi Aktif',
      phonePlaceholder: 'Masukan Nomor HP Pribadi Aktif Anda',
    },
  },
  accountLookupDinas: {
    title:
      '<span class="text-blue-70">Satu akun</span> untuk<br/>beragam kegiatan<br/>belajar mengajar',
    description: 'Segera periksa dan aktifkan Akun<br/>Pembelajaran',
    form: {
      title: 'Buat Akun belajar.id-mu sekarang.',
      subtitle:
        'Masukan detail Anda untuk melanjutkan pembuatan akun belajar.id',
      name: 'Nama Lengkap',
      namePlaceholder: 'Masukan Nama Lengkap',
      nik: 'Nomor Induk Kependudukan (NIK)',
      nikPlaceholder: 'Masukan Nomor Induk Kependudukan (NIK)',
      userType: 'Tipe Pengguna',
      userTypePlaceholder: 'Pilih Tipe Pengguna',
      birthDate: 'Tanggal Lahir',
      birthDatePlaceholder: 'Pilih Tanggal Lahir',
      npsn: 'NPSN',
      npsnPlaceholder: 'Masukkan NPSN Anda',
      forgotNpsn: 'Lupa NPSN?',
      submit: 'Periksa Akun belajar.id',
      motherName: 'Nama Ibu Kandung',
      motherNamePlaceholder: 'Masukan Nama Ibu Kandung Anda',
      email: 'Email Pribadi Aktif',
      emailPlaceholder: 'Masukan Email Pribadi Aktif Anda',
      phone: 'Nomor HP Pribadi Aktif',
      phonePlaceholder: 'Masukan Nomor HP Pribadi Aktif Anda',
      province: 'Provinsi',
      provincePlaceholder: 'Provinsi',
      city: 'Kabupaten Kota',
      cityPlaceholder: 'Kabupaten Kota',
      dinasType: 'Tipe Dinas Daerah',
      dinasTypePlaceholder: 'Pilih Tipe Dinas Daerah',
      position: 'Jabatan',
      positionPlaceholder: 'Pilih Jabatan',
      otherPosition: 'Jabatan Lainnya',
      otherPositionPlaceholder: 'Masukan Jabatan Lainnya',
      referral: 'Kode Referral',
      referralPlaceholder: 'Masukan Kode Referral',
      suratDinas: 'Perihal Surat Dinas',
      suratDinasPlaceholder: 'Contoh: Perihal Laporan Tahunan',
      nomorSuratDinas: 'Nomor Surat Dinas',
      nomorSuratDinasPlaceholder: 'Contoh: 810231231',
      raporPendidikan: 'Rapor Pendidikan',
      platform: 'Platform Merdeka Mengajar',
    },
  },
}

/**
 * Content getter function
 * @param {string} identifier Content path delimited by dot, e.g. 'app.resources.title'
 *
 * @return {(string | Array )} Fetched content
 */
export default function getContent<T = string | undefined>(
  identifier: string,
): T {
  const path = identifier.split('.')
  const result = path.reduce((acc: any, cur: string) => {
    if (!acc)
      return undefined

    acc = acc?.[cur]
    return acc
  }, content)

  if (!result)
    console.warn(`Text not found: ${identifier}`)
  return result ?? identifier
}

export const UNKNOWN_ERROR = 'Terjadi kesalahan. Silahkan coba lagi.'
