<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String,
      default: '',
      required: true,
    },
  },
})
</script>

<template>
  <div class="text-sm mt-1 text-error">
    {{ message }}
  </div>
</template>
