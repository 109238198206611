<script setup lang="ts">
import {
  Dropdown,
  List,
  ListItem,
  Navbar,
  Button as VBtn,
} from '@wartek-id/belajar-id-components'

import { useAuthModal } from '@/composables/useAuthModal'
import { useGA } from '@/composables'
import { useOidcStore } from '@/pinia'

interface DropdownItem {
  text: string
  value: string
  onClick?: (item: DropdownItem) => void
}

const { type, showMenu, show: showModal } = useAuthModal()
const showMenuOnMobile = ref(false)
const isFixed = ref(false)
const store = useStore()
const ga = useGA()
const oidcStore = useOidcStore()

const showModalHandler = (typeVal: string) => {
  if (typeVal === 'pengajuan-akun') {
    type.value = typeVal
    showModal.value = true
  }
  else {
    window.location.href = import.meta.env.VITE_ADMIN_BASE_URL
  }
}

const items = ref<DropdownItem[]>([
  {
    text: 'Ajukan Akun Peserta Didik',
    value: 'pengajuan-akun',
    onClick(item: DropdownItem) {
      showModalHandler(item.value)
    },
  },
  {
    text: 'Masuk ke Portal Admin',
    value: 'portal-admin',
    onClick(item: DropdownItem) {
      showModalHandler(item.value)
    },
  },
])

const router = useRouter()

// const cekAkunitems = ref<DropdownItem[]>([
//   {
//     text: 'Cek Profile Akun',
//     value: 'profile',
//     onClick() {
//       router.push('/profile')
//     },
//   },
//   {
//     text: 'Cari Tahu Status Akun',
//     value: 'status',
//     onClick() {
//       router.push('/login')
//     },
//   },
// ])

const toggleDropdown = () => {
  showMenuOnMobile.value = !showMenuOnMobile.value
  isFixed.value = !!showMenuOnMobile.value
}

const isOpenModalResetPassword = computed({
  get() {
    return store.state.resetPassword.isOpen
  },
  set(val) {
    store.commit('resetPassword/setModal', val)
  },
})

const openModalResetPassword = () => {
  store.commit('resetPassword/openModal')
  ga.trackActivity({
    activity_type: 'CLICK_RESET_PASSWORD',
    page_title: 'Belajar.id | Home Page',
  })
}

const trackLogo = () => {
  ga.clickLink('menu_logo_pembelajaran')
}

const trackAccountCheck = () => {
  ga.trackActivity({
    activity_type: 'PROFILE_CLICK_MENU_CHECK_ACCOUNT',
    page_title: 'Belajar.id | Home Page',
  })
}

const chromebookLink = 'https://pusatinformasi.belajar.id/hc/en-us/sections/17415982732569'
</script>

<template>
  <div data-qa="navbar" class="fixed top-0 left-0 z-10 w-full">
    <AnnouncementBar />
    <Navbar class="py-0">
      <div class="px-1 xl:px-0 lg:container mx-auto py-2">
        <div class="flex items-center justify-between">
          <div class="md:flex items-center w-auto hidden">
            <router-link
              :to="{ name: 'home' }"
              class="inline-flex md:justify-start justify-between logo gap-0 items-center"
              @click="trackLogo"
            >
              <img
                class="w-[46px] h-[45px]"
                src="/images/logo-46.webp"
                alt="Logo Kemdikbudristek RI desktop"
                width="46"
                height="45"
              >
              <span class="font-bold leading-[17px] px-2">
                Akun<br>belajar.id
              </span>
            </router-link>
          </div>
          <div class="md:flex hidden items-center gap-x-3">
            <VBtn
              variant="secondary"
              :href="chromebookLink"
            >
              Chromebook
            </VBtn>
            <NavbarDropdown label="Untuk Admin Sekolah" :items="items" />
            <VBtn
              variant="secondary"
              to="/profile"
              @click="trackAccountCheck"
            >
              Profil Akun belajar.id
            </VBtn>
            <VBtn variant="secondary" @click="openModalResetPassword">
              Reset Password
            </VBtn>
            <VBtn
              href="https://pusatinformasi.belajar.id/hc/en-us"
              variant="ultimate"
              prefix-icon="ant-design:question-circle-outlined"
              prefix-icon-class="!w-5"
            >
              Butuh Bantuan
            </VBtn>
          </div>
          <div class="flex w-full items-center justify-between md:hidden">
            <router-link :to="{ name: 'home' }" @click="trackLogo">
              <img
                class="w-[46px] h-[45px]"
                src="/images/logo-46.webp"
                alt="Logo Kemdikbudristek RI mobile"
                width="46"
                height="45"
              >
            </router-link>
            <router-link :to="{ name: 'home' }" @click="trackLogo">
              <span class="px-2 font-bold whitespace-nowrap text-gray-80">
                Akun belajar.id
              </span>
            </router-link>
            <VBtn
              fab
              class="border-none"
              aria-label="Menu"
              @click="toggleDropdown"
            >
              <icon name="menu" :width="24" :height="24" />
            </VBtn>
          </div>
        </div>
      </div>
    </Navbar>
    <Transition name="fade">
      <Navbar v-model="showMenuOnMobile" class="md:hidden shadow">
        <div class="px-2 xl:px-0 lg:container mx-auto py-2">
          <div class="flex flex-col gap-y-3">
            <VBtn
              variant="secondary"
              :href="chromebookLink"
            >
              Chromebook
            </VBtn>
            <Dropdown>
              <VBtn
                variant="secondary"
                full-width
                suffix-icon="akar-icons:chevron-down"
                suffix-icon-class="!w-3"
                @click="showMenu = !showMenu"
                @blur="showMenu = false"
              >
                Untuk Admin Sekolah
              </VBtn>
              <template #popper>
                <List rounded="md" class="my-1.5 mx-2 bg-white w-[255px]">
                  <ListItem
                    v-for="(item, index) in items"
                    :key="index"
                    class="text-start !text-sm"
                    @click="showModalHandler(item.value)"
                  >
                    {{ item.text }}
                  </ListItem>
                </List>
              </template>
            </Dropdown>
            <VBtn
              variant="secondary"
              to="/profile"
              @click="trackAccountCheck"
            >
              Profil Akun belajar.id
            </VBtn>
            <VBtn variant="secondary" @click="openModalResetPassword">
              Reset Password
            </VBtn>
            <VBtn
              href="https://pusatinformasi.belajar.id/hc/en-us"
              variant="ultimate"
              prefix-icon="ant-design:question-circle-outlined"
              prefix-icon-class="!w-5"
            >
              Butuh bantuan
            </VBtn>
          </div>
        </div>
      </Navbar>
    </Transition>
  </div>

  <ResetPasswordLogin v-model="isOpenModalResetPassword" />
</template>
