import type { AxiosError } from 'axios'
import type { Module } from 'vuex'
import type { RootState } from '..'
import type { RequestPasswordMethod } from '@/services/account-lookup'
import { changePrivateEmail, lookupAccount, requestPassword } from '@/services'
import type {
  AccountLookupBody,
  AccountLookupData,
  AccountType,
  RequestPasswordBody,
} from '@/services'
import type { ErrorResponse } from '@/types'

export interface AccountLookupForm {
  fullName: string
  motherName?: string
  npsn: string
  dateOfBirth: string
  userType?: string
  recaptchaToken?: string

  [x: string]: unknown
}

export interface AccountCreationForm {
  fullName: string
  motherName: string
  type: string
  birthDate: string
  npsn: string
  phone: string
  email: string
}

export const ACCOUNT_LOOKUP = 'ACCOUNT_LOOKUP'

export type AccountTypes = 'pd' | 'ptk' | 'dinas'

export interface NewAccountLookupForm {
  nama?: string
  npsn?: string
  tanggal_lahir?: string
  recaptcha_token?: string
  userType?: AccountTypes | ''

  [x: string]: any
}

export interface AccountLookupState {
  form: NewAccountLookupForm
  loading: boolean
  result: AccountLookupData | null
  userType: AccountTypes | ''
}

export const getLocalForm = (): NewAccountLookupForm => {
  try {
    return JSON.parse(window.localStorage.getItem(ACCOUNT_LOOKUP) || '')
  }
  catch {
    return {}
  }
}

export const getLocalUserType = () => {
  if (import.meta.env.SSR)
    return ''

  return window.localStorage.getItem('ACCOUNT_LOOKUP_TYPE')
}

export const getAccountTypeLabel = (type?: string) => {
  const labels: Record<string, string> = {
    ptk: 'Pendidik dan Tenaga Kependidikan',
    pd: 'Peserta Didik',
    dinas: 'Dinas',
  }
  return labels[type || String(getLocalUserType())]
}

export const accountLookup = {
  namespaced: true,
  state: {
    form: {
      userType: getLocalUserType(),
    },
    loading: false,
    result: null,
    userType: getLocalUserType(),
  },
  mutations: {
    setForm(state, form: NewAccountLookupForm) {
      Object.keys(form).forEach((key) => {
        state.form[key] = form[key]
      })
    },
    setLoading(state, value: boolean) {
      state.loading = value
    },
    setResult(state, result: AccountLookupData) {
      state.result = result
    },
    setUserType(state, type) {
      state.userType = type
      window.localStorage.setItem('ACCOUNT_LOOKUP_TYPE', type)
    },
    reset(state) {
      state.form = {}
      state.result = null
      state.userType = ''
    },
  },
  actions: {
    async lookup(
      { commit },
      {
        type,
        body,
        clearResultFirst = true,
      }: {
        type: AccountType
        body: AccountLookupBody
        clearResultFirst?: boolean
      },
    ) {
      commit('setForm', body)

      if (clearResultFirst) {
        commit('setLoading', true)
        commit('setResult', null)
      }
      try {
        const res = await lookupAccount(type, body)
        const data = res.data.data
        commit('setResult', data)
        return res
      }
      catch (e: unknown) {
        return (e as AxiosError)?.response
      }
      finally {
        commit('setLoading', false)
      }
    },
    async requestPassword(
      _,
      {
        body,
        method,
      }: { body: RequestPasswordBody; method: RequestPasswordMethod },
    ) {
      try {
        const { data } = await requestPassword(body, method)
        return data
      }
      catch (e: unknown) {
        return (e as AxiosError)?.response?.data as ErrorResponse
      }
    },
    async changePrivateEmail(
      _,
      { token, email }: { email: string; token: string },
    ) {
      try {
        const { data } = await changePrivateEmail(token, email)
        return data
      }
      catch (e: unknown) {
        return (e as AxiosError)?.response?.data as ErrorResponse
      }
    },
  },
  getters: {
    token: state => state.result?.token,
  },
} as Module<AccountLookupState, RootState>

export const setAccountLookupForm = (
  body: AccountLookupForm | NewAccountLookupForm,
): void => {
  window.localStorage.setItem(ACCOUNT_LOOKUP, JSON.stringify(body))
}

export const getAccountLookupForm = (): Record<string, any> | null => {
  try {
    return JSON.parse(window.localStorage.getItem(ACCOUNT_LOOKUP) || '')
  }
  catch {
    return null
  }
}

export const clearAccountLookupForm = (): void => {
  window.localStorage.removeItem(ACCOUNT_LOOKUP)
}
